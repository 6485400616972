import {
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import React from 'react';

import Formatter from '@/services/Formatter';
import { useAccountStore } from '@/store';

type AgentCommissionsEditProps = {
  data: {
    agent_commissions: Record<string, number>;
    agent_commissions_v2?: Record<string, number>;
    agent_payout_rate?: Record<string, number>;
    agent_payout_rate_override?: Record<string, number>;
    agent_commission_payout_rate?: Record<string, number>;
    contacts: string[];
  };
  setter: (fn: (prevData: any) => any) => void;
  field: any;
  dynamicSelects: any;
  readOnly?: boolean;
  percentageFormat?: 'decimal' | 'percentage';
};

const AgentCommissionsEdit: React.FC<AgentCommissionsEditProps> = ({
  data,
  field,
  setter,
  dynamicSelects,
  readOnly = false,
  percentageFormat = 'decimal',
}) => {
  let agentDataToUse: Record<string, number> = {};
  let units = 'amount';

  const { agent_commissions: agentCommissions } = data || {};
  const { agent_commissions_v2: agentCommissionsV2 } = data || {};
  const { agent_payout_rate: agentPayoutRate } = data || {};
  const { agent_payout_rate_override: agentPayoutRateOverride } = data || {};
  const { agent_commission_payout_rate: agentCommissionPayoutRate } =
    data || {};

  if (field.id === 'agent_commissions') {
    agentDataToUse = agentCommissions || {};
  } else if (field.id === 'agent_commissions_v2') {
    agentDataToUse = agentCommissionsV2 || {};
  } else if (field.id === 'agent_payout_rate') {
    agentDataToUse = agentPayoutRate || {};
    units = 'rate';
  } else if (field.id === 'agent_payout_rate_override') {
    agentDataToUse = agentPayoutRateOverride || {};
    units = 'rate';
  } else if (field.id === 'agent_commission_payout_rate') {
    agentDataToUse = agentCommissionPayoutRate || {};
    units = 'rate';
  }

  const { selectedAccount } = useAccountStore();
  const [selectedAgents, setSelectedAgents] = React.useState<string[]>([]);
  const [query, setQuery] = React.useState('');

  if (!agentDataToUse) {
    agentDataToUse = {};
  }

  const handleAddSelected = () => {
    selectedAgents.forEach((agentId) => {
      if (!agentDataToUse[agentId]) {
        updateAgentData(agentId, '0');
      }
    });
    setSelectedAgents([]);
  };

  const filterOptions = (options: any[], query: string) => {
    if (!query) return options;
    const lowerQuery = query?.toLowerCase();

    return options.filter((option) =>
      lowerQuery
        .split(' ')
        .map((q) => q.trim())
        .every(
          (q) =>
            option.first_name?.toLowerCase()?.includes(q) ||
            option.last_name?.toLowerCase()?.includes(q)
        )
    );
  };

  const updateAgentData = (agentId: string, value: string) => {
    setter((prevData) => {
      const newAgentCommissions = { ...prevData[field.id] };
      newAgentCommissions[agentId] = value;

      const { total, ...commissionsWithoutTotal } = newAgentCommissions;

      const newTotal = (
        Object.values(commissionsWithoutTotal) as string[]
      ).reduce(
        (sum: number, current: string) =>
          sum + (Number.isNaN(+current) ? 0 : +current),
        0
      );

      newAgentCommissions['total'] = newTotal;

      return {
        ...prevData,
        [field.id]: newAgentCommissions,
      };
    });
  };

  const removeAgentData = (agentId: string) => {
    setter((prevData) => {
      const newAgentCommissions = { ...prevData[field.id] };
      delete newAgentCommissions[agentId];

      const { total, ...commissionsWithoutTotal } = newAgentCommissions;

      const newTotal = (
        Object.values(commissionsWithoutTotal) as number[]
      ).reduce((sum: number, current: number) => sum + current, 0);

      newAgentCommissions['total'] = newTotal;

      return {
        ...prevData,
        [field.id]: newAgentCommissions,
      };
    });
  };

  return (
    <Box>
      <Typography variant="subtitle2">{field.label}</Typography>
      <Box
        key={`${field.id}-box`}
        sx={{
          width: '100%',
          mb: 1,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            mt: 0.5,
            p: 0.5,
            display: 'flex',
            flexDirection: 'column',
            borderStyle: 'solid',
            borderColor: 'silver',
            borderWidth: 1,
            borderRadius: 4,
            width: '100%',
            backgroundColor: '#2196f308',
          }}
        >
          <Box sx={{ my: 0.5, display: 'flex', flexWrap: 'wrap' }}>
            {agentDataToUse &&
              Object.entries(agentDataToUse)
                .filter(([k, v]) => k !== 'total')
                .map(([k, v]) => {
                  const contact = dynamicSelects?.find(
                    (e) => e?.str_id === k
                  ) ?? { id: undefined };
                  return contact.id ? (
                    <Box
                      key={`${contact.id}`}
                      sx={{
                        m: 0.5,
                        p: 1,
                        borderStyle: 'solid',
                        borderColor: 'silver',
                        borderWidth: 1,
                        borderRadius: 4,
                        display: 'inline-block',
                        width: 180,
                        backgroundColor: '#2196f30a',
                      }}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        position="relative"
                        sx={{ ml: 0.5 }}
                      >
                        <Typography variant="body2">
                          {Formatter.contact(contact, {
                            account_id: selectedAccount?.accountId,
                          })}
                        </Typography>
                        {!readOnly && (
                          <IconButton
                            onClick={() => {
                              removeAgentData(contact.str_id);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        )}
                      </Box>
                      <Box>
                        <TextField
                          label={`Commission ${units}`}
                          variant="outlined"
                          value={
                            units === 'amount'
                              ? v
                              : percentageFormat === 'percentage'
                                ? Formatter.percentageNotDecimal(v)
                                : Formatter.percentage(v)
                          }
                          onChange={(e) => {
                            let newValue = e.target.value;
                            if (newValue.includes('%')) {
                              newValue = newValue.replace('%', '');
                            }
                            updateAgentData(contact.str_id, newValue);
                          }}
                          onBlur={(e) => {
                            setter((prevData) => {
                              Object.entries(prevData[field.id]).forEach(
                                ([k, v]) => {
                                  const amount: string = v as any;
                                  prevData[field.id][k] = Number.isNaN(+amount)
                                    ? 0
                                    : +amount;
                                }
                              );
                              return prevData;
                            });
                          }}
                          sx={{ my: 0.5 }}
                          disabled={readOnly}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Skeleton key={k} />
                  );
                })}
            {!readOnly && (
              <Box
                display="flex"
                justifyContent="space-between"
                position="relative"
              >
                <FormControl key={field.id} sx={{ m: 0.5, width: 145 }}>
                  <InputLabel id={`${field.id}-label`}>Add</InputLabel>
                  <Select
                    labelId={`${field.id}-label`}
                    id={field.id}
                    label="Add"
                    multiple
                    value={selectedAgents}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    onClose={handleAddSelected}
                    onChange={(e) => {
                      setSelectedAgents(
                        Array.isArray(e.target.value)
                          ? e.target.value.filter(Boolean)
                          : [e.target.value]
                      );
                    }}
                    sx={{
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#333',
                      },
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {Array.isArray(selected) &&
                          (selected as string[]).map((value) => {
                            const item = dynamicSelects.find(
                              (option) => option.id === value
                            );
                            return (
                              <Chip
                                key={item ? item.str_id : value}
                                label={item ? item.name : value}
                              />
                            );
                          })}
                      </Box>
                    )}
                    disabled={readOnly}
                  >
                    <Box
                      sx={{
                        mb: 0.5,
                        mx: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                      onKeyDown={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <TextField
                        sx={{ flex: 1, mr: 1 }}
                        label="Search"
                        value={query}
                        onChange={(e) => {
                          setQuery(e.target.value);
                        }}
                      />
                    </Box>
                    <Button
                      sx={{ ml: 1 }}
                      variant="outlined"
                      onClick={handleAddSelected}
                    >
                      Add selected
                    </Button>
                    {field.nullable && (
                      <MenuItem value={''} key="null">
                        &nbsp;
                      </MenuItem>
                    )}
                    {filterOptions(dynamicSelects, query).map((option: any) => (
                      <MenuItem value={option.str_id} key={option.id}>
                        {Formatter.contact(option, {
                          account_id: selectedAccount?.accountId,
                        })}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
          </Box>
          {units === 'amount' && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              position="relative"
              sx={{ mx: 0.5 }}
            >
              <TextField
                label="Total"
                variant="outlined"
                value={Formatter.currency(
                  agentDataToUse?.total ? agentDataToUse.total : 0
                )}
                disabled
                sx={{ my: 0.5 }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AgentCommissionsEdit;
