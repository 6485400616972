import { customer_gender, customer_type, Prisma } from '@fintary/api';
import { Box, TextField } from '@mui/material';
import { useMemo } from 'react';

import CustomerPolicies from '@/components/Customers/CustomerPolicies';
import { FieldTypes } from '@/types';
import { formatDate } from '@/utils/datetime';
import EnhancedDataView from '../organisms/EnhancedDataView';

type Customers = Prisma.customersGetPayload<{
  include: { report_data: true };
}>;

export const Customers = () => {
  const dataDesc = useMemo(() => {
    const genderOptions: { id: customer_gender; label: string }[] = [
      { id: 'male', label: 'Male' },
      { id: 'female', label: 'Female' },
    ];

    const typeOptions: { id: customer_type; label: string }[] = [
      { id: 'person', label: 'Person' },
      { id: 'company', label: 'Company' },
    ];

    const fields: { [key in keyof Customers]?: unknown } = {
      type: {
        label: 'Type',
        enabled: true,
        type: FieldTypes.SELECT,
        options: typeOptions,
        formatter: (v: string) => (
          <Box sx={{ textTransform: 'capitalize' }}>{v}</Box>
        ),
      },
      first_name: {
        label: 'First name',
        enabled: true,
      },
      middle_name: {
        label: 'Middle name',
        enabled: false,
      },
      last_name: {
        label: 'Last name',
        enabled: true,
      },
      nickname: {
        label: 'Nickname',
        enabled: true,
      },
      dob: {
        label: 'Birthday',
        enabled: true,
        type: FieldTypes.DATE,
        formatter: formatDate,
      },
      gender: {
        label: 'Gender',
        enabled: false,
        type: FieldTypes.SELECT,
        options: genderOptions,
        formatter: (v: string) => (
          <Box sx={{ textTransform: 'capitalize' }}>{v}</Box>
        ),
      },
      company_name: {
        label: 'Company name',
        enabled: true,
      },
      website: {
        label: 'Website',
        enabled: true,
      },
      email: {
        label: 'Email',
        enabled: true,
      },
      phone: {
        label: 'Phone',
        enabled: true,
      },
      address: {
        label: 'Address',
        enabled: true,
        type: FieldTypes.CUSTOM,
        formatter: (v) => {
          if (!v) return '';
          const values = [v.street, v.city, v.country, v.zipcode].filter(
            (v) => !!v
          );
          return <Box>{values.join(', ')}</Box>;
        },
        render: (_, newData, setNewData) => {
          const {
            street = '',
            city = '',
            state = '',
            zipcode = '',
          } = newData.address || {};

          const onChange = (v: string, field: string) => {
            setNewData({
              ...newData,
              address: {
                ...(newData.address || {}),
                [field]: v,
              },
            });
          };
          return (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <TextField
                value={street}
                onChange={(v) => onChange(v.target.value, 'street')}
                label="Street"
              />
              <TextField
                value={city}
                onChange={(v) => onChange(v.target.value, 'city')}
                label="City"
              />
              <TextField
                value={state}
                onChange={(v) => onChange(v.target.value, 'state')}
                label="State"
              />
              <TextField
                value={zipcode}
                onChange={(v) => onChange(v.target.value, 'zipcode')}
                label="Zip code"
              />
            </Box>
          );
        },
      },
      group_id: {
        label: 'Group ID',
        enabled: true,
      },
      start_date: {
        label: 'Start date',
        enabled: true,
        type: 'date',
        formatter: formatDate,
      },
      end_date: {
        label: 'End date',
        enabled: true,
        type: FieldTypes.DATE,
        formatter: formatDate,
      },
      report_data: {
        label: 'Report data',
        enabled: true,
        type: FieldTypes.CUSTOM,
        tableFormatter: (field) => field.length || '',
        render: (field, row) => (
          <CustomerPolicies key="customer_policies" data={row} field={field} />
        ),
      },
    };

    return {
      label: 'Customers',
      table: 'customers',
      editable: true,
      bulkAdd: true,
      fields: fields,
      queryChips: {},
      queryChipsType: 'select',
    };
  }, []);

  return <EnhancedDataView dataSpec={dataDesc} />;
};
