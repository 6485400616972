import {
  Add,
  Close as CloseIcon,
  Visibility,
  Refresh as RefreshIcon,
} from '@mui/icons-material';
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  Chip,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useState } from 'react';
import { nanoid } from 'nanoid';

import DataView from '@/components/DataView';
import API from '@/services/API';
import useSnackbar from '@/contexts/useSnackbar';
import Formatter from '@/services/Formatter';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 2,
  borderRadius: 4,
};

const ApiKeyManager = () => {
  const [open, setOpen] = useState(false);
  const [newKeyName, setNewKeyName] = useState('');

  const apiKeyPoster = API.getMutation('api_key', 'POST');

  const { showSnackbar } = useSnackbar();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setNewKeyName('');
  };

  const handleCreateKey = async () => {
    try {
      const response = await apiKeyPoster.mutateAsync({ name: newKeyName });

      if (!response.error) {
        handleClose();
        showSnackbar('API key created');
      } else {
        console.error(response.error);
        showSnackbar(`An error occurred: ${response.error}`, 'error');
      }
    } catch (error) {
      console.error('An unexpected error occurred:', error);
      showSnackbar(
        'An unexpected error occurred. Please try again later.',
        'error'
      );
    }
  };

  const apiKeyFormatter = (val) => {
    let keyVisible = false;
    const handleToggleVisibility = (spanElement) => {
      keyVisible = !keyVisible;
      spanElement.style.filter = keyVisible ? 'none' : 'blur(5px)';
    };

    return (
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            fontFamily: 'monospace',
            fontSize: '14px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            pl: 1,
          }}
        >
          <span
            ref={(el) => {
              if (el) {
                el.style.filter = 'blur(5px)';
                el.style.transition = 'filter 0.2s ease';
              }
            }}
          >
            {val}
          </span>
          <IconButton
            onClick={(e) => {
              const spanElement =
                e.currentTarget?.parentElement?.querySelector('span');
              handleToggleVisibility(spanElement);
            }}
            sx={{ ml: 1 }}
            size="small"
          >
            <Visibility />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const dataDesc = {
    label: 'API keys',
    table: 'api_key',
    editable: true,
    fields: [
      {
        id: 'name',
        label: 'Name',
      },
      {
        id: 'api_key',
        label: 'Key',
        readOnly: true,
        formatter: apiKeyFormatter,
        copyable: true,
        type: 'custom',
        render: (field, row, setter, dynamicSelects) => (
          <TextField
            value={row[field.id] ?? ''}
            fullWidth
            size="small"
            inputProps={{
              readOnly: true,
            }}
          />
        ),
      },
      {
        id: 'status',
        label: 'Status',
        formatter: (val) =>
          Formatter.statusChip(val, {
            mapping: { active: 'green' },
          }),
        type: 'select',
        options: [
          { label: 'Active', id: 'active' },
          { label: 'Disabled', id: 'disabled' },
        ],
      },
      {
        id: 'permissions',
        label: 'Permission',
        formatter: (key) => {
          if (key === 'all') {
            return (
              <>
                <Chip label="Read" />
                <Chip label="Write" />
              </>
            );
          } else if (key === 'read') {
            return <Chip label="Read" />;
          } else if (key === 'write') {
            return <Chip label="Write" />;
          }
        },
        type: 'select',
        options: [
          { label: 'All', id: 'all' },
          { label: 'Read', id: 'read' },
          { label: 'Write', id: 'write' },
        ],
      },
      {
        id: 'ip_allow_list',
        label: 'IP allow list',
        type: 'custom',
        render: (field, row, setter, dynamicSelects) => {
          const isValidIP = (ip) => {
            const ipRegex =
              /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
            return ipRegex.test(ip);
          };

          return (
            <Box sx={{ width: '100%' }}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: '#666',
                  fontSize: '14px',
                  fontWeight: 400,
                }}
              >
                {field.label}
              </Typography>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderStyle: 'solid',
                    borderColor: 'silver',
                    borderWidth: 1,
                    borderRadius: 4,
                    width: '100%',
                    backgroundColor: '#2196f308',
                    p: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 2,
                      alignItems: 'center',
                    }}
                  >
                    {(row?.ip_allow_list ?? []).map((ip, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: 300,
                          position: 'relative',
                        }}
                      >
                        <TextField
                          label="IP address"
                          variant="outlined"
                          value={ip}
                          size="small"
                          error={ip !== '' && !isValidIP(ip)}
                          onChange={(e) => {
                            const newIPList = [...(row?.ip_allow_list ?? [])];
                            newIPList[index] = e.target.value;
                            setter({
                              ...row,
                              ip_allow_list: newIPList,
                            });
                          }}
                          sx={{
                            flex: 1,
                            '& .MuiFormHelperText-root': {
                              display: 'none',
                            },
                          }}
                        />
                        {ip !== '' && !isValidIP(ip) && (
                          <Typography
                            sx={{
                              position: 'absolute',
                              bottom: -15,
                              left: 14,
                              fontSize: '11px',
                              color: '#d32f2f',
                            }}
                          >
                            Invalid IP address
                          </Typography>
                        )}
                        <IconButton
                          size="small"
                          onClick={() => {
                            const newIPList = [...(row?.ip_allow_list ?? [])];
                            newIPList.splice(index, 1);
                            setter({
                              ...row,
                              ip_allow_list: newIPList,
                            });
                          }}
                          sx={{ ml: 1 }}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    ))}
                    <Button
                      variant="text"
                      size="small"
                      onClick={() => {
                        setter({
                          ...row,
                          ip_allow_list: [...(row?.ip_allow_list ?? []), ''],
                        });
                      }}
                      sx={{
                        height: 30,
                        minWidth: 'auto',
                        alignSelf: 'center',
                        color: '#2196f3',
                        padding: '0 8px',
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                      }}
                    >
                      Add
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        },
      },
      {
        id: 'created_at',
        label: 'Created at',
        formatter: (row) => new Date(row).toLocaleString(),
        readOnly: true,
      },
      {
        id: 'notes',
        label: 'Notes',
      },
    ],
  };

  return (
    <>
      <DataView
        dataDesc={dataDesc}
        viewOnly
        sx={{ width: '100%' }}
        extraActions={
          <Button variant="contained" startIcon={<Add />} onClick={handleOpen}>
            Create API key
          </Button>
        }
      />
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Create API key
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            label="Key name"
            fullWidth
            variant="standard"
            value={newKeyName}
            onChange={(e) => {
              setNewKeyName(e.target.value);
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={handleCreateKey}
              variant="contained"
              sx={{ ml: 1 }}
              disabled={!newKeyName}
            >
              Create
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ApiKeyManager;
