import { Box } from '@mui/material';
import { useMemo, useState } from 'react';
import validator from 'validator';

import DataView from '@/components/DataView';
import MultiSelect from '@/components/molecules/MultiSelect';
import UploadAccountLogo from '@/components/SettingsView/UploadAccountLogo';
import useUploadStorageFile from '@/contexts/useUploadStorageFile';
import { auth } from '@/firebase';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { useAccountStore, useRoleStore } from '@/store';
import { Roles } from '@/types';

const userData = {
  label: 'User info',
  table: 'users',
  editable: false,
  validateData: (data) => validator.isEmail(data?.email),
  fields: [
    {
      id: 'email',
      label: 'Email',
      default: auth?.currentUser?.email, // null on load, set below
      readOnly: true,
      validator: (val) => validator.isEmail(val || ''),
    },
    {
      id: 'first_name',
      label: 'First name',
    },
    {
      id: 'last_name',
      label: 'Last name',
    },
    {
      id: 'phone',
      label: 'Phone',
      validator: (val) =>
        validator.isMobilePhone(val || '', 'en-US') || val === '',
    },
  ],
};

const GeneralSettings = () => {
  const impersonatedUser = JSON.parse(
    localStorage.getItem('customLoginUser') || '{}'
  );

  const { data: csms, isFetched } = API.getBasicQuery('users/get_fintary_csms');
  const [accountLogoFile, setAccountLogoFile] = useState<File | null>(null);
  console.log('csms', csms);

  const { selectedAccount } = useAccountStore();
  const { userRole } = useRoleStore();
  const { uploadFilesToStorage } = useUploadStorageFile();

  const accountData = useMemo(() => {
    return {
      label: 'Account info',
      table: `accounts?accId=${selectedAccount?.accountId}`,
      editable: false,
      fields: [
        {
          id: 'name',
          label: 'Account name',
        },
        {
          id: 'description',
          label: 'Account description',
        },
        {
          id: 'auto_share_commission_report',
          label: 'Share commission report on approval',
          type: 'boolean',
          disabled: userRole !== Roles.ACCOUNT_ADMIN,
        },
        {
          id: 'auto_email_commission_report',
          label: 'Email commission report to agent upon approval',
          type: 'boolean',
          disabled: userRole !== Roles.ACCOUNT_ADMIN,
        },
        // We should consider create a new settings tab for this kind of account global settings
        {
          id: 'only_paid_commissions_for_producers',
          type: 'boolean',
          label: 'Show only paid/approved commissions to producers',
          disabled: userRole !== Roles.ACCOUNT_ADMIN,
        },
        {
          id: 'comp_grids_enabled',
          type: 'boolean',
          label: 'Enable comp grids',
          disabled: userRole !== Roles.ACCOUNT_ADMIN,
        },
        {
          id: 'accounting_transactions_enabled',
          type: 'boolean',
          label: 'Enable accounting transactions',
          disabled: userRole !== Roles.ACCOUNT_ADMIN,
        },
        {
          id: 'per_agent_payout_status',
          type: 'boolean',
          label: 'Enable individual agent payout status',
          disabled: userRole !== Roles.ACCOUNT_ADMIN,
        },
        {
          id: 'white_label_mode',
          label: 'Company logo',
          type: 'boolean',
          disabled: userRole !== Roles.ACCOUNT_ADMIN,
        },
        {
          id: 'logo_url',
          label: 'Account logo',
          type: 'custom',
          render: (field, row, setter) => (
            <UploadAccountLogo
              onChange={(logo_url, file) => {
                setAccountLogoFile(file);
                setter({ ...row, logo_url });
              }}
              data={row}
              key="logo_url"
            />
          ),
        },
        { id: 'divider', type: 'divider', access: 'admin', label: 'Admin' },
        {
          id: 'str_id',
          label: 'Account ID',
          access: 'admin',
          readOnly: true,
        },
        {
          id: 'mode',
          label: 'Mode',
          type: 'select',
          options: ['default', 'insurance'],
          access: 'admin',
        },
        {
          id: 'type',
          label: 'Type',
          access: 'admin',
          type: 'select',
          options: [
            { label: '🧪 Test', id: 'fintary' },
            { label: '👪 Life', id: 'life' },
            { label: '🧑‍⚕️ Health', id: 'health' },
            { label: '🏡 P&C', id: 'p&c' },
          ],
        },
        {
          id: 'csms',
          label: 'CSM(s)',
          access: 'admin',
          type: 'custom',
          render: (field, row, setter) => (
            <MultiSelect
              label="CSM(s)"
              key="csms"
              values={csms}
              selectedValues={row.csms ? Object.values(row.csms) : []}
              setSelectedValues={(newValues) =>
                setter({ ...row, csms: newValues })
              }
              formatter={Formatter.contact}
              valuer={(csms) => JSON.stringify(csms)}
              enableSearch={false}
              paginate={false}
              sx={{
                width: '100%',
                '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                },
                '.MuiSelect-select': {
                  color: 'black',
                },
                '.MuiSvgIcon-root': {
                  fill: 'grey !important',
                },
                '.MuiInputLabel-root': {
                  color: 'grey',
                },
              }}
            />
          ),
        },
        {
          id: 'status',
          label: 'Status',
          access: 'admin',
          type: 'select',
          options: [
            { label: '✅ Active', id: 'active' },
            { label: '⏳ Onboarding', id: 'onboarding' },
            { label: '👀 Demo', id: 'demo' },
            { label: '❌ Closed', id: 'closed' },
          ],
        },
      ],
    };
  }, [selectedAccount?.accountId, userRole, csms]);

  userData.fields.filter((field) => field.id === 'email')[0].default =
    impersonatedUser?.email ?? auth?.currentUser?.email;

  const dataCallback = async (accountData) => {
    if (accountData.logo_url && accountLogoFile) {
      try {
        await uploadFilesToStorage(
          accountData.storage_logo_url,
          accountLogoFile
        );
        setAccountLogoFile(null);
      } catch (error) {
        console.log('Error uploading file', error);
      }
    }
  };

  return (
    <Box>
      <Box sx={{ height: '300px' }}>
        <DataView dataDesc={userData} formModeOnly embed />
      </Box>
      <Box sx={{ height: '300px' }}>
        <DataView
          dataDesc={accountData}
          formModeOnly
          embed
          dataCallback={dataCallback}
          readOnly={userRole !== Roles.ACCOUNT_ADMIN}
        />
      </Box>
    </Box>
  );
};

export default GeneralSettings;
