import { useMediaQuery } from '@mui/material';
import { AccountIds } from 'common/constants';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useState, useEffect } from 'react';

import { SyncedEntity } from '@/common/SyncedEntity';
import { SyncEndAdornment } from '@/common/SyncEndAdornment';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import { ReconciliationConfirmProvider } from '@/contexts/ReconciliationConfirmProvider';
import { useSyncedFieldsNew } from '@/contexts/useSyncedFields';
import API from '@/services/API';
import Reports from '@/services/Reports';
import { useAccountStore, useRoleStore } from '@/store';
import { Roles } from '@/types';
import useSnackbar from '@/contexts/useSnackbar';

const exportOptions = [
  {
    id: 'export',
    label: 'Export',
    options: {},
  },
  // If we want to enable the export producer view, we can add the following
  // {
  //   id: 'export-producer-view',
  //   label: 'Export producer view',
  //   options: { producer_view: true },
  // },
];

const PolicyDataView = ({ reportId = null }) => {
  const [searchParams, _] = useSearchParams();
  const location = useLocation();
  const prefillData = location.state?.prefillData;

  const { data: settingsData, isFetched: isFetchedUserSettings } =
    API.getUser();
  const { data: accountSettings, isFetched: isFetchedAccountSettings } =
    API.getBasicQuery(`accounts/settings`);
  const { selectedAccount } = useAccountStore();
  const { userRole } = useRoleStore();
  const mode = selectedAccount?.accountMode;
  const syncPolicy = API.getMutation(
    'data_processing/sync/nowcerts/policy',
    'POST'
  );
  const [filterCacheKey, setFilterCacheKey] = useState([]);
  const queryClient = useQueryClient();

  const { showSnackbar } = useSnackbar();

  // useEffect(() => {
  //   // 如果有预填充数据，设置到表单中
  //   if (prefillData) {
  //     setNewData((prev) => ({
  //       ...prev,
  //       ...prefillData
  //     }));
  //   }
  // }, [prefillData]);
  useEffect(() => {
    // Cancel previous query when searchParams or selectedAccount changes, avoid previous query from returning stale data and causing UI mismatch
    setFilterCacheKey((prev) => {
      const cacheKey = [
        selectedAccount?.accountId,
        // Filter out pagination & order params from searchParams
        new URLSearchParams(
          Array.from(searchParams.entries()).filter(
            ([key]) => !['limit', 'page', 'sort', 'orderBy'].includes(key)
          )
        ).toString(),
      ];
      if (prev.length > 0 && prev.join() !== cacheKey.join()) {
        queryClient.cancelQueries({ queryKey: prev });
      }
      return cacheKey;
    });
  }, [queryClient, searchParams, selectedAccount?.accountId]);

  const { data: filters } = useQuery({
    queryKey: filterCacheKey,
    queryFn: async ({ signal }) => {
      const url = `${process.env.REACT_APP_API}/api/report_data/filters?${new URLSearchParams(searchParams).toString()}`;
      try {
        const res = await fetch(url, {
          method: 'GET',
          headers: await API.getHeaders(),
          signal,
        });
        return res.json();
      } catch (err) {
        console.error(err);
        return {};
      }
    },
  });

  const isTrailStoneAccount =
    AccountIds.TRAILSTONE === selectedAccount?.accountId;
  const reports = new Reports(mode, { account_id: selectedAccount?.accountId });
  const isMobile = useMediaQuery('(max-width:600px)');

  const pageSettingFields = isMobile
    ? accountSettings?.pages_settings?.policies?.outstandingMobileFields
    : accountSettings?.pages_settings?.policies?.fields;
  const newFields = (pageSettingFields ?? []).reduce((acc, cur) => {
    acc[cur] = {
      ...reports.fields[cur],
    };
    return acc;
  }, {});
  if (Object.keys(newFields).length > 0) reports.fields = newFields;

  if (accountSettings?.pages_settings?.policies?.page_label) {
    reports.label = accountSettings?.pages_settings?.policies?.page_label;
  }

  if (
    isFetchedAccountSettings &&
    accountSettings?.pages_settings?.policies?.show_page === false
  ) {
    return (
      // TODO: Remove navigate after figuring out how to handle this in router
      <Navigate to="/settings" />
      // <Box sx={{ textAlign: 'center', mt: 6, width: '100%' }}>
      //   <Typography variant="h5">No results</Typography>
      // </Box>
    );
  }
  const { workerSyncedFields, isSyncedField } = useSyncedFieldsNew();

  for (const key in reports.fields) {
    const field = reports.fields[key];
    field.readOnly =
      field.readOnly ||
      ((data) => {
        const syncedFields =
          workerSyncedFields?.[data?.sync_worker]?.report_data;
        if ((syncedFields || []).includes(field.id)) {
          return isSyncedField(data, syncedFields, field.id, data.config);
        }
        return false;
      });
    field.endAdornment = (data, field, setNewData) => (
      <SyncEndAdornment
        syncedFields={workerSyncedFields?.[data?.sync_worker]?.report_data}
        syncId={data?.sync_id}
        fieldId={field?.id}
        data={data}
        fieldType={field.type}
        onChange={(newOverrideFields) => {
          setNewData({
            ...data,
            config: {
              ...(data.config || {}),
              overrideFields: newOverrideFields,
            },
          });
        }}
      />
    );
  }

  const onBulkSync = async (params) => {
    await syncPolicy.mutateAsync(params);
    showSnackbar('Sync successfully', 'success');
  };

  return settingsData && isFetchedUserSettings && isFetchedAccountSettings ? (
    <ReconciliationConfirmProvider mode={mode}>
      <EnhancedDataView
        reportId={reportId}
        dataSpec={reports}
        exportOptions={exportOptions}
        outstandingMobileFields={
          accountSettings?.pages_settings?.policies?.outstandingMobileFields
        }
        defaultData={prefillData}
        filters={filters}
        enableSaves
        showTotals
        onBulkSync={
          isTrailStoneAccount ? (ids) => onBulkSync({ ids }) : undefined
        }
        actions={[
          ...(reports.actions ?? []),
          ...[
            {
              type: isTrailStoneAccount ? 'iconButton' : 'icon',
              label: 'Sync',
              icon: (
                <SyncedEntity isSynced={true} disabled={!isTrailStoneAccount} />
              ),
              enabled: (row) => !!row.sync_id,
              onClick: isTrailStoneAccount
                ? async (row, e) => {
                    e?.stopPropagation();
                    e?.preventDefault();
                    if (isTrailStoneAccount) {
                      await onBulkSync({ syncId: row.sync_id });
                    }
                  }
                : () => {},
            },
          ],
        ]}
        actionsEnabled={(row) => {
          return !!row.sync_id || reports.actions.length > 0;
        }}
        // TODO (frank.santillan): Move to settings after we migrate reconciliation / commissions / policies to pages_settings.
        readOnly={userRole === Roles.PRODUCER}
      />
    </ReconciliationConfirmProvider>
  ) : null;
};

export default PolicyDataView;
