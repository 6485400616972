import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

const TextPreview = ({ file }) => {
  const [fileContent, setFileContent] = useState<string>('');

  useEffect(() => {
    if (!file) {
      setFileContent('');
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target?.result || '';
      setFileContent(text as any);
    };
    reader.onerror = () => {
      setFileContent('');
    };
    reader.readAsText(file);
  }, [file]);

  return (
    <Box>
      <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
        {fileContent}
      </pre>
    </Box>
  );
};

export default TextPreview;
