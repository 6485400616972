import { useQuery } from '@tanstack/react-query';

import { SelectProps } from './types';

export const AsyncSelect = <T, K extends boolean = false>({
  url,
  dataKey,
}: SelectProps<T, K>) => {
  // Todo: async select will load data from the url
  // Extract data based on dataKey path
  const {} = useQuery({ queryKey: [url, dataKey], queryFn: () => {} });

  return null;
};
