const findAllDate = (json: any): string[] => {
  const datePattern = [
    /\b(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)[a-z]*\s\d{4}\b/, // "Oct 2024"
    // eslint-disable-next-line no-useless-escape
    /\b\d{1,2}[\/\-]\d{1,2}[\/\-]\d{2,4}\b/, // "1/1/2024", "1/1/24", "01/01/2024", "01-01-24", "01-01-2024"
    // eslint-disable-next-line no-useless-escape
    /\b\d{2,4}[-\/]\d{1,2}[-\/]\d{1,2}\b/, // "2024/1/1", "24/1/1", "2024/01/01", "24-01-01", "2024-01-01"
  ];

  const foundDates: string[] = [];

  json.Lines?.forEach((lineItem: any) => {
    lineItem.LinesArray.forEach((line: any) => {
      datePattern.forEach((pattern) => {
        const matches = line.Line.match(pattern);
        if (matches) {
          foundDates.push(...matches);
        }
      });
    });
  });

  return foundDates;
};

export const desc_findAllDate = {
  name: 'libs.tools.findAllDate(json)',
  description:
    'Finds all date values in a JSON object that match common date formats in the text content.',
  arguments: {
    json: '[Object] JSON object containing Lines array with text content to search',
  },
  returns: '[Array<string>] Array of found date strings from the content',
  examples:
    'findAllDate({\n  Lines: [{\n    LinesArray: [{\n      Line: "Document from Oct 2024 and 01/02/2024"\n    }]\n  }]\n})\n// → ["Oct 2024", "01/02/2024"]\n',
};

export default findAllDate;
