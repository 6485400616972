import { useEffect, useState, FC } from 'react';
import { nanoid } from 'nanoid';
import { Box } from '@mui/material';

import FactoryFileUpload from '@/common/Uplaod/FactoryFileUpload';
import useDownloadStorageFile from '@/contexts/useDownloadStorageFile';
import { useAccountStore } from '@/store';

interface UploadAccountLogoProps {
  onChange: (value: string, file: File) => void;
  data: any;
}

const UploadAccountLogo: FC<UploadAccountLogoProps> = ({ onChange, data }) => {
  const [logoUrl, setLogoUrl] = useState('');
  const [path, setPath] = useState('');
  const [curFile, setCurFile] = useState<File | null>(null);
  const { selectedAccount } = useAccountStore();

  const { downloadFile } = useDownloadStorageFile();

  useEffect(() => {
    const getFileUrl = async () => {
      if (data.str_id && data.exists_in_storage && !curFile) {
        const file = await downloadFile({
          endpoint_str_id: data.str_id,
          file_preview_type: 'logo',
          endpoint: 'accounts',
        });
        if (!file) return;
        const url = URL.createObjectURL(file);
        setLogoUrl(url);
      }
    };
    getFileUrl();
  }, [data]);

  const handleFileChange = async (files) => {
    const file = files[0];
    if (!file) return;
    setCurFile(file);
    setPath(file.name);
  };

  useEffect(() => {
    if (path && curFile) {
      onChange(path, curFile);
    }
  }, [onChange, path, curFile]);

  useEffect(() => {
    if (!curFile) return;
    const url = URL.createObjectURL(curFile);
    setLogoUrl(url);
  }, [curFile]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        ml: 4,
      }}
      key="accountLogo"
    >
      {logoUrl && (
        <Box sx={{ mr: 4 }}>
          <img src={logoUrl} alt="logo" style={{ maxHeight: '100px' }} />
        </Box>
      )}
      <Box>
        <FactoryFileUpload onChange={handleFileChange} imageOnly={true} />
      </Box>
    </Box>
  );
};

export default UploadAccountLogo;
