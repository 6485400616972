import {
  Add,
  DeleteOutline,
  DownloadOutlined,
  LaunchOutlined,
  Start,
  SyncOutlined,
} from '@mui/icons-material';
import { Alert, Box, Button, Chip, IconButton, Tooltip } from '@mui/material';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { numberOrDefault } from 'common/helpers';
import { useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import dayjs from 'dayjs';
import CommonFormatter from 'common/Formatter';

import UploadOverrideFile from '@/components/documents/DocumentsView/DocumentOverrideFile';
import ExtractMethod from '@/components/documents/DocumentsView/ExtractMethod';
import UpdateProcessData from '@/components/documents/DocumentsView/UpdateProcessData';
import { LoadingContext } from '@/contexts/LoadingContext';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { useSetOriginFile } from '@/store/excelStore';
import { BasicDialog, FileDialogPreview } from '@/common';
import { PDF_IMG_TYPES } from '@/common/preview/model';
import { DocumentPreviewKeys } from '@/types';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import { useAccountStore } from '@/store';
import usePreviewParams from '@/contexts/usePreviewParams';
import useDownloadStorageFile from '@/contexts/useDownloadStorageFile';
import useSnackbar from '@/contexts/useSnackbar';

const DocumentsView = ({ variant = '' }) => {
  const { selectedAccount } = useAccountStore();
  const queryClient = useQueryClient();
  const enableAccountId = ['W4kSrayZvmh26pGfYVrGE', 'fFF86XAy2Cu97xxra8lgA'];
  const isRiskTag =
    enableAccountId.includes(selectedAccount?.accountId || '') ||
    selectedAccount?.accountName?.toLowerCase().includes('risktag');

  const [sync, setSync] = useState({ documentId: '', show: false, count: 0 });

  const [open, setOpen] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showExtract, setShowExtract] = useState(false);

  const [rowData, setRowData] = useState({});
  const { setLoadingConfig } = useContext(LoadingContext);
  const { downloadFile } = useDownloadStorageFile();

  const setUploadedFile = useSetOriginFile();
  const { showSnackbar } = useSnackbar();

  const documentsDelete = API.getMutation('documents', 'DELETE');
  const syncStatement = API.getMutation(
    'data_processing/sync/benefit-point/statements',
    'POST'
  );

  const { showPreview, setShowPreview, previewId, setPreviewPath } =
    usePreviewParams();

  const filePathFormatter = (filename, row) =>
    filename ? (
      <Tooltip title={filename} arrow enterNextDelay={1000}>
        <Button
          onClick={async () => {
            setPreviewPath(row.str_id, DocumentPreviewKeys.ORIGINAL);
            setShowPreview(true);
          }}
        >
          <span
            style={{
              maxWidth: 240,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left',
            }}
          >
            {filename}
          </span>
        </Button>
      </Tooltip>
    ) : (
      '--'
    );

  const deleteData = async (row) => {
    try {
      setLoadingConfig({
        loading: true,
        message: 'Deleting...',
      });
      await documentsDelete.mutateAsync({ id: row.id });
      queryClient.invalidateQueries();
      setLoadingConfig({
        loading: false,
        message: '',
      });
      showSnackbar(`Deleted ${row.filename}`, 'success');
    } catch (error: any) {
      setLoadingConfig({
        loading: false,
        message: '',
      });
      showSnackbar(error.message || error, 'error');
    }
  };

  const extractData = async (row) => {
    try {
      setLoadingConfig({
        loading: true,
        message: 'Loading...',
      });
      const file = await downloadFile({
        file_preview_type: row.override_file_path ? 'override' : 'original',
        endpoint_str_id: row.str_id,
        endpoint: 'documents',
      });
      if (!file) {
        setLoadingConfig({
          loading: false,
          message: '',
        });
        throw new Error('Failed to download file');
      }
      setRowData({
        ...row,
      });
      setUploadedFile(file);

      setLoadingConfig({
        loading: false,
        message: '',
      });
      if (PDF_IMG_TYPES.includes(file.type)) {
        setShowExtract(true);
      } else {
        setOpen(true);
      }
    } catch (error: any) {
      setLoadingConfig({
        loading: false,
        message: '',
      });

      const tip = error.message || error;
      showSnackbar(tip, 'error');
    }
  };

  const fileActionFormatter = (s, row) =>
    row.override_file_path || row.file_path ? (
      <>
        <Box className="flex">
          <Tooltip
            title={
              row.extractions.length ||
              row.filename.includes('.xls') ||
              row.filename.includes('.csv')
                ? 'Process'
                : 'Need to get the extracted data first'
            }
            placement="top"
            arrow
          >
            <Button
              startIcon={<Start />}
              variant={row.status === 'new' ? 'contained' : 'outlined'}
              onClick={() => extractData(row)}
            >
              Process
            </Button>
          </Tooltip>
          {row.status === 'new' && (
            <IconButton className="ml-1" onClick={() => deleteData(row)}>
              <DeleteOutline />
            </IconButton>
          )}
        </Box>
      </>
    ) : (
      '--'
    );

  const overrideFilePathFormatter = (s, row) =>
    s ? (
      <Tooltip title={s} arrow enterNextDelay={1000}>
        <Button
          onClick={async () => {
            setPreviewPath(row.str_id, DocumentPreviewKeys.OVERRIDE);
            setShowPreview(true);
          }}
        >
          <span
            style={{
              maxWidth: 240,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left',
            }}
          >
            {s}
          </span>
        </Button>
      </Tooltip>
    ) : (
      <Box sx={{ ml: 4 }}>
        <Tooltip title="Add override file" placement="right" arrow>
          <IconButton
            onClick={() => {
              setRowData(row);
              setShowUploadModal(true);
            }}
          >
            <Add sx={{ height: 18 }} />
          </IconButton>
        </Tooltip>
      </Box>
    );

  const dataDesc = {
    label: 'Documents',
    table: 'admin/documents',
    fields: {
      account: {
        label: 'Account',
        enabled: true,
        formatter: Formatter.getLinkChipFormatter(
          'name',
          'str_id',
          '/admin/accounts?id='
        ),
      },
      filename: {
        label: 'File',
        enabled: true,
        copyable: true,
        formatter: filePathFormatter,
      },
      override_filename: {
        label: 'Override file',
        enabled: true,
        copyable: true,
        formatter: overrideFilePathFormatter,
        access: 'admin',
      },
      type: {
        label: 'Type',
        enabled: true,
        formatter: Formatter.documentType,
      },
      companies: {
        label: 'Company',
        enabled: true,
        formatter: Formatter.getLinkChipFormatter(
          'company_name',
          'str_id',
          '/admin/companies?id='
        ),
      },
      statement_data: {
        label: 'Records',
        disableSort: true,
        enabled: true,
        formatter: (val, row) => {
          const groupedCountInfoStrList: string[] = [];
          const groupedCommissionInfoStrList: string[] = [];
          if (val.groupedCountInfo) {
            Object.entries(val.groupedCountInfo).forEach(([key, value]) => {
              if (key !== 'NO_STATUS') {
                groupedCountInfoStrList.push(`${key}: ${value}`);
              }
            });
          }
          if (val.groupedCommissionInfo) {
            Object.entries(val.groupedCommissionInfo).forEach(
              ([key, value]) => {
                if (key !== 'NO_STATUS') {
                  groupedCommissionInfoStrList.push(
                    `${key}: ${Formatter.currency(value)}`
                  );
                }
              }
            );
          }
          return (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ flex: 1 }}>
                  {!!val.total_count && (
                    <Box
                      sx={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <span>{+val.total_count}</span>
                      {groupedCountInfoStrList.length > 0 && (
                        <span
                          style={{
                            color: '#444',
                            fontSize: 13,
                          }}
                        >
                          ({groupedCountInfoStrList.toString()})
                        </span>
                      )}
                    </Box>
                  )}
                  {!!val?.total_commission && (
                    <Box
                      sx={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <span>{Formatter.currency(val.total_commission)}</span>
                      {groupedCommissionInfoStrList.length > 0 && (
                        <span
                          style={{
                            color: '#444',
                            fontSize: 13,
                          }}
                        >
                          ({groupedCommissionInfoStrList.toString()})
                        </span>
                      )}
                    </Box>
                  )}
                  {!val?.total_commission && !val.total_count && '0'}
                </Box>
                <Box>
                  {(val.total_commission > 0 || val.total_count > 0) && (
                    <>
                      <IconButton
                        component={Link}
                        to={`/${row.type === 'statement' ? 'commissions' : 'policies'}?q=${row.str_id}`}
                        target="_blank"
                        sx={{
                          opacity: 0.5,
                          '&:hover': { opacity: 1 },
                          color: '#2196f3',
                        }}
                      >
                        <LaunchOutlined />
                      </IconButton>
                      {isRiskTag && (
                        <span>
                          {row?._count?.statement_data > 0 && (
                            <Tooltip
                              title={`${row._count.statement_data} statement entries will be synced to BenefitPoint`}
                              placement="top"
                            >
                              <IconButton
                                onClick={() =>
                                  setSync({
                                    documentId: row.str_id,
                                    show: true,
                                    count: row?._count?.statement_data || 0,
                                  })
                                }
                                size="small"
                                sx={{
                                  opacity: 0.5,
                                  '&:hover': { opacity: 1 },
                                  color: '#2196f3',
                                }}
                              >
                                <SyncOutlined />
                              </IconButton>
                            </Tooltip>
                          )}
                          <IconButton
                            // onClick={() => exportData(row)}
                            size="small"
                            sx={{
                              opacity: 0.5,
                              '&:hover': { opacity: 1 },
                              color: '#2196f3',
                            }}
                          >
                            <DownloadOutlined />
                          </IconButton>
                        </span>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </>
          );
        },
      },
      bank_total_amount: {
        label: 'Commission totals',
        enabled: true,
        disableSort: true,
        formatter: (v, row) => {
          const statementData = row.statement_data;
          const bankTotalAmount = numberOrDefault(row.bank_total_amount, null, {
            toFixed: 2,
          });
          const totalCommissionAmount = numberOrDefault(
            statementData.total_commission,
            null,
            { toFixed: 2 }
          );
          const statementTotalAmount = numberOrDefault(
            row.imports?.[0]?.statement_total_amount,
            null,
            { toFixed: 2 }
          );

          if (row.status === 'new') {
            return `Bank total: ${Formatter.currency(bankTotalAmount) || 'n/a'}`;
          }

          const bankAndTotalCmsIsEqual = isEqual(
            bankTotalAmount,
            totalCommissionAmount
          );
          const statementAndCmsTotalIsEqual = isEqual(
            totalCommissionAmount,
            statementTotalAmount
          );

          let matchesNode = <></>;

          if (
            bankAndTotalCmsIsEqual &&
            statementAndCmsTotalIsEqual &&
            bankTotalAmount !== null
          ) {
            const tip =
              'Bank total, statement total, and commission records all match';
            matchesNode = (
              <Tooltip title={tip} placement="right" arrow>
                <span>{`✅ ${Formatter.currency(bankTotalAmount)}`}</span>
              </Tooltip>
            );
          } else if (bankAndTotalCmsIsEqual && totalCommissionAmount) {
            const tip = (
              <span>
                Bank total and commissions match
                <br />
                (Statement total not available)
              </span>
            );
            matchesNode = (
              <Tooltip title={tip} placement="right" arrow>
                <span>{`✅ ${Formatter.currency(bankTotalAmount)}`}</span>
              </Tooltip>
            );
          } else if (statementAndCmsTotalIsEqual && totalCommissionAmount) {
            const tip =
              'Statement total and commission records match (Bank total not available)';
            matchesNode = (
              <Tooltip title={tip} placement="right" arrow>
                <span>{`✅ ${Formatter.currency(statementTotalAmount)}`}</span>
              </Tooltip>
            );
          } else if (
            !bankTotalAmount &&
            !statementTotalAmount &&
            !totalCommissionAmount
          ) {
            matchesNode = <span>0</span>;
          } else if (
            totalCommissionAmount &&
            !statementTotalAmount &&
            !bankTotalAmount
          ) {
            matchesNode = (
              <Tooltip
                title={
                  <span>
                    Validation not available.
                    <br />
                    Bank total and/or statement total required.
                  </span>
                }
                placement="right"
                arrow
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Box sx={{ fontSize: 12 }}>ℹ️</Box>
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <span>
                      {`Commissions: ${Formatter.currency(totalCommissionAmount)}`}
                    </span>
                  </Box>
                </Box>
              </Tooltip>
            );
          } else {
            matchesNode = (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box sx={{ fontSize: 12 }}>❌</Box>
                <Box
                  sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span>{`Commissions: ${Formatter.currency(totalCommissionAmount)}`}</span>
                  <span>{`Bank total: ${Formatter.currency(bankTotalAmount) || 'n/a'}`}</span>
                  <span>{`Statement total: ${Formatter.currency(statementTotalAmount) || 'n/a'}`}</span>
                </Box>
              </Box>
            );
          }
          return <Box sx={{ whiteSpace: 'nowrap' }}>{matchesNode}</Box>;
        },
      },
      created_by: {
        label: 'Imported total',
        enabled: true,
        disableSort: true,
        formatter: (v, row) => {
          const list = row.imports;
          if (list && list.length) {
            const target = list[0];
            return Formatter.currency(target?.summed_total_amount);
          }
        },
        access: 'admin',
      },
      status: {
        label: 'Status',
        enabled: true,
        formatter: (val) =>
          Formatter.statusChip(val, {
            mapping: { new: 'yellow', processed: 'green' },
          }),
      },
      method: {
        label: 'Method',
        enabled: true,
        access: 'admin',
        formatter: (val: string, row) =>
          val ? (
            <Chip
              label={val}
              component={Link}
              to={`/documents/profiles?id=${row.profile_str_id}`}
            />
          ) : null,
      },
      notes: {
        label: 'Notes',
        enabled: true,
      },
      imported_at: {
        label: 'Imported at',
        enabled: true,
        access: 'admin',
        formatter: (s: string, row) => {
          if (!s) {
            return '';
          }
          const uploadToImportTime = dayjs(s).diff(
            dayjs(row.created_at),
            'milliseconds'
          );
          const uploadedInRes = `${CommonFormatter.duration(Math.abs(uploadToImportTime), { truncate: 'seconds' })}`;
          return `${Formatter.date(s, {
            format: 'MM/DD/YYYY hh:mmA',
          })} (${uploadedInRes})`;
        },
      },
      created_at: {
        label: 'Uploaded at',
        enabled: true,
        formatter: (s: string) => {
          return Formatter.date(s, {
            format: 'MM/DD/YYYY hh:mmA',
          });
        },
        readOnly: true,
      },
      id: {
        label: 'Actions',
        enabled: true,
        disableSort: true,
        formatter: fileActionFormatter,
        access: ['admin', 'demo'],
      },
    },
    queryChips: {
      all: {
        id: 'all',
        label: 'All',
        query: {},
      },
      new: {
        id: 'new',
        label: 'New',
        query: {
          status: 'new',
        },
      },
      processed: {
        id: 'processed',
        label: 'Processed',
        query: {
          status: 'processed',
        },
      },
    },
  };

  const onConfirmMethod = (method) => {
    setRowData((prev) => ({ ...prev, method }));
    setShowExtract(false);
    setOpen(true);
  };

  const extraActions = [
    {
      type: 'dateRange',
      label: 'Uploaded at',
      value: {
        startDate: null,
        endDate: null,
      },
    },
  ];

  const syncToBenefit = async (data) => {
    try {
      setLoadingConfig({
        loading: true,
        message: 'Syncing...',
      });
      const ret = await syncStatement.mutateAsync(data);
      if (ret.success === false || ret.message) {
        const tip = <Alert severity="error">{ret.message}</Alert>;
        showSnackbar(tip);
      } else {
        showSnackbar(
          <Alert severity="success">
            Sync succeessfully, synced statmentID: {ret.statementId}
          </Alert>
        );
      }
    } catch (error: any) {
      const tip = <Alert severity="error">{error.message || error}</Alert>;
      showSnackbar(tip);
    } finally {
      setLoadingConfig({
        loading: false,
        message: '',
      });
    }
  };

  return (
    <>
      <EnhancedDataView
        dataSpec={dataDesc}
        hideAdd
        hideSelectedCount
        enableMultiSelect={false}
        enableEdit={false}
        // @ts-ignore
        extraActions={extraActions}
        variant={variant}
      />

      {open && (
        <UpdateProcessData
          open={open}
          rowData={rowData}
          setRowData={setRowData}
          handleClose={(arg) => {
            queryClient.invalidateQueries();
            setOpen(arg);
          }}
        />
      )}
      {showUploadModal && (
        <UploadOverrideFile
          open={showUploadModal}
          setOpen={setShowUploadModal}
          uploadedRow={rowData}
        />
      )}
      {showExtract && (
        <ExtractMethod
          showExtract={showExtract}
          onClose={() => setShowExtract(false)}
          onConfirm={onConfirmMethod}
          uploadedRow={rowData}
        />
      )}

      {showPreview && (
        <FileDialogPreview
          showPreview={showPreview}
          setShowPreview={setShowPreview}
          fileId={previewId}
          isAdmin={true}
        />
      )}

      <BasicDialog
        open={sync.show}
        title="Sync data"
        bodyComponent={
          <Alert severity="warning">
            Are you sure you want to sync {sync.count} statement entries to
            BenefitPoint?
          </Alert>
        }
        onClose={(isOk) => {
          if (isOk) {
            syncToBenefit({ documentId: sync.documentId });
          }

          setSync({ ...sync, show: false });
        }}
        positiveLabel="Sync"
      />
    </>
  );
};

export default DocumentsView;
