import { Navigate } from 'react-router-dom';
import validator from 'validator';

import { SyncedEntity } from '@/common/SyncedEntity';
import { SyncEndAdornment } from '@/common/SyncEndAdornment';
import DataView from '@/components/DataView';
import { useSyncedFieldsNew } from '@/contexts/useSyncedFields';
import API from '@/services/API';

const dataDesc = {
  label: 'Companies',
  table: 'companies',
  editable: true,
  copyable: true,
  bulkAdd: true,
  validateData: (data) => data?.company_name,
  fields: [
    {
      id: 'company_name',
      label: 'Company Name',
      required: true,
    },
    {
      id: 'email',
      label: 'Email',
      validator: (val) => validator.isEmail(val) || val === '',
    },
    {
      id: 'website',
      label: 'Website',
      validator: (val) => validator.isURL(val) || val === '',
    },
    {
      id: 'phone',
      label: 'Phone',
      validator: (val) => validator.isMobilePhone(val, 'en-US') || val === '',
    },
    {
      id: 'address',
      label: 'Address',
    },
    {
      id: 'notes',
      label: 'Notes',
    },
    {
      id: 'type',
      label: 'Type',
      type: 'select',
      multiple: true,
      options: [
        'Carrier',
        'MGA/MGU',
        'General Agency',
        'Sub Agency',
        'Customer',
        'Partner',
      ],
      strToValue: (vals) => vals?.split(',')?.map((v) => v.trim()),
    },
    {
      id: 'divider',
      type: 'divider',
      access: 'admin',
      label: 'Admin',
    },
    {
      id: 'access',
      label: 'Access',
      type: 'select',
      options: ['account', 'global'],
      access: 'admin',
    },
  ],
  actions: [
    {
      id: 'sync',
      label: 'Sync',
      type: 'custom',
      getComponent: (row) => (
        <SyncedEntity isSynced={!!row.sync_id} disabled={true} />
      ),
    },
  ],
};

const CompaniesView = () => {
  const { data: accountSettings, isFetched: isFetchedAccountSettings } =
    API.getBasicQuery(`accounts/settings`);
  const viewSettings = accountSettings?.pages_settings?.companies;

  const viewOnly = viewSettings?.read_only ?? false;

  // const { syncedFields, isSyncedField } = useSyncedFields('companies');
  const { workerSyncedFields, isSyncedField } = useSyncedFieldsNew();
  dataDesc.fields.forEach((field) => {
    field.readOnly = (data) => {
      const syncedFields = workerSyncedFields?.[data?.sync_worker]?.companies;
      if (syncedFields?.includes(field.id)) {
        return isSyncedField(data, syncedFields, field.id, data.config);
      }
      return false;
    };
    field.endAdornment = (data, field, setNewData) => (
      <SyncEndAdornment
        syncedFields={workerSyncedFields?.[data?.sync_worker]?.companies}
        syncId={data?.sync_id}
        fieldId={field?.id}
        data={data}
        fieldType={field.type}
        onChange={(newOverrideFields) => {
          setNewData({
            ...data,
            config: {
              ...(data.config || {}),
              overrideFields: newOverrideFields,
            },
          });
        }}
      />
    );
  });

  if (viewSettings?.page_label) {
    dataDesc.label = viewSettings?.page_label;
  }

  if (isFetchedAccountSettings && viewSettings?.show_page === false) {
    return (
      // TODO: Remove navigate after figuring out how to handle this in router
      <Navigate to="/settings" />
    );
  }

  return (
    <DataView
      dataDesc={dataDesc}
      readOnly={viewOnly}
      viewOnly={viewOnly}
      enablePagination
    />
  );
};

export default CompaniesView;
