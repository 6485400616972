import { Option, SelectProps } from './types';
import { ObjectSelect } from './ObjectSelect';
import { StringSelect } from './StringSelect';
import { AsyncSelect } from './AsyncSelect';

export const EnhancedSelect = <
  T extends Option['value'] | object,
  K extends boolean = false,
>({
  options,
  ...rest
}: SelectProps<T, K>) => {
  if (rest.url) return <AsyncSelect<T, K> options={options} {...rest} />;

  const isStringSelect =
    typeof options[0] === 'string' || typeof options[0] === 'number';

  if (isStringSelect) return <StringSelect<T, K> options={options} {...rest} />;

  return <ObjectSelect<T, K> options={options} {...rest} />;
};
