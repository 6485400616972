const findAllTextLineWithContext = (
  json,
  str: string,
  range: [number, number] = [0, 0],
  exact: boolean = false
): string[][] => {
  const txt: string[][] = [];

  json.Lines?.forEach((lineItem: any) => {
    lineItem.LinesArray.forEach((line: any, index: number) => {
      const match = exact ? line.Line === str : line.Line.includes(str);
      if (match) {
        const startIdx = Math.max(0, index + range[0]);
        const endIdx = Math.min(
          lineItem.LinesArray.length - 1,
          index + range[1]
        );
        txt.push([]);

        for (let i = startIdx; i <= endIdx; i++) {
          txt[txt.length - 1].push(lineItem.LinesArray[i].Line);
        }
      }
    });
  });

  return txt;
};

export const desc_findAllTextLineWithContext = {
  name: 'libs.tools.findAllTextLineWithContext(json, str, range, exact)',
  description:
    'Finds text lines containing the specified text and returns arrays including surrounding context lines.',
  arguments: {
    json: '[Object] JSON object containing Lines array with text content',
    str: '[String] Text string to search for',
    range:
      '[Array<number>] Optional. Two-element array specifying lines before and after [-n, n]',
    exact:
      '[Boolean] Optional. If true, performs exact match instead of partial match',
  },
  returns:
    '[Array<Array<string>>] Arrays of text lines including context for each match',
  examples:
    '// With context\nfindAllTextLineWithContext(json, "text", [-1, 1])\n// → [["before", "text1", "after"], ["before", "text2", "after"]]\n\n// Exact match with context\nfindAllTextLineWithContext(json, "text", [-1, 1], true)\n// → [["before", "text", "after"]]\n',
};

export default findAllTextLineWithContext;
