import { RemoveCircleOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { RulesOperators } from 'common/globalTypes';

import { FieldTypes } from '@/types';
import FieldMatcherComponent from '@/components/molecules/FieldMatcherComponent';
import { EnhancedSelect } from './EnhancedSelect';

const isNumeric = (type: FieldTypes) =>
  [FieldTypes.CURRENCY, FieldTypes.PERCENTAGE, FieldTypes.INTEGER].includes(
    type
  );

const isDate = (type: FieldTypes) => {
  return [FieldTypes.DATE].includes(type);
};

// TODO: Add validations, enums and conditional rendering for options based on field types
export const fieldMatcherOptions = [
  { id: RulesOperators.EQ, label: 'Equals', shortLabel: '=' },
  { id: RulesOperators.NEQ, label: 'Not equals', shortLabel: '!=' },
  {
    id: RulesOperators.GT,
    label: 'Greater than',
    shortLabel: '>',
    validate: isNumeric,
  },
  {
    id: RulesOperators.LT,
    label: 'Less than',
    shortLabel: '<',
    validate: isNumeric,
  },
  {
    id: RulesOperators.GTE,
    label: 'Greater than or equals',
    shortLabel: '>=',
    validate: isNumeric,
  },
  {
    id: RulesOperators.LTE,
    label: 'Less than or equals',
    shortLabel: '>=',
    validate: isNumeric,
  },
  { id: RulesOperators.CONTAINS, label: 'Contains' },
  { id: RulesOperators.NCONTAINS, label: 'Not contains' },
  { id: RulesOperators.CONTAINEDIN, label: 'Contained in' },
  { id: RulesOperators.NCONTAINEDIN, label: 'Not contained in' },
  { id: RulesOperators.STARTSWITH, label: 'Starts with' },
  { id: RulesOperators.ENDSWITH, label: 'Ends with' },
  { id: RulesOperators.BEFORE, label: 'Before', validate: isDate },
  {
    id: RulesOperators.BEFORE_EQUALS,
    label: 'Before or equals',
    validate: isDate,
  },
  { id: RulesOperators.AFTER, label: 'After', validate: isDate },
  {
    id: RulesOperators.AFTER_EQUALS,
    label: 'After or equals',
    validate: isDate,
  },
  { id: RulesOperators.IS_EMPTY, label: 'Is empty' },
  { id: RulesOperators.WITHIN_ONE_YEAR, label: 'Within one year' },
  { id: RulesOperators.AT_LEAST_ONE_YEAR, label: 'At least one year' },
  { id: RulesOperators.UNKNOWN, label: 'Date range unknown' },
];

const FieldMatcher = ({
  fields,
  value: fieldsMatchers = [],
  setValue,
  addLabel = 'Add',
  hideUsePolicyData = false,
  sx = {},
}: {
  fields: any[];
  value: any[];
  setValue: (val: any) => void;
  addLabel?: string;
  hideUsePolicyData?: boolean;
  sx?: any;
}) => {
  const getSelectedField = (fieldId) => {
    return fields.find((f) => f.id === fieldId);
  };

  return (
    <Box sx={{ ...sx }}>
      {fieldsMatchers?.map(
        (fieldMatcher, i) =>
          fieldMatcher.type !== 'Action' && (
            <Box
              key={i}
              sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', width: '100%' }}
            >
              <EnhancedSelect
                enableSearch
                label="Field"
                options={fields?.sort((a, b) => (b.label > a.label ? -1 : 1))}
                labelKey="label"
                value={fields.find((item) => item.id === fieldMatcher.field)}
                onChange={(item) => {
                  const newFieldMatchers = [...fieldsMatchers];
                  newFieldMatchers[i].field = item.id;
                  setValue(newFieldMatchers);
                }}
                sx={{ marginRight: 1 }}
              />
              <EnhancedSelect
                label="Operation"
                options={fieldMatcherOptions.filter((op) => {
                  const selectedField = getSelectedField(
                    fieldMatcher?.field?.id
                  );
                  if (
                    op.validate &&
                    selectedField &&
                    !op.validate(selectedField?.type)
                  ) {
                    return false;
                  }
                  return true;
                })}
                labelKey="label"
                value={fieldMatcherOptions.find(
                  (item) => item.id === fieldMatcher.op
                )}
                onChange={(item) => {
                  const newFieldMatchers = [...fieldsMatchers];
                  newFieldMatchers[i].op = item.id;
                  setValue(newFieldMatchers);
                }}
                sx={{ marginRight: 1 }}
              />

              <FieldMatcherComponent
                key={i}
                fields={fields}
                fieldMatcher={fieldMatcher}
                fieldsMatchers={fieldsMatchers}
                i={i}
                setValue={setValue}
              />
              <FormControl sx={{ mr: 1 }}>
                <InputLabel>Case sensitivity</InputLabel>
                <Select
                  label="Case sensitivity"
                  value={fieldMatcher.caseSensitive}
                  onChange={(e) => {
                    const newFieldMatchers = [...fieldsMatchers];
                    newFieldMatchers[i].caseSensitive = e.target.value;
                    setValue(newFieldMatchers);
                  }}
                  sx={{ minWidth: 90 }}
                >
                  <MenuItem value="">&nbsp;</MenuItem>
                  {[
                    { id: 'true', label: 'True', value: true },
                    { id: 'false', label: 'False', value: false },
                  ].map((e) => (
                    <MenuItem key={e.id} value={(e.value as any) ?? e.id}>
                      {e.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {!hideUsePolicyData && (
                <FormControl sx={{ mr: 1 }}>
                  <InputLabel>Use policy data</InputLabel>
                  <Select
                    label="Use policy data"
                    value={fieldMatcher.usePolicyData}
                    onChange={(e) => {
                      const newFieldMatchers = [...fieldsMatchers];
                      newFieldMatchers[i].usePolicyData = e.target.value;
                      setValue(newFieldMatchers);
                    }}
                    sx={{ minWidth: 90 }}
                  >
                    <MenuItem value="">&nbsp;</MenuItem>
                    {[
                      { id: 'true', label: 'True', value: true },
                      { id: 'false', label: 'False', value: false },
                    ].map((e) => (
                      <MenuItem key={e.id} value={(e.value as any) ?? e.id}>
                        {e.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <FormControl sx={{ mr: 1 }}>
                <InputLabel>Skip empty</InputLabel>
                <Select
                  label="Skip empty"
                  value={fieldMatcher.skipEmpty}
                  onChange={(e) => {
                    const newFieldMatchers = [...fieldsMatchers];
                    newFieldMatchers[i].skipEmpty = e.target.value;
                    setValue(newFieldMatchers);
                  }}
                  sx={{ minWidth: 90 }}
                >
                  <MenuItem value="">&nbsp;</MenuItem>
                  {[
                    { id: 'true', label: 'True', value: true },
                    { id: 'false', label: 'False', value: false },
                  ].map((e) => (
                    <MenuItem key={e.id} value={(e.value as any) ?? e.id}>
                      {e.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton
                onClick={() => {
                  const newFieldMatchers = [...fieldsMatchers];
                  newFieldMatchers.splice(i, 1);
                  setValue(newFieldMatchers);
                }}
              >
                <RemoveCircleOutline />
              </IconButton>
            </Box>
          )
      )}
      <Button
        onClick={() => setValue([...fieldsMatchers, {}])}
        sx={{ mt: 0.5 }}
      >
        {addLabel}
      </Button>
    </Box>
  );
};

export default FieldMatcher;
