import { useContext, useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  TextField,
  Grid,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import CodeMirror from '@uiw/react-codemirror';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { WidgetGroup } from 'common/constants';
import { LoadingContext } from 'contexts/LoadingContext';

import useSnackbar from '@/contexts/useSnackbar';
import { UIStateContext } from '@/contexts/UIStateProvider';
import BoxWidget from './BoxWidget';
import ChartWidget from './ChartWidget';
import TableWidget from './TableWidget';
import WidgetWrapper from './WidgetWrapper';
import FieldMatcher from '../molecules/FieldMatcher';
import useAccountStore from '@/store/accountStore';
import FieldAggregationManager from '../molecules/FieldAggregation/FieldAggregationManager';

const dataSources = [
  'statement',
  'report',
  'reconciliation',
  'reportDataCommissionProcessingDate',
];
const dataSourceDisplayNames = {
  statement: 'Commissions',
  report: 'Policies',
  reconciliation: 'Reconciliations',
  reportDataCommissionProcessingDate: 'Policies Processing Date',
};
const filterFieldsByDataSource = {
  statement: {
    groupFields: [
      {
        name: 'writing_carrier_name',
        type: 'string',
        displayName: 'Writing carrier name',
      },
      {
        name: 'agent_name',
        type: 'string',
        displayName: 'Agent name',
      },
      {
        name: 'contacts',
        type: 'string',
        displayName: 'Agents',
      },
      {
        name: 'customer_name',
        type: 'string',
        displayName: 'Customer name',
      },
      {
        name: 'product_type',
        type: 'string',
        displayName: 'Product type',
      },
      {
        name: 'product_name',
        type: 'string',
        displayName: 'Product name',
      },
      {
        name: 'processing_date',
        type: 'date',
        displayName: 'Processing date',
      },
      {
        name: 'compensation_type',
        type: 'string',
        displayName: 'Compensation type',
      },
      {
        name: 'carrier_name',
        type: 'string',
        displayName: 'Carrier name',
      },
    ],
    dataFields: [
      {
        name: 'commission_amount',
        type: 'number',
      },
      {
        name: 'agent_commissions.total',
        type: 'obj',
        lookupKey: 'total',
      },
      {
        name: 'agent_commissions',
        type: 'obj',
      },
      {
        name: 'premium_amount',
        type: 'number',
      },
    ],
  },
  report: {
    groupFields: [
      {
        name: 'effective_date',
        type: 'date',
        displayName: 'Effective date',
      },
      {
        name: 'agent_name',
        type: 'string',
        displayName: 'Agent name',
      },
      {
        name: 'customer_name',
        type: 'string',
        displayName: 'Customer name',
      },
      {
        name: 'product_type',
        type: 'string',
        displayName: 'Product type',
      },
      {
        name: 'product_name',
        type: 'string',
        displayName: 'Product name',
      },
      {
        name: 'writing_carrier_name',
        type: 'string',
        displayName: 'Writing carrier name',
      },
      {
        name: 'policy_status',
        type: 'string',
        displayName: 'Policy status',
        availableValues: [
          'Active',
          'Inactive',
          'Active Client',
          'Inactive Client',
          'In Force',
          'Prospect/Lead',
          'duplicate',
        ],
      },
      {
        name: 'state',
        type: 'string',
        displayName: 'State',
        availableValues: ['duplicate', 'active', 'deleted'],
      },
    ],
    dataFields: [
      {
        name: 'commissionable_premium_amount',
        type: 'number',
      },
      {
        name: 'premium_amount',
        type: 'number',
      },
      {
        name: 'commissions_expected',
        type: 'number',
      },
      {
        name: 'customer_paid_premium_amount',
        type: 'number',
      },
    ],
  },
  reconciliation: {
    groupFields: [
      {
        name: 'carrier_name',
        type: 'string',
        displayName: 'Carrier name',
      },
      {
        name: 'agent_name',
        type: 'string',
        displayName: 'Agent name',
      },
      {
        name: 'customer_name',
        type: 'string',
        displayName: 'Customer name',
      },
      {
        name: 'product_type',
        type: 'string',
        displayName: 'Product type',
      },
      {
        name: 'product_name',
        type: 'string',
        displayName: 'Product name',
      },
      {
        name: 'writing_carrier_name',
        type: 'string',
        displayName: 'Writing carrier name',
      },
    ],
    dataFields: [
      {
        name: 'amount_paid',
        type: 'obj',
        lookupKey: 'amount_paid.amount_paid',
      },
      {
        name: 'aggregate_premiums',
        type: 'obj',
        lookupKey: 'aggregatePremiums.aggregatePremiums',
      },
    ],
  },
};
const widgetGroups = ['box', 'chart-donut', 'chart-line', 'chart-bar', 'table'];
const calculationValues = ['Sum', 'Count', 'Aggregate', 'Average'];

const WidgetCreator = ({ widgetOnEdit, createWidget, setWidgetModel }) => {
  const addSelector = () => {
    setDataFieldsAggregators([
      ...dataFieldsAggregators,
      {
        id: dataFieldsAggregators.length + 1,
        field: '',
        aggregation_method: '',
      },
    ]);
  };

  useEffect(() => {
    if (widgetOnEdit) {
      setSelectedDataSource(widgetOnEdit.dataSource || '');
      setSelectedCustomCode(widgetOnEdit.customCode || '');
      setSelectedWidgetType(widgetOnEdit.type || '');
      setSelectedGroupByValue(widgetOnEdit.groupBy || '');
      setSelectedDataFieldValue(widgetOnEdit.dataField || []);
      setDataFieldFilters(widgetOnEdit.dataFieldFilters || []);
      setDataFieldsAggregators(widgetOnEdit.aggregationSelectors || []);
      setGroupByFilters(widgetOnEdit.groupByFilters || []);
      setSelectedCalculationValue(widgetOnEdit.calculation || '');
      setSelectedResultFormatter(widgetOnEdit.resultFormatter || '');
      setSelectedShared(widgetOnEdit.shared || false);
      setApplyAlevoExcludedAgents(
        widgetOnEdit.applyAlevoExcludedAgents || false
      );
      setWidgetName(widgetOnEdit.name || '');
    } else {
      setSelectedDataSource('');
      setSelectedCustomCode('');
      setSelectedWidgetType('');
      setSelectedGroupByValue('');
      setSelectedDataFieldValue([]);
      setDataFieldFilters([]);
      setDataFieldsAggregators([]);
      setGroupByFilters([]);
      setSelectedCalculationValue('');
      setSelectedResultFormatter('');
      setSelectedShared(false);
      setApplyAlevoExcludedAgents(false);
      setWidgetName('');
    }
  }, [widgetOnEdit]);

  const removeSelector = (id) => {
    setDataFieldsAggregators(
      dataFieldsAggregators.filter((selector) => selector.id !== id)
    );
  };

  const updateSelector = (id, updatedField) => {
    setDataFieldsAggregators(
      dataFieldsAggregators.map((selector) =>
        selector.id === id ? { ...selector, ...updatedField } : selector
      )
    );
  };
  const [selectedDataSource, setSelectedDataSource] = useState(
    widgetOnEdit?.dataSource || ''
  );
  const [selectedCustomCode, setSelectedCustomCode] = useState(
    widgetOnEdit?.customCode || ''
  );
  const [selectedWidgetType, setSelectedWidgetType] = useState(
    widgetOnEdit?.type || ''
  );
  const [selectedGroupByValue, setSelectedGroupByValue] = useState(
    widgetOnEdit?.groupBy || ''
  );
  const [selectedDataFieldValue, setSelectedDataFieldValue] = useState(
    widgetOnEdit?.dataField || []
  );
  const [dataFieldFilters, setDataFieldFilters] = useState<any>(
    widgetOnEdit?.dataFieldFilters || []
  );
  const [dataFieldsAggregators, setDataFieldsAggregators] = useState<any>(
    widgetOnEdit?.aggregationSelectors || []
  );
  const [groupByFilters, setGroupByFilters] = useState<any>(
    widgetOnEdit?.groupByFilters || []
  );
  const [selectedCalculationValue, setSelectedCalculationValue] = useState(
    widgetOnEdit?.calculation || ''
  );
  const [selectedResultFormatter, setSelectedResultFormatter] = useState(
    widgetOnEdit?.resultFormatter || ''
  );
  const { showSnackbar } = useSnackbar();

  const [selectedShared, setSelectedShared] = useState(
    widgetOnEdit?.shared || false
  );

  const [applyAlevoExcludedAgents, setApplyAlevoExcludedAgents] = useState(
    widgetOnEdit?.applyAlevoExcludedAgents || false
  );
  const { selectedAccount } = useAccountStore();

  const [widgetName, setWidgetName] = useState(widgetOnEdit?.name || '');
  const [previewData, setPreviewData] = useState<any>(null);
  const { setLoadingConfig } = useContext(LoadingContext);
  const {
    role: [role],
  } = useContext(UIStateContext);
  const isFintaryAdmin = role === 'admin';
  const isAlevoAccount = selectedAccount?.accountName === 'Alevo';
  const handleDataFieldChange = (e) => {
    const { value } = e.target;
    setSelectedDataFieldValue(
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const handleCreateWidget = async () => {
    setLoadingConfig({
      loading: true,
      message: 'Creating widget...',
    });
    try {
      const result = await createWidget({
        name: widgetName,
        dataSource: selectedDataSource,
        type: selectedWidgetType,
        groupBy: selectedGroupByValue,
        groupByFilters: groupByFilters,
        dataField: selectedDataFieldValue,
        dataFieldFilters: dataFieldFilters,
        applyAlevoExcludedAgents: applyAlevoExcludedAgents,
        calculation: selectedCalculationValue,
        shared: selectedShared,
        resultFormatter: selectedResultFormatter,
        aggregationSelectors: dataFieldsAggregators,
        customCode: selectedCustomCode,
      });
      setPreviewData(result);
      setWidgetModel({
        id: result.id,
        name: widgetName,
        displayName: result.displayName,
        widgetGroup: selectedWidgetType.toUpperCase(),
        value: result.value,
        type: selectedWidgetType,
        spec: {
          dataSource: selectedDataSource,
          name: widgetName,
          type: selectedWidgetType,
          groupBy: selectedGroupByValue,
          groupByFilters: groupByFilters,
          dataFieldFilters: dataFieldFilters,
          applyAlevoExcludedAgents: applyAlevoExcludedAgents,
          dataField: selectedDataFieldValue,
          calculation: selectedCalculationValue,
          resultFormatter: selectedResultFormatter,
          customCode: selectedCustomCode,
          aggregationSelectors: dataFieldsAggregators,
          shared: selectedShared,
        },
      });
    } catch (error) {
      showSnackbar(`Error creating widget: ${error}`, 'error');
    }
    setLoadingConfig({
      loading: false,
    });
  };

  const selectWidget = (data) => {
    switch (data.widgetGroup) {
      case WidgetGroup.BOX:
        return BoxWidget(data);
      case WidgetGroup.TABLE:
        return TableWidget(data);
      case WidgetGroup.CHART:
        return <ChartWidget data={data.value} />;
      default:
        return null;
    }
  };

  const renderWidget = (data) => (
    <WidgetWrapper displayName={data.displayName}>
      {selectWidget(data)}
    </WidgetWrapper>
  );
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6">Create a new widget</Typography>

      <FormControl fullWidth margin="normal">
        <TextField
          sx={{ flex: 1 }}
          value={widgetName}
          label="Widget name"
          onChange={(e) => setWidgetName(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>Data source</InputLabel>
        <Select
          value={selectedDataSource}
          label="Data source"
          onChange={(e) => setSelectedDataSource(e.target.value)}
        >
          <MenuItem value="" disabled>
            Select data source
          </MenuItem>
          {dataSources.map((source) => (
            <MenuItem key={source} value={source}>
              {dataSourceDisplayNames[source]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>Widget type</InputLabel>
        <Select
          value={selectedWidgetType}
          label="Widget type"
          onChange={(e) => setSelectedWidgetType(e.target.value)}
        >
          <MenuItem value="" disabled>
            Select widget type
          </MenuItem>
          {widgetGroups.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {isFintaryAdmin && (
        <FormControl fullWidth margin="normal">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <label>Custom code</label>
            </AccordionSummary>
            <AccordionDetails>
              <CodeMirror
                title="Custom code"
                height="350px"
                value={selectedCustomCode}
                width="100%"
                onChange={(code) => {
                  setSelectedCustomCode(code);
                }}
              />
            </AccordionDetails>
          </Accordion>
        </FormControl>
      )}

      <FormControl fullWidth margin="normal">
        <InputLabel>Group by field</InputLabel>
        <Select
          value={selectedGroupByValue}
          label="Group by field"
          onChange={(e) => setSelectedGroupByValue(e.target.value)}
        >
          <MenuItem value="" disabled>
            Select group by field
          </MenuItem>
          {filterFieldsByDataSource[selectedDataSource]?.groupFields.map(
            (field) => (
              <MenuItem key={field.name} value={field.name}>
                {field.displayName}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
      {filterFieldsByDataSource[selectedDataSource]?.groupFields && (
        <Box>
          <label>Group by filter</label>
          <FieldMatcher
            fields={filterFieldsByDataSource[
              selectedDataSource
            ]?.groupFields.map((field) => {
              return {
                id: field.name,
                label: field.displayName,
              };
            })}
            hideUsePolicyData={true}
            value={groupByFilters}
            setValue={setGroupByFilters}
          />
        </Box>
      )}

      {filterFieldsByDataSource[selectedDataSource]?.dataFields && (
        <FormControl fullWidth margin="normal">
          <label>Data field aggregators</label>
          <FieldAggregationManager
            fields={filterFieldsByDataSource[selectedDataSource]?.dataFields}
            selectors={dataFieldsAggregators}
            addSelector={addSelector}
            removeSelector={removeSelector}
            updateSelector={updateSelector}
          />
        </FormControl>
      )}
      {filterFieldsByDataSource[selectedDataSource]?.dataFields && (
        <Box>
          <label>Data filters</label>
          <FieldMatcher
            fields={filterFieldsByDataSource[
              selectedDataSource
            ]?.dataFields.map((field) => {
              return {
                id: field.name,
                label: field.name,
              };
            })}
            hideUsePolicyData={true}
            value={dataFieldFilters}
            setValue={setDataFieldFilters}
          />
        </Box>
      )}
      {dataFieldsAggregators.length == 0 && (
        <FormControl fullWidth margin="normal">
          <InputLabel>Aggregation method</InputLabel>
          <Select
            value={selectedCalculationValue}
            label="Aggregation method"
            onChange={(e) => setSelectedCalculationValue(e.target.value)}
          >
            <MenuItem value="" disabled>
              Select aggregation method
            </MenuItem>
            {calculationValues.map((value) => {
              return (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
      {isAlevoAccount && (
        <FormControl fullWidth margin="normal">
          <Grid container spacing={2}>
            <Grid item>
              <Typography>Apply excluded agents</Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={applyAlevoExcludedAgents}
                onChange={() =>
                  setApplyAlevoExcludedAgents(!applyAlevoExcludedAgents)
                }
              />
            </Grid>
          </Grid>
        </FormControl>
      )}
      {isFintaryAdmin && (
        <FormControl fullWidth margin="normal">
          <Grid container spacing={2}>
            <Grid item>
              <Typography>
                Global{' '}
                <span
                  style={{
                    color: 'transparent',
                    textShadow: '0 0 0 #e8e8e8',
                  }}
                >
                  🔒
                </span>
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={selectedShared}
                onChange={() => setSelectedShared(!selectedShared)}
              />
            </Grid>
          </Grid>
        </FormControl>
      )}
      <hr />
      <Box mt={2} display={'flex'}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateWidget}
        >
          Preview
        </Button>
      </Box>
      <Box mt={4} mb={4} p={2} borderColor="grey.400" height={380}>
        {previewData && renderWidget(previewData)}
      </Box>
    </Box>
  );
};

export default WidgetCreator;
