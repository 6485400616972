import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import DashboardSettings from '@/components/SettingsView/DashboardSettings';
import DataProcessing from '@/components/SettingsView/DataProcessing';
import GeneralSettings from '@/components/SettingsView/GeneralSettings';
import PlanSettings from '@/components/SettingsView/PlanSettings';
import DataProcessingSettings from '@/components/SettingsView/DataProcessingSettings';
import UserManager from '@/components/SettingsView/UserManager';
import ViewsFieldsSettings from '@/components/SettingsView/ViewsFieldsSettings';
import { UIStateContext } from '@/contexts/UIStateProvider';
import { hasAccess, requiresFintaryAdmin } from '@/services/helpers';
import { useRoleStore } from '@/store';
import { Roles } from '@/types';
import AgentSettings from '@/components/SettingsView/AgentSettings';
import CompReportSettings from '@/components/SettingsView/CompReportSettings';
import ApiKeyDashboard from '@/components/SettingsView/ApiKeyDashboard';
import DataUpdateTools from '@/components/ToolsPage/DataUpdateTools/DataUpdateTools';

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    {...other}
  >
    {value === index && (
      <Box sx={{ pt: 2 }}>
        <Typography component="div">{children}</Typography>
      </Box>
    )}
  </div>
);

const tabs = [
  {
    label: 'General',
    path: 'general',
    component: <GeneralSettings />,
  },
  {
    label: 'User manager',
    path: 'user-manager',
    component: <UserManager />,
    access: [Roles.ACCOUNT_ADMIN],
  },
  {
    label: 'Data processing',
    path: 'data-processing',
    component: <DataProcessing />,
    access: [Roles.ACCOUNT_ADMIN],
  },
  {
    label: 'Plan',
    path: 'plan',
    component: <PlanSettings />,
    access: [Roles.ACCOUNT_ADMIN],
  },

  {
    label: 'Views & fields',
    path: 'views',
    component: <ViewsFieldsSettings />,
    access: [Roles.FINTARY_ADMIN],
  },
  {
    label: 'Dashboard',
    path: 'dashboard',
    component: <DashboardSettings />,
    access: [Roles.FINTARY_ADMIN],
  },
  {
    label: 'Data settings',
    path: 'data-processing-settings',
    component: <DataProcessingSettings />,
    access: [Roles.FINTARY_ADMIN],
  },
  {
    label: 'Agent settings',
    path: 'agent-settings',
    component: <AgentSettings />,
    access: [Roles.FINTARY_ADMIN],
  },
  {
    label: 'Compensation report settings',
    path: 'comp-report-settings',
    component: <CompReportSettings />,
    access: [Roles.FINTARY_ADMIN],
  },
  {
    label: 'API key dashboard',
    path: 'api-key-dashboard',
    component: <ApiKeyDashboard />,
  },
  {
    label: 'Data update tools',
    path: 'data-update-tools',
    component: <DataUpdateTools />,
    access: [Roles.FINTARY_ADMIN],
  },
];

const SettingsView = () => {
  // User Roles and Fintary Admin have different implementations at the moment
  // Currently, role === 'admin' for Fintary Admin,
  // while userRole
  const { userRole } = useRoleStore();
  const {
    role: [role],
  } = useContext(UIStateContext);
  const filteredTabs = tabs.filter((tab) =>
    hasAccess(tab.access, userRole, role === 'admin')
  );

  const { tab = 'general' } = useParams();
  const tabPaths = filteredTabs.map((tab) => tab.path);
  const routedTab = tabPaths.indexOf(tab) >= 0 ? tabPaths.indexOf(tab) : 0;
  const [value, setValue] = useState(routedTab);

  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    navigate(`/settings/${filteredTabs[newValue].path}`);
    setValue(newValue);
  };

  useEffect(() => {
    if (value !== tabPaths.indexOf(tab) && tabPaths.indexOf(tab) >= 0)
      setValue(tabPaths.indexOf(tab));
  }, [role, tab, tabPaths, routedTab]);

  return (
    <Box sx={{ width: 1, overflowY: 'scroll' }}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2, mx: 2 }}>
          <Tabs value={value} onChange={handleChange}>
            {filteredTabs.map((tab) => (
              <Tab
                label={`${tab.label}${
                  requiresFintaryAdmin(tab.access) ? ' 🔒' : ''
                }`}
                key={tab.label}
              />
            ))}
          </Tabs>
        </Box>
        {filteredTabs.map((tab) => (
          <TabPanel
            value={value}
            index={filteredTabs.indexOf(tab)}
            key={tab.label}
          >
            {tab.component}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default SettingsView;
