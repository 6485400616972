import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { numberOrDefault } from 'common/helpers';
import { ElementRef, FC, useEffect, useRef, useState } from 'react';
import { utils } from 'xlsx';

import { SpreadsheetProps } from './model';

interface ExcelPreviewProps {
  sheet?: string;
}

const ExcelPreview: FC<ExcelPreviewProps & SpreadsheetProps> = ({
  spreadsheet,
  setSpreadsheet,
  sheet = '',
}) => {
  const [maxRowLen, setMaxRowLen] = useState(0);

  const [dataList, setDataList] = useState<any[]>([]);

  const [rowDataDef, setRowDataDef] = useState<any[]>([]);

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const containerRef = useRef<ElementRef<any>>(null);

  const [sheets, setSheets] = useState<string[]>([]);
  const [curSheet, setCurSheet] = useState<string>('');

  useEffect(() => {
    if (spreadsheet) {
      const sheets = spreadsheet.getSheets();
      setSheets(sheets);

      const sheetName = sheets.length === 1 || !sheet ? sheets[0] : sheet;
      setCurSheet(sheetName);
    }
  }, [spreadsheet, setSpreadsheet, sheet]);

  useEffect(() => {
    if (!spreadsheet || !curSheet) return;
    const _sheetData = spreadsheet.getJson(curSheet) ?? [];
    const sheetData = Array.isArray(_sheetData) ? _sheetData : [];
    console.log('sheetData', sheetData, curSheet);

    const maxRowlen = sheetData.reduce(
      (max, row) => Math.max(max, row.length),
      0
    );

    const _data = sheetData.map((row) => {
      const _rowLen = row.length;
      if (_rowLen < maxRowlen) {
        return [...row, ...Array(maxRowlen - _rowLen).fill('')];
      }
      return row;
    });
    console.log('maxRowLen', maxRowlen);
    setMaxRowLen(maxRowlen);
    setDataList(_data);
  }, [curSheet, spreadsheet]);

  // convert the data to object of arrays
  useEffect(() => {
    if (dataList.length) {
      const mappedArr = dataList.map((item) => {
        return item.reduce((acc, currentVal, index) => {
          const _key = utils.encode_col(index);
          acc[_key] = currentVal;
          return acc;
        }, {});
      });
      setRowDataDef(mappedArr);
    }
  }, [dataList]);

  // set column defs by the max row length
  useEffect(() => {
    if (maxRowLen) {
      console.log('maxRowLen2', maxRowLen);
      const _columnDefs = Array.from(new Array(maxRowLen), (el, idx) => {
        const _key = utils.encode_col(idx);
        return {
          headerName: _key,
          field: _key,
        };
      });
      setColumnDefs(_columnDefs);
    }
  }, [maxRowLen]);

  return maxRowLen ? (
    <Box
      ref={containerRef}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        border: '1px solid #cecece',
        overflow: 'auto',
        flexDirection: 'column',
        gap: 1,
        maxWidth: '100%',
      }}
    >
      {sheets.length > 1 && (
        <Box sx={{ p: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Sheet</InputLabel>
            <Select
              id="select-sheet"
              value={curSheet}
              label="Sheet"
              onChange={(e) => {
                setCurSheet(e.target.value as string);
              }}
            >
              {sheets.map((s) => (
                <MenuItem value={s} key={s}>
                  {s}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
      <Box
        style={{ width: '100%', height: '100%' }}
        className="ag-theme-material"
      >
        <AgGridReact
          headerHeight={40}
          rowData={rowDataDef}
          columnDefs={columnDefs}
          alwaysShowHorizontalScroll={true}
          defaultColDef={{ flex: 1, minWidth: 160, resizable: true }}
        />
      </Box>
    </Box>
  ) : (
    <div></div>
  );
};

export default ExcelPreview;
