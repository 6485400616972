import { useMemo } from 'react';

import { Option, SelectProps } from './types';
import { BaseSelect } from './BaseSelect';
import { convertStringToOptionList } from './helpers';

export const StringSelect = <T, K extends boolean = false>({
  options,
  onChange,
  multiple,
  value,
  ...rest
}: SelectProps<T, K>) => {
  const _options: Option[] = useMemo(() => {
    return convertStringToOptionList(options as Option['value'][]);
  }, [options]);

  const _onChange = (item: Option | Option[]) => {
    let newValue: T | T[];
    if (Array.isArray(item)) {
      newValue = item.map((item) => item.value) as T[];
    } else {
      newValue = item.value as T;
    }

    onChange(newValue as K extends false ? T : T[]);
  };

  const _value: Option | Option[] | undefined = useMemo(() => {
    if (!value) return undefined;
    if (Array.isArray(value))
      return convertStringToOptionList(value as Option['value'][]);

    return { value: value as Option['value'], label: String(value) };
  }, [value]);

  return (
    <BaseSelect
      multiple={multiple}
      value={_value}
      options={_options}
      onChange={_onChange}
      {...rest}
    />
  );
};
