import {
  AccountCircle,
  Business,
  CampaignOutlined,
  ExpandMore,
  HelpOutline,
  LogoutOutlined,
} from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import {
  Avatar,
  Box,
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { feedbackIntegration } from '@sentry/react';
import { useEffect, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';

import { useAccountStore } from '@/store';
import { States } from '@/types';
import { mapAccountKeys } from '@/utils/account';
import useDownloadStorageFile from '@/contexts/useDownloadStorageFile';

export const UserMenu = ({
  onHelpClick,
  onSignOutClick,
  user,
}: Pick<BarProps, 'onHelpClick' | 'onSignOutClick' | 'user'>) => {
  const [accountAnchorEl, setAccountAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const [accountList, setAccountList] = useState<Account[]>([]);
  const {
    selectedAccount,
    setSelectedAccount,
    logoUrl,
    setLogoUrl,
    userState,
  } = useAccountStore();
  const { downloadFile } = useDownloadStorageFile();

  useEffect(() => {
    const accounts = userState?.userAccounts.map((acc) =>
      mapAccountKeys(acc.account)
    );
    if (accounts) setAccountList(accounts);
  }, [userState]);

  const overallState = userState?.userOverallState;
  useEffect(() => {
    if (user && overallState === States.ACTIVE) {
      const getSetUrl = async () => {
        try {
          if (!selectedAccount?.accountId) return;
          const file = await downloadFile({
            endpoint_str_id: selectedAccount?.accountId,
            file_preview_type: 'logo',
            endpoint: 'accounts',
          });
          if (!file) return;
          const fileToUrl = URL.createObjectURL(file);
          setLogoUrl(fileToUrl);
        } catch (e) {
          console.error('Error getting logo url', e);
        }
      };

      if (selectedAccount?.whiteLabelMode && selectedAccount?.accountId) {
        getSetUrl();
      }
    }
  }, [selectedAccount, overallState, user]);

  const openMenu: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    const accountAnchorEl = event.currentTarget;
    setAccountAnchorEl(accountAnchorEl);
  };

  const closeMenu = () => {
    setAccountAnchorEl(null);
  };

  const selectAccount = (account: Account) => {
    setSelectedAccount(account);
    closeMenu();
    localStorage.setItem('triggerReload', Date.now().toString());
    window.location.reload();
  };

  const [feedbackWidget, setFeedbackWidget] = useState<ReturnType<
    (typeof feedback)['createWidget']
  > | null>(null);

  const feedback = feedbackIntegration({
    autoInject: false,
    triggerLabel: 'Send feedback',
    colorScheme: 'light',
    formTitle: 'Send feedback',
    isEmailRequired: true,
    showBranding: false,
    showName: false,
    submitButtonLabel: 'Submit',
    themeLight: {
      submitBorder: '#2196f3',
      submitBackground: '#2196f3',
      submitBackgroundHover: '#2196f3',
      submitOutlineFocus: '#2196f3',
      inputOutlineFocus: '#2196f3',
    },
  });

  const menuItems = [
    {
      icon: <HelpOutline />,
      name: 'Help',
      onClick: onHelpClick,
    },
    {
      icon: <CampaignOutlined />,
      name: feedbackWidget ? 'Close feedback' : 'Open feedback',
      onClick: () => {
        if (feedbackWidget) {
          feedbackWidget.removeFromDom();
          setFeedbackWidget(null);
        } else {
          setFeedbackWidget(feedback.createWidget());
        }
      },
    },
    {
      icon: <LogoutOutlined />,
      name: 'Sign out',
      onClick: onSignOutClick,
    },
  ];

  return (
    <>
      <Button
        data-testid="log_out_button"
        endIcon={<ExpandMore />}
        onClick={openMenu}
        variant="outlined"
        sx={{ color: 'white' }}
      >
        {/* desktop */}
        <Typography
          sx={{
            display: {
              xs: 'none',
              md: 'block',
            },
          }}
          variant="h6"
        >
          {user?.email}
        </Typography>

        {/* mobile icon */}
        <PersonIcon
          sx={{
            display: {
              xs: 'inline-block',
              md: 'none',
            },
          }}
        />
      </Button>

      <Menu
        anchorEl={accountAnchorEl}
        open={Boolean(accountAnchorEl)}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{ py: 0 }}
      >
        <MenuItem disabled style={{ opacity: 1 }}>
          <ListItemIcon>
            <AccountCircle />
          </ListItemIcon>
          <ListItemText>{user?.email}</ListItemText>
        </MenuItem>
        {!!accountList?.length && <Divider variant="middle" key="divider" />}
        {!!accountList?.length && (
          <Box key="accountList">
            <Box display="flex" justifyContent="center">
              <Typography variant="caption">
                {accountList.length > 1 ? 'Accounts' : 'Account'}
              </Typography>
            </Box>
            {accountList.map((account) => (
              <MenuItem
                key={account.accountId}
                disabled={accountList.length === 1}
                onClick={() => selectAccount(account)}
              >
                <ListItemIcon>
                  {account.logoUrl && logoUrl ? (
                    <Avatar
                      alt="Account logo"
                      src={logoUrl}
                      sx={{ width: 24, height: 24 }}
                    />
                  ) : (
                    <Business />
                  )}
                </ListItemIcon>
                <ListItemText>
                  {account.accountName ?? '(unspecified)'}
                </ListItemText>
                {selectedAccount?.accountId === account.accountId && (
                  <CheckIcon />
                )}
              </MenuItem>
            ))}
          </Box>
        )}
        <Divider variant="middle" sx={{ mb: 1 }} />

        {menuItems.map((menuItem) => {
          return (
            <MenuItem
              key={menuItem.name}
              onClick={() => {
                closeMenu();
                menuItem.onClick?.();
              }}
            >
              {menuItem.icon && <ListItemIcon>{menuItem.icon}</ListItemIcon>}
              <ListItemText>{menuItem.name}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
