import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Chip } from '@mui/material';
import CommonFormatter from 'common/Formatter';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import Formatter from '@/services/Formatter';
import { FieldTypes } from '@/types';

const CommissionCalcLog = ({ commissionProfile }) => {
  const [expanded, setExpanded] = useState(false);

  const logFields = useMemo(
    () => [
      { field: 'alerts', label: 'Alerts', skipEmpty: true, newLine: true },
      { field: 'profile_name', label: 'Profile', skipEmpty: true },
      {
        field: 'commissionAmount',
        label: 'Amount',
        formatter: Formatter.currency,
        skipEmpty: true,
      },
      {
        field: 'payerCommissionRate',
        label: 'Payer commission rate',
        formatter: CommonFormatter.percentage,
        skipEmpty: true,
      },
      {
        field: 'commissionRate',
        label: 'Effective rate',
        formatter: CommonFormatter.percentage,
        skipEmpty: true,
      },
      {
        field: 'payoutRate',
        label: 'Payout rate',
        formatter: (val) => CommonFormatter.percentage(val / 100),
        skipEmpty: true,
      },
      {
        field: 'calcMethod',
        label: 'Method',
        formatter: CommonFormatter.calcMethod,
        skipEmpty: true,
      },
      {
        field: 'calcBasis',
        label: 'Basis',
        formatter: Formatter.currency,
        collapsed: true,
        skipEmpty: true,
      },
      {
        field: 'criterionStr',
        label: 'Criteria',
        collapsed: true,
        newLine: true,
        skipEmpty: true,
      },
      {
        field: 'payerRates',
        label: 'Payer grid rates',
        collapsed: true,
        newLine: true,
        formatter: (val) => {
          return val.carrier_rate || val.house_rate || val.rate
            ? `Carrier: ${CommonFormatter.percentage(val.carrier_rate / 100)} • House: ${CommonFormatter.percentage(val.house_rate / 100)} • Total: ${CommonFormatter.percentage(val.rate / 100)}`
            : null;
        },
        skipEmpty: true,
      },
      {
        field: 'payeeRates',
        label: 'Payee grid rates',
        collapsed: true,
        newLine: true,
        formatter: (val) => {
          return val.carrier_rate || val.house_rate || val.rate
            ? `Carrier: ${CommonFormatter.percentage(val.carrier_rate / 100)} • House: ${CommonFormatter.percentage(val.house_rate / 100)} • Total: ${CommonFormatter.percentage(val.rate / 100)}`
            : null;
        },
        skipEmpty: true,
      },
      {
        field: 'agentSplit',
        label: 'Agent split',
        formatter: CommonFormatter.percentage,
        skipEmpty: true,
        collapsed: true,
        newLine: true,
      },
      {
        field: 'hierarchySplit',
        label: 'Hierarchy split',
        formatter: CommonFormatter.percentage,
        skipEmpty: true,
        collapsed: true,
      },
      {
        field: 'multiplier',
        label: 'Multiplier',
        formatter: CommonFormatter.percentage,
        skipEmpty: true,
        collapsed: true,
        newLine: true,
      },
      {
        field: 'agentUplines',
        label: 'Agent upline(s)',
        formatter: (val) =>
          Array.isArray(val) && val.length > 0 ? val.join(' / ') : 'n/a',
        skipEmpty: true,
        collapsed: true,
        newLine: true,
      },
      {
        field: 'product_type',
        label: 'Product type',
        collapsed: true,
        skipEmpty: true,
      },
      {
        field: 'referral_rates',
        label: 'Referral rates',
        collapsed: true,
        skipEmpty: true,
      },
      {
        field: 'formula',
        label: 'Formula',
        type: FieldTypes.CUSTOM,
        collapsed: true,
        render: (row) => {
          return row?.calResult?.formula;
        },
        newLine: true,
      },
      {
        field: 'formulaInterpreted',
        label: 'Formula interpreted',
        type: FieldTypes.CUSTOM,
        collapsed: true,
        render: (row) => {
          return row?.calResult?.formulaInterpreted;
        },
        newLine: true,
      },
      {
        field: 'notes',
        label: 'Notes',
        collapsed: true,
        newLine: true,
        skipEmpty: true,
      },
      {
        field: 'calculatedAt',
        label: 'Calculated at',
        collapsed: true,
        newLine: true,
        skipEmpty: true,
        formatter: (val) => Formatter.dateTime(val),
      },
    ],
    []
  );

  const logs = useMemo(() => {
    const logRows: any[] = [];
    const currentRow: string[] = [];
    logFields
      .filter((log) => expanded || !log.collapsed)
      .forEach((log) => {
        if (log.newLine && currentRow.length) {
          logRows.push(<Box key={log.field}>{currentRow.join(', ')}</Box>);
          currentRow.length = 0;
        }
        const value = commissionProfile[log.field];
        if (log.skipEmpty && (value === null || value === undefined)) return;
        if (log.formatter) {
          currentRow.push(`${log.label}: ${log.formatter(value)}`);
        } else if (log.render) {
          const comp = log.render(commissionProfile);
          if (comp) {
            currentRow.push(`${log.label}: ${log.render(commissionProfile)}`);
          }
        } else {
          currentRow.push(`${log.label}: ${value}`);
        }
      });
    logRows.push(
      <Box key={logFields[logFields.length - 1].field}>
        {currentRow.join(', ')}
      </Box>
    );
    return <Box>{logRows}</Box>;
  }, [logFields, expanded, commissionProfile]);

  const isReferral = commissionProfile.calcMethod === 'referral';

  return (
    <Chip
      sx={
        expanded
          ? {
              m: 0.1,
              py: 0.5,
              height: 'auto',
              '& .MuiChip-label': {
                display: 'block',
                whiteSpace: 'normal',
              },
              maxWidth: 800,
              minWidth: 400,
            }
          : { m: 0.1 }
      }
      clickable
      component={Link}
      to={
        isReferral
          ? `/agents/list?id=${commissionProfile.contactStrId}`
          : `/commissions/agent-schedule-profiles?id=${commissionProfile?.profile_str_id}`
      }
      target="_blank"
      label={
        expanded ? (
          <Box>
            <Box sx={{ my: 0.2 }}>{logs}</Box>
          </Box>
        ) : (
          logs
        )
      }
      onDelete={(e) => {
        e.preventDefault();
        setExpanded(!expanded);
      }}
      deleteIcon={expanded ? <ExpandLess /> : <ExpandMore />}
    />
  );
};

export default CommissionCalcLog;
