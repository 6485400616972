import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  AgentCommissionsStatusesLabels,
  CompReportDateFilter,
  CompReportPrefixConfig,
} from 'common/globalTypes';

import MultiSelect from '@/components/molecules/MultiSelect';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import Statements from '@/services/Statements';
import { useAccountStore } from '@/store';
import BasicDateRangePicker from '@/common/BasicDateRangePicker';
import useSnackbar from '@/contexts/useSnackbar';

dayjs.extend(utc);

type Agent = {
  id: number;
  name: string;
};

type AgentGroup = {
  id: number;
  name: string;
  contacts: Agent[];
};

type CommissionPayoutReportConfigProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  refreshData?: () => void;
};

enum ReportDefaults {
  Prefix = 'Commissions: start_date - end_date',
}

const CommissionPayoutReportConfig = ({
  open,
  setOpen,
  refreshData = () => {},
}: CommissionPayoutReportConfigProps) => {
  const [startDate, setStartDate] = useState<dayjs.Dayjs | string | null>(
    dayjs.utc().startOf('day')
  );
  const [endDate, setEndDate] = useState<dayjs.Dayjs | string | null>(
    dayjs.utc().startOf('day')
  );
  const [agents, setAgents] = useState<Agent[]>([]);
  const [agentGroups, setAgentGroups] = useState<AgentGroup[]>([]);
  const [compensationTypes, setCompensationTypes] = useState<string[]>([]);
  const [contactStatuses, setContactStatuses] = useState<string[]>([]);
  const [contactPayableStatuses, setContactPayableStatuses] = useState<
    string[]
  >([]);
  const [agentCommissionStatuses, setAgentCommissionStatuses] = useState<
    string[]
  >([]);
  const [agentCommissionStatuses2, setAgentCommissionStatuses2] = useState<
    string[]
  >(Object.values(AgentCommissionsStatusesLabels));
  const [loading, setLoading] = useState(false);
  const [isAgentGroupSelectionEnabled, setIsAgentGroupSelectionEnabled] =
    useState(false);
  const [selectedDateFilter, setSelectedDateFilter] = useState<string>(
    CompReportDateFilter.ProcessingDate
  );
  const [selectedReportPrefix, setSelectedReportPrefix] = useState<string>(
    CompReportPrefixConfig.Default
  );
  const [reportPrefix, setReportPrefix] = useState<string>(
    ReportDefaults.Prefix
  );

  const { selectedAccount } = useAccountStore();

  const mode = selectedAccount?.accountMode;
  const statements = new Statements(mode);

  const poster = API.getMutation(
    'saved_reports/groups/gen_commissions_report',
    'POST'
  );
  const { data: _agentList, isFetched } = API.getBasicQuery('contacts');
  const { data: _agentGroups, isFetched: _isAgentGroupsFetched } =
    API.getBasicQuery('contacts/groups');
  const { data: _contactOptions, isFetched: isFetchedContactOptions } =
    API.getBasicQuery(`contacts/options`);
  const {
    data: _statementDataOptions,
    isFetched: isFetchedStatementDataOptions,
  } = API.getBasicQuery(`statement_data/options`);

  const { showSnackbar } = useSnackbar();

  const agentList = _agentList?.data?.sort((a, b) =>
    Formatter.contact(a, { account_id: selectedAccount?.accountId }) >
    Formatter.contact(b, { account_id: selectedAccount?.accountId })
      ? 1
      : -1
  );

  const agentGroupList = _agentGroups?.sort((a, b) =>
    a.name > b.name ? 1 : -1
  );

  const contactStatusesSet = new Set<string | null>(_contactOptions?.status);
  contactStatusesSet.add('(Blank)');

  const contactStatusesList = Array.from(contactStatusesSet).sort((a, b) => {
    if (a === '(Blank)') return -1;
    if (b === '(Blank)') return 1;
    return (a as string).localeCompare(b as string);
  });

  const contactPayableStatusesSet = new Set<string | null>(
    _contactOptions?.payable_status
  );
  contactPayableStatusesSet.add('(Blank)');

  const contactPayableStatusesList = Array.from(contactPayableStatusesSet).sort(
    (a, b) => {
      if (a === '(Blank)') return -1;
      if (b === '(Blank)') return 1;
      return (a as string).localeCompare(b as string);
    }
  );

  const statementCompensationTypesList = [
    '(Blank)',
    ...(_statementDataOptions?.compensation_type?.sort((a, b) =>
      (a as string).localeCompare(b as string)
    ) || []),
  ];

  const statementAgentCommissionStatusesList = [
    '(Blank)',
    ...(_statementDataOptions?.agent_commissions_status?.sort((a, b) =>
      (a as string).localeCompare(b as string)
    ) || []),
  ];

  const agentValuer = (val) => val.id;

  useEffect(() => {
    if (isFetched) {
      setAgents(agentList.map((agent) => agentValuer(agent)));
    }

    if (_isAgentGroupsFetched) {
      setAgentGroups(
        agentGroupList.map((agentGroup) => agentValuer(agentGroup))
      );
    }

    if (isFetchedContactOptions) {
      setContactStatuses(contactStatusesList as string[]);
      setContactPayableStatuses(contactPayableStatusesList as string[]);
    }

    if (isFetchedStatementDataOptions) {
      setCompensationTypes(statementCompensationTypesList as string[]);
      setAgentCommissionStatuses(
        statementAgentCommissionStatusesList.filter(
          (status) => status.toLowerCase() !== 'paid'
        )
      );
    }
  }, [
    agentList,
    isFetched,
    agentGroupList,
    _isAgentGroupsFetched,
    isFetchedContactOptions,
    isFetchedStatementDataOptions,
  ]);

  useEffect(() => {
    if (_isAgentGroupsFetched && isAgentGroupSelectionEnabled) {
      const agentGroupContacts = agentGroups.flatMap((agentGroupId) => {
        const agentGroup = agentGroupList.find(
          (group) => group.id === agentGroupId
        );
        return agentGroup && Array.isArray(agentGroup.contacts)
          ? agentGroup.contacts.map((contact) => contact.id)
          : [];
      });
      setAgents(agentGroupContacts);
    } else if (isFetched) {
      setAgents(agentList.map((agent) => agentValuer(agent)));
    }
  }, [
    agentGroupList,
    _isAgentGroupsFetched,
    agentGroups,
    isAgentGroupSelectionEnabled,
  ]);

  const genReport = async () => {
    try {
      const response = await poster.mutateAsync({
        start_date: startDate,
        end_date: endDate,
        dateFilter: selectedDateFilter,
        reportPrefix:
          reportPrefix === ReportDefaults.Prefix ? '' : reportPrefix,
        contacts: agents,
        compensation_types: compensationTypes,
        contact_statuses: contactStatuses,
        contact_payable_statuses: contactPayableStatuses,
        agent_commission_statuses: agentCommissionStatuses,
        agent_commission_statuses2: agentCommissionStatuses2,
        headers: JSON.stringify(Object.values(statements.fields)),
      });
      if (response.data?.reports?.length > 0) {
        showSnackbar(
          `${response.data?.reports?.length} reports generated`,
          'info'
        );
        refreshData();
      } else {
        showSnackbar('No reports generated', 'error');
      }
    } catch (error: any) {
      showSnackbar(error?.message || error, 'error');
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Generate agent commission payout report</DialogTitle>
      <DialogContent sx={{ width: 450 }}>
        <Box
          sx={{
            p: 1,
            mt: 1,
            borderStyle: 'solid',
            borderColor: 'silver',
            borderWidth: 1,
            borderRadius: 4,
            display: 'inline-block',
            width: '100%',
            backgroundColor: '#2196f30a',
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="relative"
            sx={{ ml: 0.5, mb: 0.5 }}
          >
            <Typography variant="body2">Report settings</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <ToggleButtonGroup
              sx={{ height: '20px' }}
              value={selectedReportPrefix}
              exclusive
              onChange={(event, reportPrefix) => {
                setSelectedReportPrefix(reportPrefix);
                if (reportPrefix === CompReportPrefixConfig.Default) {
                  setReportPrefix(ReportDefaults.Prefix);
                }
              }}
              color="primary"
            >
              <ToggleButton
                value={CompReportPrefixConfig.Default}
                sx={{
                  borderRadius: '12px',
                }}
              >
                Default prefix
              </ToggleButton>
              <ToggleButton
                value={CompReportPrefixConfig.Custom}
                sx={{
                  borderRadius: '12px',
                }}
              >
                Custom prefix
              </ToggleButton>
            </ToggleButtonGroup>
            <Box sx={{ mt: 2 }}>
              <TextField
                label="Report prefix"
                value={reportPrefix}
                onChange={(event) => setReportPrefix(event.target.value)}
                fullWidth
                disabled={
                  selectedReportPrefix === CompReportPrefixConfig.Default
                }
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            p: 1,
            mt: 1,
            borderStyle: 'solid',
            borderColor: 'silver',
            borderWidth: 1,
            borderRadius: 4,
            display: 'inline-block',
            width: '100%',
            backgroundColor: '#2196f30a',
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="relative"
            sx={{ ml: 0.5, mb: 0.5 }}
          >
            <Typography variant="body2">Date filters</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <ToggleButtonGroup
              sx={{ height: '20px' }}
              value={selectedDateFilter}
              exclusive
              onChange={(event, selectedDateFilter) => {
                setSelectedDateFilter(selectedDateFilter);
              }}
              color="primary"
            >
              <ToggleButton
                value={CompReportDateFilter.ProcessingDate}
                sx={{
                  borderRadius: '12px',
                }}
              >
                Processing date
              </ToggleButton>
              <ToggleButton
                value={CompReportDateFilter.PaymentDate}
                sx={{
                  borderRadius: '12px',
                }}
              >
                Payment date
              </ToggleButton>
            </ToggleButtonGroup>
            <Box sx={{ mt: 2 }}>
              <BasicDateRangePicker
                range={{
                  startDate: startDate,
                  startDateLabel:
                    selectedDateFilter === CompReportDateFilter.ProcessingDate
                      ? 'Processing date start'
                      : 'Payment date start',
                  endDate: endDate,
                  endDateLabel:
                    selectedDateFilter === CompReportDateFilter.ProcessingDate
                      ? 'Processing date end'
                      : 'Payment date end',
                }}
                onChange={(range) => {
                  setStartDate(range.startDate);
                  setEndDate(range.endDate);
                }}
                justify="left"
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            p: 1,
            mt: 1,
            borderStyle: 'solid',
            borderColor: 'silver',
            borderWidth: 1,
            borderRadius: 4,
            display: 'inline-block',
            width: '100%',
            backgroundColor: '#2196f30a',
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="relative"
            sx={{ ml: 0.5, mb: 0.5 }}
          >
            <Typography variant="body2">Agent filters</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <ToggleButtonGroup
              sx={{ height: '20px' }}
              value={isAgentGroupSelectionEnabled ? 'agentGroups' : 'agents'}
              exclusive
              onChange={(event, newSelectionType) => {
                setIsAgentGroupSelectionEnabled(
                  newSelectionType === 'agentGroups'
                );
              }}
              color="primary"
            >
              <ToggleButton
                value="agents"
                sx={{
                  borderRadius: '12px',
                }}
              >
                Agents
              </ToggleButton>
              <ToggleButton
                value="agentGroups"
                sx={{
                  borderRadius: '12px',
                }}
              >
                Agent groups
              </ToggleButton>
            </ToggleButtonGroup>
            <Box sx={{ mt: 1.5 }}>
              {isAgentGroupSelectionEnabled ? (
                <MultiSelect
                  label="Agent group(s)"
                  values={agentGroupList}
                  formatter={(contactGroup) => contactGroup.name}
                  valuer={agentValuer}
                  selectedValues={agentGroups}
                  setSelectedValues={setAgentGroups}
                  sx={{ width: '100%' }}
                  enableSearch
                />
              ) : (
                <MultiSelect
                  label="Agent(s)"
                  values={agentList}
                  formatter={(o) =>
                    Formatter.contact(o, {
                      account_id: selectedAccount?.accountId,
                    })
                  }
                  valuer={agentValuer}
                  selectedValues={agents}
                  setSelectedValues={setAgents}
                  sx={{ width: '100%' }}
                  enableSearch
                  paginate
                />
              )}
            </Box>
            <Box sx={{ mt: 1.5 }}>
              <MultiSelect
                label="Agent status"
                values={contactStatusesList}
                selectedValues={contactStatuses}
                setSelectedValues={setContactStatuses}
                sx={{ width: '100%' }}
              />
            </Box>
            <Box sx={{ mt: 1.5 }}>
              <MultiSelect
                label="Agent payable status"
                values={contactPayableStatusesList}
                selectedValues={contactPayableStatuses}
                setSelectedValues={setContactPayableStatuses}
                sx={{ width: '100%' }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            mt: 1,
            p: 1,
            borderStyle: 'solid',
            borderColor: 'silver',
            borderWidth: 1,
            borderRadius: 4,
            display: 'inline-block',
            width: '100%',
            backgroundColor: '#2196f30a',
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="relative"
            sx={{ ml: 0.5, mb: 0.5 }}
          >
            <Typography variant="body2">Commission filters</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ mt: 1.5 }}>
              <MultiSelect
                label="Compensation type"
                values={statementCompensationTypesList}
                selectedValues={compensationTypes}
                setSelectedValues={setCompensationTypes}
                sx={{ width: '100%' }}
              />
            </Box>
            <Box sx={{ mt: 1.5 }}>
              <MultiSelect
                label="Payout status"
                values={statementAgentCommissionStatusesList}
                selectedValues={agentCommissionStatuses}
                setSelectedValues={setAgentCommissionStatuses}
                sx={{ width: '100%' }}
              />
            </Box>
            <Box sx={{ mt: 1.5 }}>
              <MultiSelect
                label="Per agent payout status"
                values={Object.values(AgentCommissionsStatusesLabels)}
                selectedValues={agentCommissionStatuses2}
                setSelectedValues={setAgentCommissionStatuses2}
                sx={{ width: '100%' }}
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ pt: 0 }}>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={async () => {
            setLoading(true);
            await genReport();
            setLoading(false);
            setOpen(false);
          }}
        >
          Generate
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CommissionPayoutReportConfig;
