import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
  TextField,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import { AccessTypes } from 'common/globalTypes';
import { UseMutationResult } from '@tanstack/react-query';

import {
  DataUpdateActionsObject,
  DataUpdateCopyTypes,
} from '@/components/ToolsPage/DataUpdateTools/types';
import useSnackbar from '@/contexts/useSnackbar';

type MutationObject = UseMutationResult<any, unknown, any, unknown>;

type PosterType = MutationObject & {
  abort: () => void;
};
interface DataUpdateCopyModalProps {
  open: boolean;
  handleClose: () => void;
  type: DataUpdateCopyTypes;
  selectedData: DataUpdateActionsObject[];
  poster: PosterType;
  refetch: () => void;
}

const DataUpdateCopyModal: React.FC<DataUpdateCopyModalProps> = ({
  open,
  handleClose,
  type,
  selectedData,
  poster,
  refetch,
}) => {
  const [newNames, setNewNames] = useState<{ [key: string]: string }>({});

  const { showSnackbar } = useSnackbar();

  const handleNameChange = (str_id: string, newName: string) => {
    setNewNames((prevNames) => ({
      ...prevNames,
      [str_id]: newName,
    }));
  };

  const handleConfirm = async () => {
    const copiedData = cloneDeep(selectedData);
    const updatedData = copiedData.map((data) => ({
      ...data,
      name: newNames[data.str_id] || data.name,
      access: AccessTypes.ACCOUNT,
    }));
    const data = await poster.mutateAsync(updatedData);

    if (data?.length > 0) {
      showSnackbar(`Copied!`, 'success');
      refetch();
    } else {
      showSnackbar(`Error copying data`, 'error');
    }

    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Copy data update {type}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter a new name for copying the selected data update {type}.
        </DialogContentText>
        {selectedData.map((data) => (
          <Box key={data.id} sx={{ mt: 1 }}>
            <TextField
              label="Current name"
              value={data.name}
              disabled
              variant="outlined"
              fullWidth
              sx={{ mb: 1 }}
            />
            <TextField
              label="New name"
              value={newNames[data.str_id] || ''}
              onChange={(e) => handleNameChange(data.str_id, e.target.value)}
              variant="outlined"
              fullWidth
              sx={{ mb: 1 }}
            />
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DataUpdateCopyModal;
