const convertArrayToMap = (keys, valuesArray) => {
  return valuesArray.map((values) => {
    const obj = {};
    keys.forEach((key, index) => {
      obj[key] = values[index];
    });
    return obj;
  });
};

export const desc_convertArrayToMap = {
  name: 'libs.tools.convertArrayToMap(headers, records)',
  description: 'Converts an array into an objects.',
  arguments: {
    headers:
      '[Array<string>] Array of property names that will be used as object keys',
    records:
      '[Array<Array>] Two-dimensional array where each inner array will be converted to an object',
  },
  returns:
    '[Array<Object>] Array of objects where each property corresponds to a header',
  examples: `convertArrayToMap(['a', 'b'], [[1, 2], [3, 4]])
// → [{a: 1, b: 2}, {a: 3, b: 4}]
`,
};

export default convertArrayToMap;
