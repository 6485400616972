import { Card, CardContent, Grid, Typography } from '@mui/material';
import ReactECharts from 'echarts-for-react';

const ChartWidget = ({ data }) => {
  return (
    <ReactECharts
      option={data}
      style={{ height: '100%', minHeight: '250px' }}
    />
  );
};

export default ChartWidget;
