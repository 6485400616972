import { Box, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Clear from '@mui/icons-material/Clear';

export const SearchBox = ({
  onChange,
  search = '',
}: {
  search?: string;
  onChange: (v: string) => void;
}) => {
  return (
    <Box
      sx={{
        borderBottom: '1px solid',
        borderColor: 'grey.200',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <TextField
        value={search}
        placeholder="Search"
        onChange={(evt) => onChange(evt.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" sx={{ color: 'grey.400' }} />
            </InputAdornment>
          ),
          endAdornment: search ? (
            <InputAdornment
              onClick={() => onChange('')}
              sx={{ cursor: 'pointer' }}
              position="start"
            >
              <Clear sx={{ color: 'grey.400' }} fontSize="small" />
            </InputAdornment>
          ) : null,
        }}
        sx={{
          width: '100%',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
        size="small"
      />
    </Box>
  );
};
