import { Box, Input, InputAdornment, InputLabel } from '@mui/material';
import { useMemo } from 'react';

import DataView from '@/components/DataView';
import KeyConfig from '@/components/reconciliation/ReconcilersView/KeyConfig';
import SimilarityConfig from '@/components/reconciliation/ReconcilersView/SimilarityConfig';
import Reports from '@/services/Reports';
import Statements from '@/services/Statements';
import { useAccountStore } from '@/store';
import { FieldTypes } from '@/types';
import OrderConfig from '@/components/reconciliation/ReconcilersView/OrderConfig';

// const beautify = require('js-beautify').js;

const ReconcilersView = () => {
  const { selectedAccount } = useAccountStore();
  const mode = selectedAccount?.accountMode;

  const statements = useMemo(() => new Statements(mode), [mode]);
  const reports = useMemo(() => new Reports(mode), [mode]);

  const statementFields = Object.fromEntries(
    Object.entries(statements.fields).filter(([k, v]) => v.enabled)
  );
  const reportFields = Object.fromEntries(
    Object.entries(reports.fields).filter(([k, v]) => v.enabled)
  );

  const dataDesc = {
    label: 'Reconcilers',
    table: 'reconcilers',
    editable: true,
    copyable: true,
    fields: [
      {
        id: 'name',
        label: 'Name',
        required: true,
      },
      {
        id: 'filters',
        type: 'heading',
        label: 'Filters',
      },
      {
        id: 'report_filter',
        type: 'sub-heading',
        label: 'Policies',
      },
      {
        id: 'filter_report_params',
        label: 'Policies filter params',
        type: 'field-matcher',
        fields: Object.values(reportFields),
        formatter: (matchCriteria) => {
          return (
            matchCriteria &&
            Object.keys(matchCriteria).length > 0 &&
            `[${matchCriteria?.field}] ${matchCriteria?.op} "${matchCriteria?.value}"`
          );
        },
      },
      {
        id: 'statement_filter',
        type: 'sub-heading',
        label: 'Commissions',
      },
      {
        id: 'filter_statement_params',
        label: 'Commissions filter params',
        type: 'field-matcher',
        fields: Object.values(statementFields),
        formatter: (matchCriteria) => {
          return (
            matchCriteria &&
            `[${matchCriteria?.field}] ${matchCriteria?.op} "${matchCriteria?.value}"`
          );
        },
      },
      { id: 'matchMethod', type: 'heading', label: 'Matcher configuration' },
      {
        id: 'method_type',
        label: 'Method type',
        type: 'select',
        required: true,
        options: [
          { id: 'key-config', label: 'Key Config' },
          { id: 'key-custom', label: 'Key Custom' },
          { id: 'similarity-config', label: 'Similarity Config' },
          { id: 'similarity-custom', label: 'Similarity Custom' },
        ],
        tip: (
          <ul style={{ paddingLeft: 12 }}>
            <li>
              Key: Configure a key to be generated for each policy report and
              commission statement record. Records with the same key will be
              matched.
            </li>
            <li>
              Key Custom: Define custom code to generate a key for policy report
              data and commission statement data.
            </li>
            <li>
              Similarity: Configure fields, transformers, and similarity
              functions for policy report and commission statement data. Similar
              records above threshold will be matched.
            </li>
            <li>
              Similarity Custom: Custom code that takes a policy report record
              and a commission statement record and calcuate score. Result must
              be a number between 0-1. Scores above specified threshold will be
              matched.
            </li>
          </ul>
        ),
      },
      {
        id: 'key_config_header_report',
        type: 'sub-heading',
        label: 'Policy data key',
        condition: (row) => row.method_type === 'key-config',
      },
      {
        id: 'key_config_report',
        label: 'Report key config',
        condition: (row) => row.method_type === 'key-config',
        type: 'custom',
        render: (field, row, setter) => (
          <KeyConfig
            key={field.id}
            fieldId={field.id}
            data={row}
            setter={setter}
            fields={Object.values(reportFields).sort((a, b) =>
              a.label.localeCompare(b.label)
            )}
          />
        ),
        visible: ['form', 'view'],
      },
      {
        id: 'key_config_header_statement',
        type: 'sub-heading',
        label: 'Commission statement key',
        condition: (row) => row.method_type === 'key-config',
      },
      {
        id: 'key_config_statement',
        label: 'Statement key config',
        condition: (row) => row.method_type === 'key-config',
        type: 'custom',
        render: (field, row, setter) => (
          <KeyConfig
            key={field.id}
            fieldId={field.id}
            data={row}
            setter={setter}
            fields={Object.values(statementFields).sort((a, b) =>
              a.label.localeCompare(b.label)
            )}
          />
        ),
        visible: ['form', 'view'],
      },
      {
        id: 'key_condition',
        label: 'Key condition',
        condition: (row) => row.method_type === 'key-config',
        type: 'select',
        multiple: true,
        options: [
          { id: '', label: '' },
          {
            id: 'effective_date_range',
            label: 'Effective date range + policy term',
          },
        ],
      },
      {
        id: 'effectiveDateBuffer',
        label: 'Effective Date Buffer',
        type: FieldTypes.CUSTOM,
        condition: (row) => row.key_condition?.includes('effective_date_range'),
        render: (field, row, setter) => {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                mb: 1,
                width: '100%',
                justifyContent: 'flex-start',
              }}
            >
              <Box
                sx={{
                  mr: 1,
                }}
              >
                <InputLabel>Start buffer month</InputLabel>
                <Input
                  type="number"
                  value={
                    row.key_condition_config?.effective_date_range
                      ?.startBufferMonth
                  }
                  endAdornment={
                    <InputAdornment position="end">Months</InputAdornment>
                  }
                  onChange={(e) =>
                    setter({
                      ...row,
                      key_condition_config: {
                        ...row.key_condition_config,
                        effective_date_range: {
                          ...row.key_condition_config?.effective_date_range,
                          startBufferMonth: e.target.value,
                        },
                      },
                    })
                  }
                ></Input>
              </Box>
              <Box>
                <InputLabel>End buffer month</InputLabel>
                <Input
                  type="number"
                  value={
                    row.key_condition_config?.effective_date_range?.bufferMonth
                  }
                  endAdornment={
                    <InputAdornment position="end">Months</InputAdornment>
                  }
                  onChange={(e) =>
                    setter({
                      ...row,
                      key_condition_config: {
                        ...row.key_condition_config,
                        effective_date_range: {
                          ...row.key_condition_config?.effective_date_range,
                          bufferMonth: e.target.value,
                        },
                      },
                    })
                  }
                ></Input>
              </Box>
            </Box>
          );
        },
      },
      {
        id: 'key_custom_header',
        type: 'heading',
        label: 'Custom key code',
        condition: (row) => row.method_type === 'key-custom',
      },
      {
        id: 'key_config_statement',
        label: 'Statement Method',
        tip: 'Return a key used to match record with policy report data',
        default: `// (row) => (row.policy_id.toLowerCase().replaceAll('-', ''))`,
        // onBlurFormatter: (val) => beautify(val, { indent_size: 2 }),
        condition: (row) => row.method_type === 'key-custom',
        type: 'text-multiline',
        visible: ['form', 'view'],
      },
      {
        id: 'key_config_report',
        label: 'Report Method',
        tip: 'Return a key used to match record with commission statement data',
        default: `// (row) => (row.policy_id.toLowerCase().replaceAll('-', ''))`,
        // onBlurFormatter: (val) => beautify(val, { indent_size: 2 }),
        condition: (row) => row.method_type === 'key-custom',
        type: 'text-multiline',
        visible: ['form', 'view'],
      },
      {
        id: 'similarity_config_header',
        type: 'heading',
        label: 'Similarity configuration',
        condition: (row) => row.method_type === 'similarity-config',
      },
      {
        id: 'similarity_config',
        label: 'Similarity config',
        condition: (row) => row.method_type === 'similarity-config',
        type: 'custom',
        render: (field, row, setter) => (
          <SimilarityConfig
            key={field.id}
            fieldId={field.id}
            data={row}
            setter={setter}
            reportFields={Object.values(reportFields)}
            statementFields={Object.values(statementFields)}
          />
        ),
        visible: ['form', 'view'],
      },
      {
        id: 'similarity_custom_header',
        type: 'heading',
        label: 'Custom similarity code',
        condition: (row) => row.method_type === 'similarity-custom',
      },
      {
        id: 'similarity_config',
        label: 'Similarity custom code',
        default: `// Score - Return value is a score between 0 and 1
        (report, statement) => {
          let score = 0;
          if (report.policy_id === statement.policy_id) score += 0.1;
          if (report.policy_id.toLowerCase() === statement.policy_id.toLowerCase()) score += 0.9;
          if (report.customer_name === statement.customer_name) score += 0.3
          if (report.product_type === statement.product_type) score += 0.2;
        }`,
        // onBlurFormatter: (val) => beautify(val, { indent_size: 2 }),
        condition: (row) => row.method_type === 'similarity-custom',
        type: 'code',
        visible: ['form', 'view'],
      },
      {
        id: 'method_threshold_match',
        label: 'Match threshold',
        condition: (row) => row.method_type?.startsWith('similarity'),
        visible: ['form', 'view'],
      },
      {
        id: 'method_threshold_maybe',
        label: 'Maybe threshold',
        condition: (row) => row.method_type?.startsWith('similarity'),
        visible: ['form', 'view'],
      },
      {
        id: 'config',
        label: 'Options',
        type: 'select',
        multiple: true,
        options: [
          {
            id: 'matchCommissionsWithoutPolicy',
            label: 'Match statements without policy match',
          },
          {
            id: 'oneCommissionOneAgent',
            label: 'Only assign agent with closest split % to commission',
          },
        ],
        condition: (row) => row.method_type?.startsWith('key'),
      },
      {
        id: 'order_header',
        type: 'heading',
        label: 'Order',
      },
      {
        id: 'order',
        label: 'Order',
        type: 'custom',
        tableFormatter: () => 'Order',
        render: (field, row, setter) => (
          <OrderConfig
            key={field.id}
            fieldId={field.id}
            data={row}
            fields={Object.values(reportFields).sort((a, b) =>
              a.label.localeCompare(b.label)
            )}
            setter={setter}
          />
        ),
        visible: ['form', 'view'],
      },
      {
        id: 'notes',
        type: 'text-multiline',
        label: 'Notes',
      },
      { id: 'divider3', type: 'divider', label: 'divider' },
      {
        id: 'access',
        label: 'Access',
        type: 'select',
        default: 'account',
        options: ['account', 'global'],
        access: 'admin',
      },
      {
        id: 'updated_at',
        label: 'Last updated',
        formatter: (s) => new Date(s).toLocaleString(),
        condition: (row) => row.updated_at,
        readOnly: true,
      },
    ],
  };

  return <DataView dataDesc={dataDesc} hideExport />;
};

export default ReconcilersView;
