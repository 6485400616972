import { useEffect } from 'react';

import API from '@/services/API';

const useSyncedFields = (table: string) => {
  const { data, abort } = API.getBasicQuery(
    'data_processing/sync/synced-fields'
  );
  const { data: workerSyncedFields, abort: abortWorkerSyncedFields } =
    API.getBasicQuery('data_processing/sync/worker-synced-fields');

  useEffect(() => {
    return () => abort();
  }, []);

  const syncedFields = data?.[table];

  return {
    syncedFields,
    workerSyncedFields,
    isSyncedField: (
      data: { [key: string]: any; sync_id?: string },
      key: string,
      config?: { overrideFields?: string[] }
    ) => {
      const fields =
        workerSyncedFields?.[data.sync_worker]?.[table] ?? syncedFields;
      return (
        data.sync_id &&
        fields?.includes(key) &&
        (!config?.overrideFields || !config?.overrideFields?.includes(key))
      );
    },
  };
};
export const useSyncedFieldsNew = () => {
  const { data, abort } = API.getBasicQuery(
    'data_processing/sync/worker-synced-fields'
  );

  useEffect(() => {
    return () => abort();
  }, []);

  return {
    workerSyncedFields: data || {},
    isSyncedField: (
      data: { [key: string]: any; sync_id?: string },
      syncedFields: string[],
      key: string,
      config?: { overrideFields?: string[] }
    ) => {
      return (data.sync_id &&
        syncedFields?.includes(key) &&
        (!config?.overrideFields ||
          !config?.overrideFields?.includes(key))) as boolean;
    },
  };
};

export default useSyncedFields;
