import CloseIcon from '@mui/icons-material/Close';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';

import FileUploads from '@/components/UploadModal/FileUploads';
import {
  DocumentProfileModel,
  IDocumentModel,
} from '@/components/UploadModal/processFlow/model';
import useSnackbar from '@/contexts/useSnackbar';
import useStorageSignedUrl from '@/contexts/useStorageSignedUrl';
import API from '@/services/API';
import { arrayBufferToBase64, readFile } from '@/services/helpers';
import { useOriginalFile, useSetOriginFile } from '@/store/excelStore';
import useUploadStore from '@/store/uploadStore';
import PreviewUploads from './PreviewUploads';

const BaseModal = ({ open, handleClose }) => {
  const [current, setCurrent] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [formModel, setFormModel] = useState({});
  const queryClient = useQueryClient();

  const [btnText, setBtnText] = useState('');
  const [titleText, setTitleText] = useState('');
  const uploadFileRef = useRef<{ clearFile: () => void; submit: () => void }>();
  const previewFileRef = useRef<{
    submit: (params?: { tryAgain: boolean }) => any;
  }>();

  const setOriginFile = useSetOriginFile();
  const originalFile = useOriginalFile();
  const { getSignedUrl } = useStorageSignedUrl();
  const uploadProgresses = useUploadStore((s) => s.uploadProgresses);

  const { showSnackbar } = useSnackbar();

  const tryAgain = Object.values(uploadProgresses).some(
    (item) => item === 'failed'
  );

  const documentsExtractor = API.getMutation('documents/extractData', 'POST');
  const googleDocumentsAIExtractor = API.getMutation(
    'documents/googleDocumentAI',
    'POST'
  );
  const { data: processors } = API.getBasicQuery('processors', '', open);

  const handleCancel = useCallback(() => {
    setCurrent(0);
    uploadFileRef.current?.clearFile();
    setOriginFile(null);
    setFormModel({});
    handleClose(false);
  }, [setOriginFile]);

  const onCancel = () => {
    if (current === 0) {
      handleCancel();
      return;
    }
    setCurrent((prev) => prev - 1);
  };

  const goNext = useCallback(() => {
    if (current === 1) {
      handleCancel();
      return;
    }
    setCurrent((prev) => prev + 1);
  }, [current, handleCancel]);

  const autoExtractData = async ({
    document,
    profile,
  }: {
    document: IDocumentModel;
    profile: DocumentProfileModel;
  }) => {
    let targetProcessors: any[] = [];

    const isPdf = document.file_type === 'pdf';
    if (!isPdf) return;

    if (!targetProcessors.length) {
      targetProcessors = processors
        .filter((p) => profile.processor_str_ids?.includes(p.str_id))
        .map(({ method, inner_name, str_id }) => {
          return {
            method,
            inner_name,
            str_id,
          };
        });
    }

    const hasExtractTable = targetProcessors.some(
      (p) => p.method === 'extractTable'
    );
    const hasGoogleDocumentAI = targetProcessors.some(
      (p) => p.method === 'documentAI'
    );

    const signedUrl = await getSignedUrl({
      endpoint_str_id: document.str_id,
      endpoint: 'documents',
      file_preview_type: document.override_file_path ? 'override' : 'original',
      action: 'read',
    });

    if (hasExtractTable) {
      const extractDataParams = {
        url: signedUrl,
        filename: document.filename,
        document_id: document.id,
      };
      documentsExtractor.mutateAsync(extractDataParams);
    } else if (hasGoogleDocumentAI) {
      const _file = originalFile.find(
        (item: File) => item.name === document.filename
      );
      const params = {
        document_id: document.id,
        document_type: 'original',
      };
      googleDocumentsAIExtractor.mutateAsync(params);
    }

    showSnackbar(
      `${document.filename} was uploaded. We'll let you know when the file is processed.`,
      'success'
    );
  };

  const doStepOne = () => {
    setUploading(true);
    uploadFileRef.current?.submit();
    setUploading(false);
  };

  const doStepTwo = async () => {
    setUploading(true);
    previewFileRef.current?.submit({ tryAgain });
  };

  const onFinishUpload = useCallback(
    (err: boolean) => {
      setUploading(false);
      if (err) {
        showSnackbar(
          'Some files has failed to upload, please try again.',
          'error'
        );
        return;
      }
      goNext();
      // Auto refresh page
      if (window.location.pathname === '/documents') {
        queryClient.invalidateQueries();
      }
    },
    [goNext, queryClient]
  );

  const submit = () => {
    switch (current) {
      case 0:
        doStepOne();
        break;
      case 1:
        doStepTwo();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (current === 0) {
      setBtnText('Next');
      setTitleText('Upload file');
    } else if (current === 1) {
      setBtnText(tryAgain ? 'Try again' : 'Upload');
      setTitleText('Preview');
    }
  }, [current, tryAgain]);

  const steps = [
    {
      title: 'Select files',
      content: (
        <FileUploads
          ref={uploadFileRef}
          setCurrent={setCurrent}
          setFormModel={setFormModel}
          formModel={formModel}
        />
      ),
    },
    {
      title: 'Preview & Upload',
      content: (
        <PreviewUploads
          ref={previewFileRef}
          formModel={formModel}
          onFileUploadSuccess={autoExtractData}
          onFinishUpload={onFinishUpload}
        />
      ),
    },
  ];

  return (
    <Dialog
      open={open}
      fullScreen={current === 1}
      maxWidth={current === 1 ? undefined : 'lg'}
      sx={{
        background: 'transparent',
        padding: 2,
        minWidth: 800,
      }}
      onClose={handleCancel}
      PaperProps={{ sx: { borderRadius: 2 } }}
    >
      <DialogTitle>
        <Box className="flex items-center">
          <CloudSyncIcon />
          <Box className="pl-2">{titleText}</Box>
        </Box>
      </DialogTitle>
      <IconButton
        className="group absolute p-4 right-0 top-0 cursor-pointer hover:text-blue-600"
        onClick={handleCancel}
      >
        <CloseIcon className="group-hover:rotate-180 transition-all origin-center" />
      </IconButton>
      <Divider />

      <DialogContent
        sx={{
          padding: 2,
          backgroundColor: '#fff',
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Stepper activeStep={current} alternativeLabel>
          {steps.map((step) => (
            <Step key={step.title}>
              <StepLabel
                sx={{ '.MuiStepLabel-label': { mt: '6px !important' } }}
              >
                {step.title}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className="p-2 pb-1 rounded flex-1">{steps[current].content}</div>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel}>{current === 0 ? 'Cancel' : 'Back'}</Button>
        <LoadingButton onClick={submit} loading={uploading} variant="contained">
          {btnText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

BaseModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default BaseModal;
