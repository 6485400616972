const findTargetIn2DArray = (
  array: string[][],
  target: string,
  findAll = false
): {
  value: string[] | undefined;
  metadata: { row: number; col: number }[] | undefined;
} => {
  if (!target) {
    return {
      value: undefined,
      metadata: undefined,
    };
  }
  const results: {
    row: number;
    col: number;
  }[] = [];
  for (let i = 0; i < array.length; i++) {
    for (let j = 0; j < array[i].length; j++) {
      if (
        array[i][j]?.toLocaleLowerCase().includes(target?.toLocaleLowerCase())
      ) {
        if (findAll || results.length === 0) {
          results.push({ row: i, col: j });
        }
      }
    }
  }

  if (results.length === 0) {
    return {
      value: undefined,
      metadata: undefined,
    };
  }

  if (findAll) {
    return {
      value: results.map((result) => array[result.row][result.col]),
      metadata: results,
    };
  } else {
    return {
      value: [array[results[0].row][results[0].col]],
      metadata: [results[0]],
    };
  }
};

export const desc_findTargetIn2DArray = {
  name: 'libs.tools.findTargetIn2DArray(array, target, findAll)',
  description:
    'Searches for a target string in a 2D array and returns matching values with their positions.',
  arguments: {
    array: '[Array<Array<string>>] 2D array to search in',
    target: '[String] Text to search for (case-insensitive)',
    findAll:
      '[Boolean] Optional. If true, returns all matches instead of just the first one',
  },
  returns:
    '[Object] Object containing value (matched strings) and metadata (positions) arrays',
  examples:
    'const array = [\n  ["Name", "Policy Number", "Tax ID"],\n  ["", "", "Statement Date: 09/30/2024"]\n];\n\nfindTargetIn2DArray(array, "Statement Date:")\n// → {\n//   value: ["Statement Date: 09/30/2024"],\n//   metadata: [{ row: 1, col: 2 }]\n// }\n',
};

export default findTargetIn2DArray;
