import { useState, useEffect } from 'react';
import { Box } from '@mui/material';

import { useUserInfo } from '@/hooks/useUserInfo';

const AdminCard = (params: {
  children;
  isAdmin?: boolean;
  showIcon?: boolean;
  iconPosition?: 'left' | 'right';
}) => {
  const {
    children,
    isAdmin: externalIsAdmin,
    showIcon = false,
    iconPosition = 'left',
  } = params;
  const [isAdmin, setIsAdmin] = useState(false);
  const { data: { fintaryAdmin } = {} } = useUserInfo();

  useEffect(() => {
    if (externalIsAdmin !== undefined) {
      setIsAdmin(externalIsAdmin);
    } else {
      setIsAdmin(!!fintaryAdmin);
    }
  }, [externalIsAdmin, fintaryAdmin]);

  if (!isAdmin) {
    return null;
  }

  const icon = <span>🔒</span>;
  return (
    <Box>
      {showIcon && iconPosition === 'left' ? icon : null}
      {children}
      {showIcon && iconPosition === 'right' ? icon : null}
    </Box>
  );
};

export default AdminCard;
