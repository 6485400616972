import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
} from '@mui/material';
import { AccountIds } from 'common/constants';
import {
  NowCertsPolicyStatus,
  SyncParamsDTO,
} from 'common/dto/data_processing/sync';
import dayjs from 'dayjs';
import { useState } from 'react';

import AdminCard from '@/common/AdminCard';
import BasicDateRangePicker from '@/common/BasicDateRangePicker';
import MultiSelect from '@/components/molecules/MultiSelect';
import useSnackbar from '@/contexts/useSnackbar';
import API from '@/services/API';
import { useAccountStore } from '@/store';

export const DataSyncWorker = ({ workerInfo, onSyncComplete, onSyncStart }) => {
  const { showSnackbar } = useSnackbar();

  const isAgencyIntegrator = workerInfo?.worker === 'AgencyIntegratorWorker';
  const [showSyncDateRange, setShowSyncDateRange] = useState(false);
  const { selectedAccount } = useAccountStore();
  const [loading, setLoading] = useState(false);
  const policyStates: NowCertsPolicyStatus[] = [
    'Active',
    'Cancelled',
    'Expired',
    'Renewed',
    'Fintary',
    'Non-Renewal',
    'Pending Cancel',
    'Renewing',
    'Replaced',
  ];

  const isTrailStoneAccount =
    selectedAccount?.accountId === AccountIds.TRAILSTONE;
  const SYNCERS = workerInfo?.entities || [];
  const [syncArgs, setSyncArgs] = useState<SyncParamsDTO>({
    entities: SYNCERS,
    sync: false,
    startDate: null,
    endDate: null,
    worker: workerInfo?.worker,
    policyStates: isTrailStoneAccount ? policyStates : undefined,
  });

  const syncData = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_API}/api/data_processing/sync`,
      {
        method: 'POST',
        headers: await API.getHeaders(),
        body: JSON.stringify(syncArgs),
      }
    );
    return res;
  };

  const handleSync = async () => {
    try {
      setLoading(true);
      const res = await syncData();

      if (res.status === 200) {
        const data = await res.json();
        if (!data?.success) {
          showSnackbar(data.message, 'error');
        } else {
          onSyncStart?.(data.taskId);
          showSnackbar(
            'Data sync task has been created, please check data processing log to view task status',
            'success'
          );
        }
      } else {
        showSnackbar('Error syncing data', 'error');
      }
    } catch (error) {
      showSnackbar('Error syncing data', 'error');
    } finally {
      setLoading(false);
      onSyncComplete?.();
    }
  };

  return (
    <Box>
      <FormControl>
        <FormLabel>Data sync ({workerInfo?.worker})</FormLabel>
        <MultiSelect
          label="Entities"
          values={SYNCERS?.sort()}
          selectedValues={syncArgs.entities}
          setSelectedValues={(entities) =>
            setSyncArgs({
              ...syncArgs,
              entities,
            })
          }
          sx={{ minWidth: 200, maxWidth: 266, my: 1 }}
        />
        {isAgencyIntegrator && (
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  setSyncArgs({
                    ...syncArgs,
                    isFullSync: e.target.checked,
                  });
                }}
              />
            }
            label="Full run"
          />
        )}

        {isTrailStoneAccount && (
          <>
            <MultiSelect
              label="Filter policy"
              values={policyStates?.sort()}
              sx={{ minWidth: 200, maxWidth: 266, my: 1 }}
              selectedValues={syncArgs.policyStates || []}
              setSelectedValues={(states) => {
                setSyncArgs({
                  ...syncArgs,
                  policyStates: states.filter((s) => s),
                });
              }}
            />
            <FormControlLabel
              label="Sync date range"
              control={<Checkbox />}
              checked={showSyncDateRange}
              onClick={(e) => setShowSyncDateRange(!showSyncDateRange)}
            />
            {showSyncDateRange && (
              <Box>
                <BasicDateRangePicker
                  range={{
                    startDate: syncArgs?.startDate
                      ? dayjs.utc(syncArgs.startDate)
                      : null,
                    startDateLabel: 'Start date',
                    endDate: syncArgs?.endDate
                      ? dayjs.utc(syncArgs.endDate)
                      : null,
                    endDateLabel: 'End date',
                  }}
                  onChange={({ startDate, endDate }) => {
                    setSyncArgs({
                      ...syncArgs,
                      startDate: startDate
                        ? dayjs.isDayjs(startDate)
                          ? startDate.toDate()
                          : new Date(startDate)
                        : null,
                      endDate: endDate
                        ? dayjs.isDayjs(endDate)
                          ? endDate.toDate()
                          : new Date(endDate)
                        : null,
                    });
                  }}
                  my={0.5}
                />
              </Box>
            )}
          </>
        )}
        <AdminCard showIcon iconPosition="right">
          <FormControlLabel
            control={
              <Checkbox
                checked={!syncArgs.sync}
                onChange={(e) =>
                  setSyncArgs({
                    ...syncArgs,
                    sync: !e.target.checked,
                  })
                }
              />
            }
            label="Use workers"
          />
        </AdminCard>
        <LoadingButton
          loading={loading}
          onClick={handleSync}
          variant="contained"
        >
          Sync
        </LoadingButton>
      </FormControl>
    </Box>
  );
};
