import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import { dateOrDefault } from 'common/helpers';

import MultiSelect from '@/components/molecules/MultiSelect';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import BasicDateRangePicker from '@/common/BasicDateRangePicker';
import useSnackbar from '@/contexts/useSnackbar';
import DataUpdateGroupViewer from '@/components/ToolsPage/DataUpdateTools/DataUpdateGroupViewer';
import { Fields } from '@/components/ToolsPage/DataUpdateTools/types';
import { EnhancedSelect } from '@/components/molecules/EnhancedSelect';
import DataUpdatePreviewDialog from '@/components/ToolsPage/DataUpdateTools/DataUpdatePreviewModal';

interface RunDataUpdateProps {
  fields: Fields;
}

enum DataFilterOptions {
  ALL = 'all',
  RECONCILED = 'reconciled',
}

const RunDataUpdate: React.FC<RunDataUpdateProps> = ({ fields }) => {
  const { showSnackbar } = useSnackbar();

  const [showGlobal, setShowGlobal] = useState(false);
  const [globalSelected, setGlobalSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<dayjs.Dayjs | string | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | string | null>(null);
  const [selectedDocs, setSelectedDocs] = useState<string[]>([]);
  const [selectedDataUpdateGroup, setSelectedDataUpdateGroup] =
    useState<string>('');
  const [filteredDataUpdateInfo, setFilteredDataUpdateInfo] = useState<any[]>(
    []
  );
  const [fieldsList, setFieldsList] = useState<any[]>([]);
  const [fieldsToPreview, setFieldsToPreview] = useState<any[]>([]);
  const [previewData, setPreviewData] = useState<any[]>([]);
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedDataFilter, setSelectedDataFilter] = useState<string>(
    DataFilterOptions.RECONCILED
  );

  const handleClose = () => {
    setOpenPreview(false);
  };

  const { data: _documents } = API.getBasicQuery(
    'documents',
    'is_dynamic_select=true&limit=300'
  );
  const documents = _documents?.data ?? [];

  const { data: _dataUpdateConfig } = API.getBasicQuery(
    `data-update/config?global=${showGlobal}`
  );

  const dataUpdateInfo = useMemo(
    () => _dataUpdateConfig ?? [],
    [_dataUpdateConfig]
  );

  const dataUpdateGroups: string[] = Array.from(
    new Set(
      dataUpdateInfo.map((data: { group: string; access: string }) =>
        data.access === 'global' ? `${data.group} - global` : data.group
      )
    )
  );

  const runCompensationTypePoster = API.getMutation(
    'admin/data-update',
    'POST'
  );

  const handleGroupChange = (event) => {
    const group = event.target.value;
    setSelectedDataUpdateGroup(group);
    setGlobalSelected(group.includes(' - global'));
  };

  useEffect(() => {
    if (selectedDataUpdateGroup) {
      const filteredInfo = dataUpdateInfo.filter((data: { group: string }) => {
        const normalizedGroup = selectedDataUpdateGroup.replace(
          ' - global',
          ''
        );
        return data.group === normalizedGroup;
      });
      setFieldsList(fields[filteredInfo[0]?.data_entity]);
      setFilteredDataUpdateInfo(filteredInfo);
    } else {
      setFilteredDataUpdateInfo([]);
    }
  }, [selectedDataUpdateGroup, dataUpdateInfo, fields]);

  const handleDataUpdatePost = async (isPreview: boolean) => {
    setLoading(true);
    const params = {
      data_update_group: selectedDataUpdateGroup.replace(' - global', ''),
      global: globalSelected,
      processing_date_start: dateOrDefault(startDate, undefined),
      processing_date_end: dateOrDefault(endDate, undefined),
      document_str_ids: selectedDocs,
      only_reconcilied_data:
        selectedDataFilter === DataFilterOptions.RECONCILED,
      preview: isPreview,
      fieldsToPreview: fieldsToPreview.map((field) => field.id),
    };

    const response = await runCompensationTypePoster.mutateAsync(params);

    if (response.error) {
      showSnackbar(response.error, 'error');
    } else {
      if (isPreview) {
        setPreviewData(response.data);
        setOpenPreview(true);
        showSnackbar(
          `Preview generated for ${response.data.length} records.`,
          'success'
        );
      } else {
        showSnackbar(`${response.data.length} records updated.`, 'success');
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Box sx={{ mt: 1, mb: 1, ml: 1 }}>
        <Typography variant="body2" sx={{ mb: 1 }}>
          Select data update group to run
        </Typography>
        <FormControl sx={{ width: 250, mt: 1 }}>
          <InputLabel id="group-select-label">Group</InputLabel>
          <Select
            labelId="group-select-label"
            label="Group"
            value={selectedDataUpdateGroup}
            onChange={handleGroupChange}
          >
            {dataUpdateGroups.map((group) => (
              <MenuItem key={group} value={group}>
                {group}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="outlined"
          onClick={() => {
            setShowGlobal(!showGlobal);
          }}
          sx={{ ml: 1, mt: 1 }}
        >
          {showGlobal ? 'Show account groups' : 'Show global groups'}
        </Button>
        <DataUpdateGroupViewer
          dataUpdateData={filteredDataUpdateInfo}
          fields={fields}
        />
      </Box>
      <Box sx={{ mt: 1, mb: 1, ml: 1 }}>
        <Typography variant="body2" sx={{ mb: 1 }}>
          Commission filters
        </Typography>
        <BasicDateRangePicker
          range={{
            startDate: startDate,
            startDateLabel: 'Processing date start',
            endDate: endDate,
            endDateLabel: 'Processing date end',
          }}
          onChange={(range) => {
            setStartDate(range.startDate);
            setEndDate(range.endDate);
          }}
          justify="left"
          width={210}
        />
        <MultiSelect<any, any>
          label="Document filter"
          values={documents}
          valuer={(o) => o.str_id}
          formatter={(o) => {
            return o
              ? `${o?.filename} (${Formatter.date(o?.created_at, { format: 'YYYY/MM/DD hh:mmA' })})`
              : '';
          }}
          selectedValues={selectedDocs}
          setSelectedValues={(values) =>
            setSelectedDocs(values.filter((value) => value !== ''))
          }
          sx={{ width: 200, mt: 1.5 }}
          enableSearch
          paginate
        />
        <ToggleButtonGroup
          sx={{ mt: 1 }}
          value={selectedDataFilter}
          exclusive
          onChange={(event, reportPrefix) => {
            setSelectedDataFilter(reportPrefix);
          }}
          color="primary"
        >
          <ToggleButton value={DataFilterOptions.RECONCILED}>
            Reconcilied data
          </ToggleButton>
          <ToggleButton value={DataFilterOptions.ALL}>All data</ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box sx={{ mt: 2 }}>
        {selectedDataUpdateGroup && (
          <>
            <EnhancedSelect
              label="Fields to preview"
              options={fieldsList}
              value={fieldsToPreview}
              onChange={(v) => {
                setFieldsToPreview(v);
              }}
              multiple
              sx={{ ml: 1, minWidth: 200, width: 'fit-content' }}
            />
            <LoadingButton
              loading={loading}
              variant="contained"
              disabled={fieldsToPreview.length === 0}
              onClick={() => handleDataUpdatePost(true)}
              sx={{ ml: 1 }}
            >
              Preview data update
            </LoadingButton>
            <DataUpdatePreviewDialog
              handleClose={handleClose}
              open={openPreview}
              handleDataUpdatePost={handleDataUpdatePost}
              loading={loading}
              previewData={previewData}
              fieldsToPreview={fieldsToPreview}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default RunDataUpdate;
