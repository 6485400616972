import { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, FormHelperText, Typography, IconButton } from '@mui/material';
import {
  AttachFile as AttachFileIcon,
  AddCircle as AddCircleIcon,
} from '@mui/icons-material';
import PropTypes from 'prop-types';

// excel, pdf, png, jpg, jpeg, csv
const acceptMIME = {
  'application/vnd.ms-excel': ['.xls'],
  'application/pdf': ['.pdf'],
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'text/csv': ['.csv'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
  'text/plain': ['.txt'],
};

// png, jpg, jpeg
const acceptImgMIME = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg'],
};

const FactoryFileUpload = ({
  label,
  required,
  onChange,
  error,
  curFile,
  addFileMode = false,
  imageOnly = false,
  multiple = false,
  buttonStyle = false,
  showFileInfo = true,
  buttonComp = <></>,
}) => {
  const [files, setFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
    onChange(acceptedFiles);
  }, []);

  useEffect(() => {
    if (curFile) {
      setFiles(curFile);
    }
  }, [curFile]);

  let useDropzoneProps = {};
  if (multiple) {
    useDropzoneProps = {
      accept: imageOnly ? acceptImgMIME : acceptMIME,
      onDrop,
    };
  } else {
    useDropzoneProps = {
      accept: imageOnly ? acceptImgMIME : acceptMIME,
      onDrop,
      maxFiles: 1,
    };
  }

  const { getRootProps, getInputProps } = useDropzone(useDropzoneProps);

  const wrapperStyle = {
    height: addFileMode ? '50px' : '90px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
    justifyContent: 'center',
    flexDirection: 'column',
  };

  return (
    <>
      {!buttonStyle ? (
        <Box>
          {label && <Typography sx={{ my: 1 }}>{label}</Typography>}
          <div {...getRootProps()}>
            <input required={required} {...getInputProps()} />
            <div
              style={wrapperStyle}
              className="cursor-pointer border-dashed border border-gray-300 hover:border-blue-500 transition-colors"
            >
              {addFileMode ? (
                <IconButton
                  aria-label="upload file"
                  sx={{
                    color: 'gray',
                    '&:hover': {
                      color: 'darkgray',
                      transition: 'transform 0.3s ease, color 0.3s ease',
                    },
                  }}
                >
                  <AddCircleIcon fontSize="large" />
                </IconButton>
              ) : (
                <>
                  <p className="m-0 mx-2">
                    Drag and drop file here or click to browse files
                  </p>
                  {imageOnly ? (
                    <p className="m-0 text-gray-400">(.png, .jpg, .jpeg)</p>
                  ) : (
                    <p className="m-0 text-gray-400">
                      (.csv, .xls, .pdf, .png, .jpg)
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
          {showFileInfo && (
            <aside>
              <ul className="m-0 p-0 flex flex-col">
                {files &&
                  files.map((f) => (
                    <li key={f.path} className="list-none py-1 align-middle">
                      <AttachFileIcon className="text-base mr-1" />
                      {f.path} - {(f.size / 1024).toFixed(2)} KB
                    </li>
                  ))}
              </ul>
            </aside>
          )}
          <div>
            {required && error && (
              <FormHelperText error={error}>Required</FormHelperText>
            )}
          </div>
        </Box>
      ) : (
        <Box {...getRootProps()}>
          <input required={required} {...getInputProps()} />
          {buttonComp}
        </Box>
      )}
    </>
  );
};

FactoryFileUpload.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  curFile: PropTypes.object,
  imageOnly: PropTypes.bool,
  multiple: PropTypes.bool,
  buttonStyle: PropTypes.bool,
  buttonComp: PropTypes.element,
  showFileInfo: PropTypes.bool,
};

export default FactoryFileUpload;
