import getIndices from './getIndices';

const removeColsWithName = (array: string[][], colNames: string[]) => {
  const indicesToRemove = getIndices(array, colNames);
  const newArray = JSON.parse(JSON.stringify(array));
  indicesToRemove.forEach((idx) => {
    newArray.forEach((row) => row.splice(idx, 1));
  });
  return newArray;
};

export const desc_removeColsWithName = {
  name: 'libs.tools.removeColsWithName(array, colNames)',
  description:
    'Removes specified columns from a 2D array based on column names in the header row.',
  arguments: {
    array: '[Array<Array>] 2D array where first row contains headers',
    colNames: '[Array<string>] Array of column names to remove',
  },
  returns: '[Array<Array>] New 2D array with specified columns removed',
  examples:
    'removeColsWithName([\n  ["a", "b", "c"],\n  [1, 2, 3],\n  [4, 5, 6]\n], ["a", "c"])\n// → [["b"], [2], [5]]\n',
};

export default removeColsWithName;
