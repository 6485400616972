import React, { useCallback, useRef, useState } from 'react';
import { DataEntities } from 'common/globalTypes';
import { useQueryClient } from '@tanstack/react-query';

import {
  DataUpdateActionsObject,
  DataUpdateCopyTypes,
  Fields,
} from '@/components/ToolsPage/DataUpdateTools/types';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import { FieldTypes } from '@/types';
import CriteriaActionsViewer from '@/components/ToolsPage/DataUpdateTools/CriteriaActionsViewer';
import API from '@/services/API';
import DataUpdateCopyModal from '@/components/ToolsPage/DataUpdateTools/DataUpdateCopyModal';

interface DataUpdateCriteriaProps {
  fields: Fields;
}

const DataUpdateCriteria: React.FC<DataUpdateCriteriaProps> = ({ fields }) => {
  const [showGlobal, setShowGlobal] = useState(false);
  const [selectedData, setSelectedData] = useState<
    DataUpdateActionsObject[] | []
  >([]);
  const [openCopy, setOpenCopy] = useState(false);
  const [queryKey, setQueryKey] = useState(null);
  const queryClient = useQueryClient();

  const poster = API.getMutation(`data-update/criteria?copy=true`, 'POST');

  const handleClose = () => {
    setOpenCopy(false);
  };

  const handleRefetch = () => {
    if (queryKey) {
      queryClient.refetchQueries({
        queryKey: queryKey,
        type: 'active',
        exact: true,
      });
    }
  };

  const dataSpec = {
    label: 'Data update criteria',
    table: `data-update/criteria?global=${showGlobal}&`,
    fields: {
      data_entity: {
        label: 'Data entity',
        type: FieldTypes.SELECT,
        options: [DataEntities.COMMISSIONS, DataEntities.POLICIES],
        enabled: true,
      },
      name: {
        label: 'Name',
        enabled: true,
      },
      data_update_criteria: {
        label: 'Data update criteria',
        type: FieldTypes.FIELD_MATCHER,
        fieldGetter: (row) => {
          return fields[row.data_entity as DataEntities];
        },
        fields: Object.values(fields),
        tableFormatter: (field, row) => {
          return (
            <CriteriaActionsViewer
              fieldData={field}
              rowData={row}
              fields={fields}
            />
          );
        },
        enabled: true,
      },
      notes: {
        label: 'Notes',
        enabled: true,
      },
      access: {
        label: 'Access',
        readOnly: true,
        enabled: true,
      },
    },
  };

  const extraActions = [
    {
      label: 'Toggle access',
      onClick: () => {
        setShowGlobal(!showGlobal);
      },
      type: 'button',
    },
    {
      label: 'Copy selected',
      onClick: () => {
        setOpenCopy(true);
      },
      disabled: selectedData.length > 0 ? false : true,
      type: 'button',
    },
  ];

  return (
    <>
      <EnhancedDataView
        dataSpec={dataSpec}
        hideSelectedCount
        hideExport={true}
        setSelectedData={setSelectedData}
        // TODO: Fix this type in the EnhancedDataView component
        // @ts-ignore
        extraActions={extraActions}
        onQueryKeyChange={setQueryKey}
      />
      <DataUpdateCopyModal
        handleClose={handleClose}
        open={openCopy}
        type={DataUpdateCopyTypes.CRITERIA}
        selectedData={selectedData}
        poster={poster}
        refetch={handleRefetch}
      />
    </>
  );
};

export default DataUpdateCriteria;
