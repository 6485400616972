import { ContentCopy } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import copy from 'copy-to-clipboard';

const CopyButton = ({ value, sx = {} }) => (
  <IconButton
    onClick={(e) => {
      e.stopPropagation();
      copy(value);
    }}
    sx={{
      ...{
        opacity: 0,
        '&:hover': { opacity: 1 },
      },
      ...sx,
    }}
  >
    <ContentCopy />
  </IconButton>
);

export default CopyButton;
