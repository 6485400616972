import { useContext, useState } from 'react';
import { Box, Button, MenuItem, Select, Typography } from '@mui/material';
import { LoadingContext } from 'contexts/LoadingContext';

import useSnackbar from '@/contexts/useSnackbar';
import API from '@/services/API';
import { Roles } from '@/types';

const WidgetSelector = ({
  sharedWidgets,
  dashboardSettings,
  closeAddWidgetDialog,
  dashboardName,
}) => {
  const [dashboardWidgetsAdmin, setDashboardWidgetsAdmin] = useState(
    (dashboardSettings && dashboardSettings[Roles.ACCOUNT_ADMIN]) ?? []
  );
  const [dashboardWidgetsProducer, setDashboardWidgetsProducer] = useState(
    (dashboardSettings && dashboardSettings[Roles.PRODUCER]) ?? []
  );
  const [dashboardWidgetsDataSpecialist, setDashboardWidgetsDataSpecialist] =
    useState(
      (dashboardSettings && dashboardSettings[Roles.DATA_SPECIALIST]) ?? []
    );
  const { setLoadingConfig } = useContext(LoadingContext);
  const { showSnackbar } = useSnackbar();

  const saveWidgetSettingPoster = API.getMutation(
    'insights/accountWidgetsSettings',
    'PUT'
  );

  const saveWidgetSetting = async () => {
    setLoadingConfig({
      loading: true,
      message: 'Saving widget setting...',
    });
    const data = {
      adminWidgetsSettings: dashboardWidgetsAdmin,
      producerWidgetsSettings: dashboardWidgetsProducer,
      dataSpecialistWidgetsSettings: dashboardWidgetsDataSpecialist,
      dashboardName,
    };
    try {
      await saveWidgetSettingPoster.mutateAsync(data);
    } catch (error) {
      showSnackbar(`Error save widget setting :${error}`, 'error');
    }
    setLoadingConfig({
      loading: false,
    });
  };

  const handleFieldChange = (value, name) => {
    if (name === 'admin') {
      setDashboardWidgetsAdmin(value);
    } else if (name === 'producer') {
      setDashboardWidgetsProducer(value);
    } else {
      setDashboardWidgetsDataSpecialist(value);
    }
  };

  return (
    <Box sx={{ padding: 2, minWidth: '400px' }}>
      <Typography variant="h6">Choose widgets</Typography>
      <Box padding={2}>
        <Typography variant="caption">Admin</Typography>
        <Select
          value={dashboardWidgetsAdmin}
          style={{ width: '100%' }}
          label="Widgets"
          multiple
          onChange={(e) => handleFieldChange(e.target.value, 'admin')}
        >
          {sharedWidgets?.map((field) => (
            <MenuItem key={field.id} value={field.id}>
              {field.name}
            </MenuItem>
          ))}
        </Select>
        <Typography variant="caption">Producer</Typography>

        <Select
          value={dashboardWidgetsProducer}
          label="Widgets"
          style={{ width: '100%' }}
          multiple
          onChange={(e) => handleFieldChange(e.target.value, 'producer')}
        >
          {sharedWidgets?.map((field) => (
            <MenuItem key={field.id} value={field.id}>
              {field.name}
            </MenuItem>
          ))}
        </Select>
        <Typography variant="caption">Data specialist</Typography>

        <Select
          value={dashboardWidgetsDataSpecialist}
          label="Widgets"
          style={{ width: '100%' }}
          multiple
          onChange={(e) => handleFieldChange(e.target.value, 'dataSpecialist')}
        >
          {sharedWidgets?.map((field) => (
            <MenuItem key={field.id} value={field.id}>
              {field.name}
            </MenuItem>
          ))}
        </Select>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px',
            borderBottom: '1px solid #e0e0e0',
            position: 'fixed',
            bottom: '0',
            width: '100%',
            background: 'inherit',
          }}
        >
          <Box>
            <Button variant="contained" onClick={saveWidgetSetting}>
              Save
            </Button>
            <Button
              variant="outlined"
              style={{ marginLeft: '10px' }}
              onClick={closeAddWidgetDialog}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WidgetSelector;
