import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Checkbox, Popover, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';

import { FieldTypes } from '@/types';
import BasicDateRangePicker from '@/common/BasicDateRangePicker';

const MoreDateFilters = ({ title, filters, values, onSetValue }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMore = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkBoxComponent = (filters) => {
    const checkBox = filters.find(
      (filter) => filter.type === FieldTypes.BOOLEAN
    );

    if (!checkBox) {
      console.error('Filters are missing required fields: ', { filters });
      return null;
    }

    return (
      <Checkbox
        checked={values.get(checkBox.filterKey) === 'true'}
        onChange={(e) => {
          onSetValue(checkBox.filterKey, e.target.checked ? 'true' : undefined);
        }}
        key={checkBox.filterKey}
      />
    );
  };

  const dateRangeComponent = (filters) => {
    const startDate = filters.find((filter) => filter.label?.includes('start'));
    const endDate = filters.find((filter) => filter.label?.includes('end'));

    if (!startDate || !endDate) {
      console.error('Filters are missing required fields: ', { filters });
      return null;
    }

    return (
      <BasicDateRangePicker
        range={{
          startDate: values.get(startDate.filterKey) || null,
          endDate: values.get(endDate.filterKey) || null,
          startDateLabel: startDate.label || 'Start Date',
          endDateLabel: endDate.label || 'End Date',
        }}
        onChange={(range) => {
          onSetValue(startDate.filterKey, range.startDate);
          onSetValue(endDate.filterKey, range.endDate);
        }}
        key={`${startDate.filterKey}-to-${endDate.filterKey}`}
        width={200}
        mt={1}
        my={1}
      />
    );
  };

  return (
    <Box>
      <IconButton
        id="more-date-filters-btn"
        onClick={handleClick}
        sx={{ pl: 0 }}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        id="more-date-filter-view"
        open={openMore}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ ml: -36, mt: 1 }}
      >
        <Box sx={{ p: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="subtitle2">{title}</Typography>
            <Typography
              variant="caption"
              sx={{ maxWidth: 60, textAlign: 'right', lineHeight: 1.2 }}
            >
              Include blanks
            </Typography>
          </Box>
          <Box>
            {filters.map((filter) => (
              <Box
                key={filter.filterFieldId}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                {dateRangeComponent(filter.filters)}
                {checkBoxComponent(filter.filters)}
              </Box>
            ))}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default MoreDateFilters;
