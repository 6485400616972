import { Chip } from '@mui/material';
import { AccountIds } from 'common/constants';
import { Link, Navigate } from 'react-router-dom';

import { SyncedEntity } from '@/common/SyncedEntity';
import { SyncEndAdornment } from '@/common/SyncEndAdornment';
import DataView from '@/components/DataView';
import { useSyncedFieldsNew } from '@/contexts/useSyncedFields';
import API from '@/services/API';
import { useAccountStore, useRoleStore } from '@/store';
import { Roles } from '@/types';
import { useUserInfo } from '@/hooks/useUserInfo';
import Formatter from '@/services/Formatter';

const View = () => {
  const { data: { fintaryAdmin } = {} } = useUserInfo();
  const { selectedAccount } = useAccountStore();
  const { userRole } = useRoleStore();
  const { data: accountSettings, isFetched: isFetchedAccountSettings } =
    API.getBasicQuery(`accounts/settings`);

  const viewSettings = accountSettings?.pages_settings?.products;

  const viewOnly = viewSettings?.read_only ?? false;

  if (isFetchedAccountSettings && viewSettings?.show_page === false) {
    return (
      // TODO: Remove navigate after figuring out how to handle this in router
      <Navigate to="/settings" />
    );
  }

  const companies = API.getBasicQuery('companies')?.data?.data ?? [];

  const dataDesc = {
    label: 'Products',
    table: 'companies/products',
    editable: true,
    copyable: true,
    bulkAdd: true,
    filterConfigs: {
      cids: {
        type: 'select',
        label: 'Companies',
        options: companies.map((item) => ({
          id: item.id,
          str_id: item.str_id,
          label: item.company_name,
        })),
      },
    },
    fields: [
      {
        id: 'company_id',
        label: 'Company',
        type: 'dynamic-select',
        table: 'companies',
        field: 'id',
        formatter: (val, collectionVals: any = []) =>
          (Array.isArray(collectionVals) &&
            collectionVals?.find((company) => company.id === val)
              ?.company_name) ||
          '',
        optionFormatter: (option) => option.company_name,
        optionValuer: (option) => option.id,
        required: true,
        bulkAddSelect: true,
      },
      {
        id: 'product_type',
        label: 'Product type',
        type: 'select',
        options: [
          'Life',
          'IUL',
          'UL',
          'Term',
          'Annuity',
          'Indexed Annuity',
          'Fixed Annuity',
          'Health',
          'Dental',
          'Vision',
          'Disability',
          'Long Term Care',
          'Other',
        ],
      },
      {
        id: 'product_name',
        label: 'Product name',
        required: true,
      },
      {
        id: 'comp_grid_products',
        label: 'Comp grid products 🔒',
        formatter: (val) => (
          <Chip
            label={val?.name}
            component={Link}
            to={`/schedules/comp-grids/products?id=${val?.str_id}`}
            clickable
          />
        ),
        tableFormatter: (val) =>
          val?.length > 0
            ? val.map((compGridProduct) => (
                <Chip
                  label={compGridProduct?.name}
                  component={Link}
                  to={`/schedules/comp-grids/products?id=${compGridProduct?.str_id}`}
                  clickable
                  sx={{ m: 0.25 }}
                />
              ))
            : Formatter.statusChip('Missing products', {
                mapping: {
                  ['Missing products']: 'yellow',
                },
              }),
        readOnly: true,
        enabled:
          !!fintaryAdmin ||
          (userRole === Roles.ACCOUNT_ADMIN &&
            selectedAccount?.accountId === AccountIds.TRANSGLOBAL),
      },
      {
        id: 'notes',
        label: 'Notes',
      },
    ],
    actions: [
      {
        id: 'sync',
        label: 'Sync',
        type: 'custom',
        getComponent: (row) => (
          <SyncedEntity isSynced={!!row.sync_id} disabled={true} />
        ),
      },
    ],
    queryChips: {
      all: {
        id: 'all',
        label: 'All',
        query: {},
      },
      contains_products: {
        id: 'contains_products',
        label: 'Contains products',
        query: {
          comp_grid_products: 'contains_products',
        },
      },
      missing_products: {
        id: 'missing_products',
        label: 'Missing products',
        query: {
          comp_grid_products: 'missing_products',
        },
      },
    },
  };

  const { workerSyncedFields, isSyncedField } = useSyncedFieldsNew();
  dataDesc.fields.forEach((field: any) => {
    field.readOnly = (data) => {
      const syncedFields =
        workerSyncedFields?.[data?.sync_worker]?.company_products;
      if (syncedFields?.includes(field.id)) {
        return isSyncedField(data, syncedFields, field.id, data.config);
      }
      return false;
    };
    field.endAdornment = (data, field, setNewData) => (
      <SyncEndAdornment
        syncedFields={workerSyncedFields?.[data?.sync_worker]?.company_products}
        syncId={data?.sync_id}
        fieldId={field?.id}
        fieldType={field.type}
        data={data}
        onChange={(newOverrideFields) => {
          setNewData({
            ...data,
            config: {
              ...(data.config || {}),
              overrideFields: newOverrideFields,
            },
          });
        }}
      />
    );
  });

  if (viewSettings?.page_label) {
    dataDesc.label = viewSettings?.page_label;
  }

  return (
    <DataView
      dataDesc={dataDesc}
      viewOnly={viewOnly}
      readOnly={viewOnly}
      enablePagination
    />
  );
};

export default View;
