import {
  Box,
  FormControl,
  InputLabel,
  SxProps,
  TextField,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { DataProcessingAction } from 'common/globalTypes';

import { EnhancedSelect } from './EnhancedSelect';

export const fieldMatcherActions = [
  {
    id: DataProcessingAction.SET_FIELD_VALUE,
    label: 'Set field value',
  },
];

interface ActionPickerProps {
  value: any;
  row: any;
  fieldGetter: any;
  setValue: (val: any) => void;
  sx?: SxProps;
}

const ActionPicker: React.FC<ActionPickerProps> = ({
  value: actionObj = { field: '', action: '', value: '' },
  row,
  fieldGetter,
  setValue,
  sx = {},
}) => {
  const [selectedActionField, setSelectedActionField] = useState<string | null>(
    actionObj?.field
  );

  const selectedFields = fieldGetter(row);

  const setActionObject = (value, key) => {
    const updatedActionObj = { ...actionObj, [key]: value };

    setValue((prevData) => {
      return {
        ...prevData,
        data_update_actions: updatedActionObj,
      };
    });
  };

  const valueOptions = useMemo(() => {
    return selectedFields?.find((field) => field.id === selectedActionField)
      ?.options as string[];
  }, [selectedFields, selectedActionField]);

  return (
    <Box
      sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', width: '100%', ...sx }}
    >
      <EnhancedSelect
        label="Action"
        value={fieldMatcherActions.find((item) => item.id === actionObj.action)}
        options={fieldMatcherActions}
        onChange={(v) => {
          setActionObject(v?.id, 'action');
        }}
        sx={{ width: 200, mr: 1 }}
      />

      {selectedFields && (
        <EnhancedSelect
          label="Field"
          options={selectedFields}
          value={selectedFields.find((item) => item.id === actionObj?.field)}
          onChange={(v) => {
            setActionObject((v as any)?.id, 'field');
            setSelectedActionField((v as any)?.id);
          }}
          sx={{ mr: 1, minWidth: 200, width: 'fit-content' }}
        />
      )}

      {selectedActionField && (
        <>
          {valueOptions?.length ? (
            <EnhancedSelect
              label="Value"
              onChange={(v) => {
                setActionObject(v, 'value');
              }}
              options={valueOptions}
              value={actionObj?.value}
            />
          ) : (
            <FormControl sx={{ width: 145 }}>
              <InputLabel>Value</InputLabel>
              <TextField
                value={actionObj?.value || ''}
                onChange={(e) => setActionObject(e.target.value, 'value')}
                label="Value"
                variant="outlined"
              />
            </FormControl>
          )}
        </>
      )}
    </Box>
  );
};

export default ActionPicker;
