import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { isEqual } from 'lodash-es';
import { CustomSortParams, SortOrder } from './globalTypes';

dayjs.extend(utc);

export const getUpdatedFields = (oldObj, newObj) => {
  // TODO: Would be nice if FE only sent those fields that were updated
  // Use centralized types for this handling instead of keying off field keys
  const updatedFields: string[] = [];
  Object.keys(newObj).forEach((key) => {
    if (key.endsWith('_date')) {
      if (
        oldObj[key] &&
        !Number.isNaN(new Date(oldObj[key]).getTime()) &&
        newObj[key] &&
        !Number.isNaN(new Date(newObj[key]).getTime()) &&
        new Date(oldObj[key]).getTime() !== new Date(newObj[key]).getTime()
      ) {
        updatedFields.push(key);
      }
    } else if (key.endsWith('_amount')) {
      if (+oldObj[key] !== +newObj[key]) {
        updatedFields.push(key);
      }
    } else if (['split_percentage'].includes(key)) {
      +oldObj[key] === +newObj[key];
    } else if (!isEqual(oldObj[key], newObj[key])) {
      updatedFields.push(key);
    }
    return oldObj[key] !== newObj[key];
  });
  return updatedFields;
};

export const numberOrDefault = (
  input: any,
  defaultValue: number | string | null = 0,
  opts: { toFixed?: number } = {}
): number | undefined => {
  if (defaultValue === 'undefined') {
    defaultValue = undefined;
  }
  let res = Number.isNaN(parseFloat(input))
    ? (defaultValue as number)
    : parseFloat(input);
  if (typeof res === 'number' && typeof opts?.toFixed === 'number') {
    res = +res.toFixed(opts.toFixed);
  }
  return res;
};

export const dateOrDefault = (input: any, defaultValue = undefined) => {
  if (input === null || input === undefined) return defaultValue;
  const _input =
    typeof input === 'string' ? decodeURIComponent(input) : input.toString();
  const date = new Date(_input);
  if (Number.isNaN(date.getTime())) {
    return defaultValue;
  }
  return date;
};

export const calcExecuteTime = async (name, context, fn) => {
  const start = process.hrtime();
  const result = await fn.call(context);
  const stop = process.hrtime(start);
  const executionTime = (stop[0] * 1e9 + stop[1]) / 1e9;
  console.log(`${name} execution time: ${executionTime}s`);
  return result;
};

export const setNextDay = (date: Date) => {
  if (!(date instanceof Date)) return date;
  date.setDate(date.getDate() + 1);
  return date;
};

export const isNill = (value: any) => {
  return (
    value === null ||
    value === undefined ||
    value === 'null' ||
    value === 'undefined' ||
    value === '' ||
    value === '""' ||
    value === "''" ||
    value === '“”' ||
    value === '‘’' ||
    value === 'NaN'
    // Number.isNaN(value)
  );
};

export const isValidJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

interface DateRange {
  id?: number;
  start_date: Date | null;
  end_date: Date | null;
}
const getValidDate = (date: any, defaultDate: Date): Date => {
  if (typeof date === 'string' || typeof date === 'number') {
    const parsedDate = new Date(date);
    if (!isNaN(parsedDate.getTime())) {
      return parsedDate;
    }
  }
  if (date instanceof Date && !isNaN(date.getTime())) {
    return date;
  }
  return defaultDate;
};
export const doDateRangesOverlap = (ranges: DateRange[]): boolean => {
  const earliestDate = new Date(-8640000000000000);
  const latestDate = new Date(8640000000000000);
  for (let i = 0; i < ranges.length; i++) {
    for (let j = i + 1; j < ranges.length; j++) {
      const range1 = ranges[i];
      const range2 = ranges[j];

      const range1Start = getValidDate(range1.start_date, earliestDate);
      const range1End = getValidDate(range1.end_date, latestDate);
      const range2Start = getValidDate(range2.start_date, earliestDate);
      const range2End = getValidDate(range2.end_date, latestDate);
      if (
        (range1Start <= range2End && range1End >= range2Start) ||
        (range2Start <= range1End && range2End >= range1Start) ||
        (range1Start === range2Start && range1End === range2End)
      ) {
        return true;
      }
    }
  }
  return false;
};

/**
 * Merges and processes date ranges by converting date strings to Date objects
 * and filtering out existing date ranges that are not present in the new date ranges.
 *
 * @param {DateRange[]} date_ranges - The new date ranges to be processed.
 * @param {DateRange[]} existingDateRanges - The existing date ranges to be filtered.
 * @returns {DateRange[]} - The merged and processed date ranges.
 */
export const mergeAndProcessDateRanges = (
  date_ranges: DateRange[],
  existingDateRanges: DateRange[]
): DateRange[] => {
  const newDateRanges = (date_ranges || []).map((range) => ({
    ...range,
    start_date: range.start_date
      ? new Date(range.start_date)
      : range.start_date,
    end_date: range.end_date ? new Date(range.end_date) : range.end_date,
  }));

  const filteredExistingDateRanges = existingDateRanges.filter(
    (existingRange) =>
      newDateRanges.some((newRange) => newRange.id === existingRange.id)
  );

  const uniqueDateRanges = [
    ...filteredExistingDateRanges,
    ...newDateRanges.filter(
      (newRange) =>
        !filteredExistingDateRanges.some(
          (existingRange) => existingRange.id === newRange.id
        )
    ),
  ];

  return uniqueDateRanges;
};

/**
 * Converts fields in an object that end with '_date' to dayjs objects.
 *
 * @param {Record<string, any>} obj - The object containing fields to convert.
 * @returns {Record<string, any>} A new object with '_date' fields converted to dayjs objects.
 */
export const convertDateFields = (
  obj: Record<string, any>
): Record<string, any> =>
  Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    if (key.endsWith('_date') || key.endsWith('date')) {
      if (value !== null && value !== undefined && value !== '') {
        acc[key] = dayjs.utc(value);
      } else {
        acc[key] = null;
      }
    } else {
      acc[key] = value;
    }
    return acc;
  }, {} as Record<string, any>);

export const getOrderBy = (
  property: string = 'created_at',
  sort: string = 'desc',
  fieldMappings: {
    query_field: string;
    db_field: string;
  }[] = []
) => {
  const fieldMapTarget = fieldMappings.find(
    (item) => item.query_field === property
  );
  if (fieldMapTarget) {
    const { query_field, db_field } = fieldMapTarget;
    return {
      [query_field]: {
        [db_field]: sort,
      },
    };
  }
  return {
    [property]: sort,
  };
};

export const mapToOrderBy = (
  property: string = 'created_at',
  sort: string = 'desc',
  fieldMappings: {
    query_field: string;
    db_field: string;
    nested_db_field?: string;
  }[] = []
) => {
  const fieldMapTarget = fieldMappings.find(
    (item) => item.query_field === property
  );

  if (fieldMapTarget) {
    if (fieldMapTarget.nested_db_field) {
      const { db_field, nested_db_field } = fieldMapTarget;
      return {
        [db_field]: {
          [nested_db_field]: sort,
        },
      };
    } else {
      const { db_field } = fieldMapTarget;
      return {
        [db_field]: sort,
      };
    }
  }

  return {
    ['created_at']: sort,
  };
};

export const getFilenameFromPath = (path, removeAllNanoId = false) => {
  if (!path) return '';
  const filename = path.split('/').pop()?.trim();
  if (!filename) return '';
  if (removeAllNanoId) {
    const nanoIdAllRegex = /[-_a-zA-Z0-9]{21}-/g;
    return filename.replace(nanoIdAllRegex, '');
  }
  return filename.replace(/^[-_a-zA-Z0-9]{21}-/, '');
};

export const convertArrayOfMapsToArrayOfArrays = (
  fields = [],
  arrayOfMaps = [{}]
) => {
  const arr = [];
  arrayOfMaps?.forEach((map) => {
    const row = [];
    fields?.forEach((k) => {
      row.push(map[k]);
    });
    arr.push(row);
  });
  return arr;
};

export const removeLeadingTrailingChar = (
  str: string,
  charToRemove: string
) => {
  return str.replace(
    new RegExp(`^${charToRemove}+|${charToRemove}+$`, 'g'),
    ''
  );
};

/**
 * Checks if the parameter is an array and has a length greater than 0.
 *
 * @param {unknown} param - The parameter to check.
 * @returns {boolean} - Returns true if the parameter is an array with length > 0, otherwise false.
 */
export const isNonEmptyArray = (param: unknown): param is unknown[] => {
  return Array.isArray(param) && param.length > 0;
};

export const getBasicRelevancySort =
  (fields: string[] = [], query: string = '') =>
  (a, b) => {
    const getFieldVals = (fields: string[], data) =>
      fields.map((field) => data[field]?.toLowerCase());
    const queryLowerCase = query.trim().toLowerCase();
    const aVals = getFieldVals(fields, a);
    const bVals = getFieldVals(fields, b);

    if (aVals.includes(queryLowerCase)) return -1;
    if (bVals.includes(queryLowerCase)) return 1;
    if (aVals.some((e) => e?.startsWith(queryLowerCase))) return -1;
    if (bVals.some((e) => e?.startsWith(queryLowerCase))) return 1;
    return;
  };

export const tryDecodeURIComponent = (str: string) => {
  try {
    const decoded = decodeURIComponent(str);
    return decoded !== str ? decoded : str;
  } catch (e) {
    return str;
  }
};

/**
 * Custom sorting function for any type or object.
 * @param CustomSortParams<T> customSortParams - The custom sort parameters.
 * @returns The sorted array.
 */
export const customSort = <T>(
  customSortParams: CustomSortParams<T>
): T[] => {
  const { array, orderBy, sort } = customSortParams;
  if (!orderBy || !sort) return array; 

  return array.sort((a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    if (aValue === undefined && bValue === undefined) return 0;
    if (aValue === undefined) return sort === SortOrder.ASC ? -1 : 1;
    if (bValue === undefined) return sort === SortOrder.ASC ? 1 : -1;

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sort === SortOrder.ASC
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return sort === SortOrder.ASC ? aValue - bValue : bValue - aValue;
    }

    return 0;
  });
};
