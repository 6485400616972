export const dashboardWidgetOptions = {
  policiesInForce: 'policiesInForce',
  premiumsInForce: 'premiumsInForce',
  premiumsIssuePay: 'premiumsIssuePay',
  commissionsReceived: 'commissionsReceived',
  commissionsPaid: 'commissionsPaid',
  numPoliciesWithCommissions: 'numPoliciesWithCommissions',
  policiesWithReversedPayments: 'policiesWithReversedPayments',
  commissionsByCarrierMap: 'commissionsByCarrierMap',
  commissionsByWritingCarrierMap: 'commissionsByWritingCarrierMap',
  premiumsByCarrierMap: 'premiumsByCarrierMap',
  agentCommissions: 'agentCommissions',
  commissionsByTimeMap: 'commissionsByTimeMap',
  commissionsAccByTimeMap: 'commissionsAccByTimeMap',
  commissionsOutByTimeMap: 'commissionsOutByTimeMap',
  commissionsOutAccByTimeMap: 'commissionsOutAccByTimeMap',
  commissionsNetByTimeMap: 'commissionsNetByTimeMap',
  commissionsNetAccByTimeMap: 'commissionsNetAccByTimeMap',
  policiesByTimeMap: 'policiesByTimeMap',
  effectiveCommissionRateByCarrierArray:
    'effectiveCommissionRateByCarrierArray',
  effectiveCommissionRateByWritingCarrierArray:
    'effectiveCommissionRateByWritingCarrierArray',
};

export enum TransactionStatuses {
  None = '',
  Draft = 'Draft',
  InReview = 'In review',
  RequestUpdate = 'Request update',
  NonPayable = 'Non-payable',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Paid = 'Paid',
}

export const CALC_METHODS = {
  compGrid: 'Pay commission to grid level',
  compGridLevel: 'Share override to grid level',
  payoutRate: 'Pay a set rate',
  payHouseRate: 'Pay house rate',
  payOverrideUpToTotalRate: 'Pay override up to total rate',
  keepRate: 'Keep a set rate',
  overrideSplit: 'Split percentage',
  carrierGridSplitlRemainder: 'Pay to a grid level then split percentage',
  payoutRateIncentiveTiers: 'Pay based on incentive tier',
  referral: 'Referral',
};

export enum WidgetGroup {
  BOX = 'BOX',
  CHART = 'CHART',
  TABLE = 'TABLE',
}

export enum AccountIds {
  TRANSGLOBAL = 'XN9U5UtGrD5aovyEwNlHj',
  EDISON_RISK = 'tY4K6TGT8NH6yMREQf2XQ',
  TRAILSTONE = 'f_mX8g_LfFzV1SeD-U4ki',
}

export const ALEVO_EXCLUDED_AGENTS = [
  'DrRNXzr0itzLiqYRJyVkk',
  'Jcp0FdGNQR6Gk6UnoYFAB',
  'SwHJMuUejInJGRGlHXatD',
  'zbmNbWcwgM1gNEvLH_7n0',
];

export enum WorkerNames {
  AgencyIntegratorWorker = 'AgencyIntegratorWorker',
  NowCertsWorker = 'NowCertsWorker',
  OneHQWorker = 'OneHQWorker',
  SmartOfficeWorker = 'SmartOfficeWorker',
  BenefitPointWorker = 'BenefitPointWorker',
  TransGlobalWorker = 'TransGlobalWorker',
  MyAdvisorGridsWorker = 'MyAdvisorGridsWorker',
  AwsS3Worker = 'AwsS3Worker',
}

export const RATES_LABELS = {
  carrier_rate: 'Carrier rate',
  house_rate: 'House rate',
  rate: 'Total rate',
};
