import { RemoveCircleOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { clone } from 'lodash-es';

const emptyRow = { field: '', order: '' };

const OrderConfig = ({ fieldId, data: rowData, setter, fields }) => {
  const data =
    typeof rowData[fieldId] === 'string'
      ? [clone(emptyRow)]
      : (rowData[fieldId] ?? [clone(emptyRow)]);

  return (
    <Box key={fieldId}>
      {data?.map((datum, i) => (
        <Box key={i} sx={{ mb: 1, display: 'flex' }}>
          <FormControl sx={{ mr: 2 }}>
            <InputLabel id={datum.id}>Field</InputLabel>
            <Select
              label="Field"
              labelId={datum.id}
              value={datum.field}
              onChange={(e) => {
                const newdatums = [...data];
                newdatums[i].field = e.target.value;
                setter({ ...rowData, [fieldId]: newdatums });
              }}
              sx={{ minWidth: 180 }}
            >
              {fields?.map((field) => (
                <MenuItem key={field.id} value={field.id}>
                  {field.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex' }}>
            <FormControl sx={{ mr: 2 }}>
              <InputLabel id={`${datum.id}-order`}>Order</InputLabel>
              <Select
                label="Order"
                labelId={`${datum.id}-order`}
                value={datum.order}
                onChange={(e) => {
                  const newdatums = [...data];
                  newdatums[i].order = e.target.value;
                  setter({ ...rowData, [fieldId]: newdatums });
                }}
                sx={{ minWidth: 180 }}
              >
                <MenuItem value="asc">Ascending</MenuItem>
                <MenuItem value="desc">Descending</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <IconButton
            onClick={() => {
              const newdatums = [...data];
              newdatums.splice(i, 1);
              setter({ ...rowData, [fieldId]: newdatums });
            }}
          >
            <RemoveCircleOutline />
          </IconButton>
        </Box>
      ))}
      <Button
        onClick={() =>
          setter({ ...rowData, [fieldId]: [...data, clone(emptyRow)] })
        }
      >
        Add
      </Button>
    </Box>
  );
};

export default OrderConfig;
