export enum CompGridRateFields {
  ALL = 'all',
  CARRIER_RATE = 'carrier_rate',
  HOUSE_RATE = 'house_rate',
  TOTAL_RATE = 'total_rate',
}

export interface BulkEditBody {
  ids: number[];
  policy_id?: string;
  agent_name?: string;
  writing_carrier_name?: string;
  carrier_name?: string;
  customer_name?: string;
  product_type?: string;
  product_name?: string;
  effective_date?: string;
  notes?: string;
  contacts?: any;
  agent_commissions_status?: string;
  compensation_type?: string;
  transaction_type?: string;
  policy_status?: string;
  product_option_name?: string;
  processing_date?: string;
  payment_date?: string;
  period_date?: string;
  internal_id?: string;
  split_percentage?: number;
  premium_type?: string;
}

export enum ContactPayableStatuses {
  PAYABLE = 'payable',
  NON_PAYABLE = 'non_payable',
}

export enum ReportReviewStatuses {
  None = '',
  Draft = 'draft',
  Approved = 'approved',
  Rejected = 'rejected',
  InReview = 'in_review',
  RequestUpdate = 'request_update',
  NonPayable = 'non_payable',
}

export enum ReportReviewStatusesLabels {
  None = '',
  Draft = 'Draft',
  Approved = 'Approved',
  Rejected = 'Rejected',
  InReview = 'In review',
  RequestUpdate = 'Request update',
  NonPayable = 'Non-payable',
}

export enum CompReportDateFilter {
  ProcessingDate = 'processing_date',
  PaymentDate = 'payment_date',
}

export enum CompReportPrefixConfig {
  Default = 'default_prefix',
  Custom = 'custom_prefix',
}

export enum ProductTypes {
  LIFE = 'Life',
  ANNUITY = 'Annuity',
}

export enum CompensationTypes {
  // COMMISSION = 'Commission',
  // OVERRIDE = 'Override',
  // EXCESS = 'Excess',
  // RENEWAL_COMMISSION = 'Renewal Commission',
  // TRAIL = 'Trail',
  // Use TG definitions until we have a config for this
  COMMISSION = 'FYC',
  OVERRIDE = 'FYC',
  EXCESS = 'Excess',
  RENEWAL_COMMISSION = 'Renew',
  TRAIL = 'Trail',
}

// TODO: Define more detailed types for saved report snapshot data
export interface SnapshotDataData {
  data: any[];
  count: number;
  totals: any;
  contactUser: string;
  contactStrId: string;
  fieldOptions: any;
  contactBankInfo: string;
  contactPayableStatus: string;
}
export interface SavedReportSnapshotData {
  data: SnapshotDataData;
  headers: any[];
  reportPage: string;
}

export enum CompReportViewTypes {
  PRODUCER_VIEW = 'prod-view',
  ADMIN_VIEW = 'admin-view',
}

export enum RulesOperators {
  // TODO: Add directional operators (e.g. within one year before | within one year after)
  EQ = 'eq',
  NEQ = 'neq',
  GT = 'gt',
  LT = 'lt',
  GTE = 'gte',
  LTE = 'lte',
  CONTAINS = 'contains',
  NCONTAINS = 'ncontains',
  CONTAINEDIN = 'containedin',
  NCONTAINEDIN = 'ncontainedin',
  STARTSWITH = 'startswith',
  ENDSWITH = 'endswith',
  BEFORE = 'before',
  BEFORE_EQUALS = 'beforeEquals',
  AFTER = 'after',
  AFTER_EQUALS = 'afterEquals',
  IS_EMPTY = 'isEmpty',
  WITHIN_ONE_YEAR = 'withinOneYear',
  AT_LEAST_ONE_YEAR = 'atLeastOneYear',
  UNKNOWN = 'unknown',
}

export enum DataProcessingAction {
  SET_FIELD_VALUE = 'set_field_value',
}

export interface DataUpdateRule {
  field?: string;
  op?: RulesOperators;
  value?: string[] | string;
  action?: string;
  type?: string;
}

export interface DataUpdateRules {
  [id: number]: DataUpdateRule[];
}

export enum AgentCommissionsStatusesLabels {
  None = '(Blank)',
  Draft = 'Draft',
  InReview = 'In review',
  Manual = 'Manual',
  Approved = 'Approved',
  Paid = 'Paid',
  NonPayable = 'Non-payable',
}

export enum DataEntities {
  COMMISSIONS = 'Commissions',
  POLICIES = 'Policies',
}

export enum ContactStatuses {
  INACTIVE = 'Inactive',
  // TODO: Add more statuses
}

export enum AccessTypes {
  GLOBAL = 'global',
  ACCOUNT = 'account',
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export interface CustomSortParams<T> {
  array: T[],
  orderBy: keyof T | undefined,
  sort: SortOrder;
}
