import { DeleteOutline, GroupAdd } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Skeleton,
  Tooltip,
} from '@mui/material';
import CommonFormatter from 'common/Formatter';
import { AccountIds } from 'common/constants';
import { ContactPayableStatuses } from 'common/globalTypes';
import { capitalize } from 'lodash-es';
import { useContext, useEffect, useState } from 'react';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import validator from 'validator';

import { BasicDialog } from '@/common';
import DataView from '@/components/DataView';
import AgentsAdd from '@/components/contacts/ContactsView/AgentsAdd';
import CompProfilesAdd from '@/components/contacts/ContactsView/CompProfilesAdd';
import ContactMemosAdd from '@/components/contacts/ContactsView/ContactMemosAdd';
import ContactsLevelsAdd from '@/components/contacts/ContactsView/ContactsLevelsAdd';
import ContactsReferralsAdd from '@/components/contacts/ContactsView/ContactsReferralsAdd';
import ParentsHierarchyAdd from '@/components/contacts/ContactsView/ParentsHierarchyAdd';
import { LoadingContext } from '@/contexts/LoadingContext';
import { useSyncedFieldsNew } from '@/contexts/useSyncedFields';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { useAccountStore } from '@/store';
import { FieldTypes, Roles } from '@/types';
import ContactsTransactionsAdd from './ContactsTransactionsAdd';
import { SyncEndAdornment } from '@/common/SyncEndAdornment';
import DataTransformation from '@/services/DataTransformation';
import useSnackbar from '@/contexts/useSnackbar';
import { SyncedEntity } from '@/common/SyncedEntity';

const ContactsView = () => {
  const Normalizer = DataTransformation;

  const { data: accountSettings, isFetched: isFetchedAccountSettings } =
    API.getBasicQuery(`accounts/settings`);

  const viewSettings = accountSettings?.pages_settings?.agents;

  const viewOnly = viewSettings?.read_only ?? false;

  if (isFetchedAccountSettings && viewSettings?.show_page === false) {
    return (
      // TODO: Remove navigate after figuring out how to handle this in router
      <Navigate to="/settings" />
    );
  }

  const [showDelete, setShowDelete] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);

  const [showConfig, setShowConfig] = useState({
    open: false,
    refresh: 0,
  });
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams({});
  const { setLoadingConfig } = useContext(LoadingContext);
  const { selectedAccount } = useAccountStore();
  const { showSnackbar } = useSnackbar();

  const { data: contactGroups } = API.getBasicQuery('contacts/groups');
  const { data: contactOptions, isFetched: isFetchedContactOptions } =
    API.getBasicQuery('contacts/options');
  const contactsDeleter = API.getMutation('report_data/agents', 'DELETE');

  const isAccountTransactionsEnabled =
    selectedAccount?.accountingTransactionsEnabled;
  const renderAgentChip = (val, contact) => {
    const startDate = val.start_date ? new Date(val.start_date) : null;
    const endDate = val.end_date ? new Date(val.end_date) : null;
    const currentDate = new Date();
    const isCurrentDateInRange =
      (startDate === null || currentDate >= startDate) &&
      (endDate === null || currentDate <= endDate);
    const agentName = Formatter.contact(contact, {
      account_id: selectedAccount?.accountId,
    });
    const dateRange = CommonFormatter.dateRange(val.start_date, val.end_date);
    return (
      <Tooltip title={dateRange}>
        <Chip
          label={agentName}
          sx={{
            color: isCurrentDateInRange ? 'inherit' : '#757575',
            m: 0.2,
          }}
          clickable
          component={Link}
          to={`/agents/list?id=${contact.str_id}`}
        />
      </Tooltip>
    );
  };

  const { workerSyncedFields, isSyncedField } = useSyncedFieldsNew();

  const dataDesc = {
    label: 'Agents',
    table: 'contacts?is_detailed_view=true&hierarchy_depth=1',
    editable: true,
    copyable: true,
    bulkAdd: true,
    extraActions: [],
    fields: [
      [
        {
          id: 'first_name',
          label: 'First name',
          required: true,
          queryable: true,
        },
        {
          id: 'last_name',
          label: 'Last name',
          queryable: true,
        },
        {
          id: 'nickname',
          label: 'Nickname',
          queryable: true,
        },
      ],
      [
        {
          id: 'email',
          label: 'Email',
          validator: (val) => val === '' || validator.isEmail(val),
          isUnique: true,
          existingVals: [],
          queryable: true,
        },
        {
          id: 'phone',
          label: 'Phone',
          validator: (val) =>
            val === '' || validator.isMobilePhone(val, 'en-US'),
          queryable: true,
        },
        { id: 'agent_code', label: 'Agent code', queryable: true },
        { id: 'bank_info', label: 'Bank info' },
      ],
      {
        id: 'contact_group_id',
        label: 'Agent group',
        type: 'dynamic-select',
        table: 'contacts/groups',
        field: 'id',
        nullable: true,
        queryable: true,
        formatter: (val, collectionVals = []) => {
          if (val === '') return '';
          if (Array.isArray(collectionVals) && collectionVals.length > 0) {
            const record: any = collectionVals?.filter(
              (datum: any) => datum?.id === val
            )?.[0];
            return record?.name ?? '';
          }
          return val; // not formatting when collectionVals is not available
        },
        optionFormatter: (option) => option?.name,
        optionValuer: (option) => option?.id,
        readOnly: (row) => ![null, undefined].includes(row?.parent_id),
        tip: 'Group can only be configured for the root agent',
      },
      [
        {
          id: 'type',
          label: 'Type',
          type: 'select',
          options: ['Agent', 'Sales rep', 'IMO'],
          strToValue: (vals) => vals?.split(',')?.map((v) => v.trim()),
          queryable: true,
        },
        {
          id: 'status',
          label: 'Status',
          type: 'select',
          options: [
            ...new Set([
              '',
              'active',
              'archived',
              ...(contactOptions?.status ?? []),
            ]),
          ],
          queryable: true,
          condition: () => isFetchedContactOptions,
        },
        {
          id: 'payable_status',
          label: 'Payable status',
          queryable: true,
          type: 'select',
          options: [
            ...new Set([
              '',
              ContactPayableStatuses.PAYABLE,
              ContactPayableStatuses.NON_PAYABLE,
              ...(contactOptions?.agent_payable_status ?? []),
            ]),
          ],
          condition: () => isFetchedContactOptions,
          formatter: (val) => capitalize(val),
        },
      ],
      {
        id: 'level',
        label: 'Grid levels',
        type: 'custom',
        tableFormatter: (field, row) => {
          if (Array.isArray(row.contact_level)) {
            return row.contact_level.map((level) => (
              <Box key={level.id}>
                {level.level_label && (
                  <Chip label={level.level_label} sx={{ m: 0.5 }} />
                )}
                {level.loa && <Chip label="LOA" sx={{ m: 0.5 }} />}
              </Box>
            ));
          }
        },
        render: (field, row, setter, dynamicSelects) => (
          <ContactsLevelsAdd
            key="contact_levels_add"
            data={row}
            setter={setter}
            field={field}
            isSyncedField={isSyncedField}
            syncedFields={
              workerSyncedFields?.[row?.sync_worker]?.contact_levels
            }
            dynamicSelects={dynamicSelects}
          />
        ),
      },
      {
        id: 'parent_relationships',
        label: 'Uplines',
        type: 'dynamic-select',
        table: 'contacts',
        multiple: true,
        formatter: (val) => {
          if (typeof val === 'object') {
            if (val.parent) {
              return renderAgentChip(val, val.parent);
            } else return null;
          }
          return val;
        },
        render: (field, row, setter, dynamicSelects) => (
          <ParentsHierarchyAdd
            key="parent_relationships_add"
            data={row}
            setter={setter}
            field={field}
            syncedFields={
              workerSyncedFields?.[row?.sync_worker]?.contact_hierarchy
            }
            isSyncedField={isSyncedField}
            dynamicSelects={dynamicSelects}
          />
        ),
        bulkAddUnsupported: true,
      },
      {
        id: 'child_relationships',
        label: 'Downlines',
        type: FieldTypes.SELECT,
        multiple: true,
        readOnly: true,
        linker: (val) => `/agents/list?id=${val?.contact?.str_id}`,
        formatter: (val) =>
          Formatter.contact(val?.contact, {
            account_id: selectedAccount?.accountId,
          }),
        optionFormatter: (val) =>
          Formatter.contact(val?.contact, {
            account_id: selectedAccount?.accountId,
          }),
        optionValuer: (val) => val?.id, // not used to save
      },
      {
        id: 'contacts_agent_commission_schedule_profiles_sets',
        label: 'Comp profile sets',
        type: 'dynamic-select',
        table: 'schedules/comp-profiles/sets',
        multiple: true,
        field: 'id',
        linker: (val) =>
          `/schedules/comp-profile-sets?id=${val?.agent_commission_schedule_profiles_sets?.str_id}`,
        formatter: (val, collectionVals = []) => {
          const _val =
            typeof val === 'object'
              ? val.agent_commission_schedule_profile_set_id
              : val;
          if (!Array.isArray(collectionVals))
            return (
              <Skeleton
                variant="rounded"
                width={160}
                height={24}
                sx={{ m: 0.25 }}
              />
            );
          if (collectionVals.length > 0) {
            const record: any = collectionVals?.find(
              (datum: any) => datum?.id === _val
            );
            const name = record?.name;
            return name ?? 'Not found';
          } else return _val?.str_id ?? _val?.id ?? _val?.label;
        },
        optionValuer: (option) => {
          return option?.id;
        },
        render: (field, row, setter, dynamicSelects) => (
          <CompProfilesAdd
            key="contacts_agent_commission_schedule_profiles_sets"
            data={row}
            setter={setter}
            field={field}
            dynamicSelects={dynamicSelects}
            type={'set'}
          />
        ),
        bulkAddUnsupported: true,
      },
      {
        id: 'contacts_agent_commission_schedule_profiles',
        label: 'Custom comp profiles',
        type: 'dynamic-select',
        table: 'schedules/comp-profiles',
        multiple: true,
        field: 'id',
        linker: (val) =>
          `/schedules/comp-profiles?id=${val?.agent_commission_schedule_profile?.str_id}`,
        formatter: (val, collectionVals = []) => {
          const _val =
            typeof val === 'object'
              ? val.agent_commission_schedule_profile_id
              : val;
          if (!Array.isArray(collectionVals))
            return (
              <Skeleton
                variant="rounded"
                width={160}
                height={24}
                sx={{ m: 0.25 }}
              />
            );
          if (collectionVals.length > 0) {
            const record: any = collectionVals?.find(
              (datum: any) => datum?.id === _val
            );
            const name = record?.name;
            return name ?? 'Not found';
          } else return _val?.str_id ?? _val?.id ?? _val?.label;
        },
        optionValuer: (option) => {
          return option?.id;
        },
        render: (field, row, setter, dynamicSelects) => (
          <CompProfilesAdd
            key="contacts_agent_commission_schedule_profiles"
            data={row}
            setter={setter}
            field={field}
            dynamicSelects={dynamicSelects}
          />
        ),
        bulkAddUnsupported: true,
      },
      {
        id: 'contact_memos',
        label: 'Memos',
        multiple: true,
        field: 'id',
        type: 'custom',
        tableFormatter: (field, _row) => field.length || '',
        render: (field, row, setter, dynamicSelects) => (
          <ContactMemosAdd
            key="contact_memos_add"
            data={row}
            setter={setter}
            field={field}
            dynamicSelects={dynamicSelects}
          />
        ),
        bulkAddUnsupported: true,
      },
      {
        id: 'contact_referrals',
        label: 'Referrals',
        multiple: true,
        field: 'id',
        type: 'custom',
        tableFormatter: (field, _row) => field.length || '',
        render: (field, row, setter, dynamicSelects) => (
          <ContactsReferralsAdd
            key="contact_referralss_add"
            data={row}
            setter={setter}
            field={field}
            dynamicSelects={dynamicSelects}
          />
        ),
        bulkAddUnsupported: true,
      },
      {
        id: 'notes',
        label: 'Notes',
        queryable: true,
      },
      {
        id: 'saved_reports',
        label: 'Saved reports',
        readOnly: true,
        type: 'select',
        multiple: true,
        formatter: (val) => {
          if (val)
            return (
              <Chip
                key={val.str_id}
                label={val.name}
                clickable
                component={Link}
                to={`/reports/${val.str_id}`}
                target="_blank"
                sx={{ m: 0.1 }}
              />
            );
          return '';
        },
        optionFormatter: (val) => val.name,
      },
      {
        id: 'user_contact',
        label: 'Fintary access',
        formatter: (s, row) => {
          if (s) {
            const account = row?.user_contact?.account_user_roles?.find(
              (account) => account.account_id === selectedAccount?.accountId
            );
            if (account) {
              return account.state;
            } else {
              return 'No access';
            }
          } else {
            return 'No access';
          }
        },
        tip: 'To grant access, go to Settings > User manager.',
        readOnly: true,
        queryable: true,
      },
      {
        id: 'str_id',
        label: 'Agent Id',
        queryable: true,
        readOnly: true,
        condition: (val) => val.str_id,
      },
    ] as any,
    actions: [
      {
        label: 'Delete',
        type: 'iconButton',
        icon: <DeleteOutline />,
        onClick: async (row) => {
          setCurrentRow(row);
          setShowDelete(true);
        },
      },
      {
        label: 'Synced',
        type: 'icon',
        enabled: (row) => !!row.sync_id,
        icon: <SyncedEntity isSynced={true} disabled />,
      },
    ],
    queryChips: {},
  };

  if (viewSettings?.page_label) {
    dataDesc.label = viewSettings?.page_label;
  }
  if (isAccountTransactionsEnabled) {
    dataDesc.fields.push(
      {
        id: 'accounting_transactions',
        label: 'Transactions',
        type: 'custom',
        tableFormatter: (field) => field.length || '',
        render: (field, row, setter) => (
          <ContactsTransactionsAdd
            key="contact_transactions_add"
            data={row}
            setter={setter}
            field={field}
          />
        ),
        access: [Roles.ACCOUNT_ADMIN],
      },
      {
        id: 'balance',
        label: 'Balance',
        type: FieldTypes.CURRENCY,
        readOnly: true,
        formatter: Normalizer.formatCurrency,
        normalizer: Normalizer.normalizeCurrency,
        access: [Roles.ACCOUNT_ADMIN],
      }
    );
  }

  useEffect(() => {
    if (typeof showConfig === 'boolean') {
      setShowModal(showConfig);
    } else {
      setRefresh(showConfig.refresh);
      setShowModal(showConfig.open);
    }
  }, [showConfig]);

  const extraActions = [
    <Button
      variant="outlined"
      startIcon={<GroupAdd />}
      onClick={() => {
        // setShowModal(true);
        setShowConfig({ open: true, refresh: 0 });
      }}
      sx={{ mr: 1 }}
      key="pullFromPolicies"
    >
      Pull from policies
    </Button>,
  ];

  // Temporary filter until we move over to EnhancedDataView
  if (selectedAccount?.accountId === AccountIds.TRANSGLOBAL) {
    extraActions.unshift(
      <FormControlLabel
        control={
          <Checkbox checked={searchParams.get('show_inactive') === 'true'} />
        }
        label="Show inactive"
        sx={{ mr: 1 }}
        onChange={(e: any) => {
          setSearchParams((prev: any) => {
            if (e.target.checked) {
              prev.set('show_inactive', 'true');
            } else {
              prev.delete('show_inactive');
            }
            return prev;
          });
        }}
        key="inactiveFilter"
      />
    );
  }

  if (Array.isArray(contactGroups) && contactGroups.length > 0) {
    const queryChips = [
      {
        id: '0',
        name: 'All',
        query: {},
      },
      ...(contactGroups ?? []),
    ];

    queryChips.forEach((group) => {
      if (dataDesc)
        dataDesc.queryChips[group?.id] = {
          id: String(group?.id),
          label: group.name,
          query: { contact_group_id: group?.id },
        };
    });
  }

  const DelConfirmComp = ({ row }) => {
    return (
      <BasicDialog
        title="Delete agent"
        open={showDelete}
        onClose={(val) => {
          if (val) {
            const accountUserRole = row?.user_contact?.account_user_roles?.find(
              (accountUserRole) =>
                accountUserRole.account_id === selectedAccount?.accountId
            );
            if (!row.user_contact || accountUserRole.state === 'deleted') {
              setLoadingConfig({
                loading: true,
                message: 'Deleting...',
              });
              const params = { ids: [row.id], strIds: [row.str_id] } as any;
              contactsDeleter
                .mutateAsync(params)
                .then(() => {
                  setRefresh(refresh + 1);
                  setLoadingConfig({
                    loading: false,
                    message: '',
                    delayToClose: 1000,
                  });
                  setCurrentRow(null);
                })
                .catch((err) => {
                  showSnackbar(err, 'error');
                  setLoadingConfig({
                    loading: false,
                    message: '',
                  });
                });
            } else {
              setShowDelete(false);
              setCurrentRow(null);
            }
          } else {
            setShowDelete(false);
            setCurrentRow(null);
          }
        }}
        bodyComponent={
          row?.user_contact?.account_user_roles?.find(
            (account) => account.account_id === selectedAccount?.accountId
          ).state === 'deleted' || !row.user_contact ? (
            <Alert severity="warning">
              Are you sure you want to delete this agent?
              <br />
              <br />
              {Formatter.contact(row, {
                account_id: selectedAccount?.accountId,
              })}
            </Alert>
          ) : (
            <Alert severity="warning">
              You can't delete the contact{' '}
              {Formatter.contact(row, {
                account_id: selectedAccount?.accountId,
              })}{' '}
              because it has a user associated. Please delete the user first.
            </Alert>
          )
        }
        positiveLabel="Delete"
      />
    );
  };

  const disableSyncedFields = (field) => {
    field.readOnly =
      field.readOnly ||
      ((data) => {
        const syncedFields = workerSyncedFields?.[data?.sync_worker]?.contacts;
        if (syncedFields?.includes(field.id)) {
          return isSyncedField(data, syncedFields, field.id, data.config);
        }
        return false;
      });
    field.endAdornment = (data, field, setNewData) => (
      <SyncEndAdornment
        syncedFields={workerSyncedFields?.[data?.sync_worker]?.contacts}
        syncId={data?.sync_id}
        fieldId={field?.id}
        data={data}
        fieldType={field.type}
        onChange={(newOverrideFields) => {
          setNewData({
            ...data,
            config: {
              ...(data.config || {}),
              overrideFields: newOverrideFields,
            },
          });
        }}
      />
    );
  };
  dataDesc.fields.forEach((field) => {
    if (Array.isArray(field)) {
      field.forEach(disableSyncedFields);
    } else {
      disableSyncedFields(field);
    }
  });

  return (
    <>
      {dataDesc && (
        <DataView
          headingOffset={104}
          dataDesc={dataDesc}
          extraActions={extraActions}
          refresh={refresh}
          viewOnly={viewOnly}
          readOnly={viewOnly}
          enablePagination
        />
      )}
      {showModal && <AgentsAdd open={showModal} setOpen={setShowConfig} />}
      {showDelete && currentRow && <DelConfirmComp row={currentRow} />}
    </>
  );
};

export default ContactsView;
