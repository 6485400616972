import Popover from '@mui/material/Popover';
import { MouseEvent, useMemo, useState } from 'react';
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  TextField,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ItemData, Option, BaseSelectProps } from './types';
import { SearchBox } from './SearchBox';
import List from './ListAndRow';
import { AllLabel, AllValue } from './constants';

export const BaseSelect = ({
  options,
  onChange,
  value,
  multiple,
  label,
  sx,
  enableSearch,
  renderLabel,
  disableAllOption = false,
  searchKeyword,
  onSearch,
  tokenizeSearch = true,
  listContainerSx,
}: BaseSelectProps) => {
  const [search, setSearch] = useState('');
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const _searchValue = onSearch ? searchKeyword : search;

  const open = Boolean(anchorEl);
  const isShrink = open || !!(multiple ? value?.length : value);
  const selectedValues = Array.isArray(value)
    ? value.reduce((prev, cur) => {
        prev[cur.value] = true;
        return prev;
      }, {})
    : { [value?.value || '']: true };

  const isAllSelected =
    Array.isArray(value) && value.length > 0 && value.length === options.length;

  const _onChange = (item: Option) => {
    // Handle All select
    if (item.value === AllValue) {
      if (isAllSelected) onChange([]);
      else onChange(options);
      return;
    }

    if (multiple) {
      const _value = Array.isArray(value) ? [...value] : [];
      const index = _value.findIndex((i) => i.value === item.value);
      if (index !== -1) {
        _value.splice(index, 1);
      } else {
        _value.push(item);
      }
      onChange(_value);
    } else {
      onChange(item);
    }

    if (!multiple) handleClose();
  };

  const list = useMemo(() => {
    let _list = [...options];
    if (!disableAllOption && multiple && _list.length) {
      _list.unshift({ label: AllLabel, value: AllValue });
    }

    const doSearch = () => {
      if (!search) return;

      if (tokenizeSearch) {
        const tokens = search.toLowerCase().split(/[,\s]+/);
        _list = options.filter(
          (item) =>
            !!tokens.some((token) => item.label.toLowerCase().includes(token))
        );
      } else {
        _list = options.filter((item) =>
          item.label.toLowerCase().includes(search.toLowerCase())
        );
      }
    };

    doSearch();

    return _list;
  }, [options, disableAllOption, multiple, search, tokenizeSearch]);

  const onTargetSelect = (item: Option) => {
    if (multiple) onChange([item]);
  };

  const itemData: ItemData = {
    options: list,
    onChange: _onChange,
    selectedValues,
    multiple,
    isAllSelected,
    onTargetSelect,
    renderLabel,
    disableAllOption,
  };

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const displayValue = useMemo(() => {
    if (multiple) {
      const valuesLength = (value as Option[])?.length;
      if (valuesLength === options.length) return `All (${valuesLength})`;
      else if (valuesLength) return `${valuesLength} selected`;
      return '';
    }
    return (value as Option)?.label;
  }, [multiple, options.length, value]);

  const _onSearch = (v: string) => {
    if (onSearch) onSearch(v);
    else setSearch(v);
  };

  return (
    <>
      <FormControl
        onClick={handleClick}
        sx={{ minWidth: 160, marginRight: 1, ...sx }}
      >
        <InputLabel
          sx={{
            paddingLeft: 1,
            paddingRight: 1,
            background: 'white',
            marginTop: isShrink ? 0 : '-3px',
          }}
          shrink={isShrink}
        >
          {label}
        </InputLabel>
        <TextField
          sx={{
            '& .MuiInputBase-root': {
              height: '35px',
              paddingRight: 0,
            },
            '& .MuiInputBase-input': {
              opacity: 0,
              width: 0,
              paddingRight: 0,
              height: '100%',
              py: 0,
            },
          }}
          InputProps={{
            startAdornment: (
              <Box
                sx={{
                  width: '100%',
                  cursor: 'pointer',
                  lineHeight: '40px',
                  zIndex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  alignSelf: 'stretch',
                }}
              >
                {displayValue}
              </Box>
            ),
            endAdornment: (
              <InputAdornment
                sx={{ cursor: 'pointer', flexShrink: 0, marginLeft: 1 }}
                position="start"
              >
                <ExpandMoreIcon
                  sx={{ transform: open ? 'rotate(180deg)' : 'rotate(0)' }}
                />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          sx={{
            minWidth: 300,
            width: '100%',
            ...listContainerSx,
          }}
        >
          {enableSearch && (
            <SearchBox search={_searchValue} onChange={_onSearch} />
          )}
          <List itemData={itemData} />
        </Box>
      </Popover>
    </>
  );
};
