import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { CellValueChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import CommonFormatter from 'common/Formatter';
import { cloneDeep, isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import LoadingCircle from '@/components/atoms/LoadingCircle';
import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import SearchBox from '@/components/molecules/SearchBox';
import SearchSettings from '@/components/molecules/SearchSettings';
import useSnackbar from '@/contexts/useSnackbar';
import API from '@/services/API';
import { useRoleStore } from '@/store';
import { DateRange, DateRangesTypes, Roles } from '@/types';
import CompGridBulkActions from '@/components/schedules/compGrids/CompGridViewerView/CompGridBulkActions';
import { EnhancedSelect } from '@/components/molecules/EnhancedSelect';
import ConfirmNewRatesDialog from '@/components/schedules/compGrids/CompGridViewerView/ConfirmNewRatesDialog';
import {
  CompGridRate,
  CriteriaUpdateData,
  NewRate,
} from '@/components/schedules/compGrids/CompGridViewerView/types';

const getGridLevelCols = (
  name: string,
  rate_fields: string[],
  role: Roles | null,
  editable: boolean,
  adding: boolean,
  editedCells: any = {}
) => {
  if (role === Roles.PRODUCER) {
    return [
      {
        field: `rate::${name}`,
        headerName: 'Rate',
        type: 'numericColumn',
        width: 80,
        cellStartedEdit: editable,
        editable,
        wrapHeaderText: true,
        cellClassRules: {
          'ag-cell-custom-valid': (params) =>
            editedCells[`${params.data.id}-${params.colDef.field}`] === 'valid',
          'ag-cell-custom-invalid': (params) =>
            editedCells[`${params.data.id}-${params.colDef.field}`] ===
            'invalid',
        },
      },
    ];
  }
  return [
    {
      field: `carrier_rate::${name}`,
      headerName: 'Carrier rate',
      type: 'numericColumn',
      cellDataType: 'number',
      width: 80,
      cellStartedEdit: (params) =>
        editable || (params.node.rowPinned === 'top' && adding),
      editable: (params) =>
        editable || (params.node.rowPinned === 'top' && adding),
      wrapHeaderText: true,
      hide: !rate_fields.includes('carrier_rate'),
      cellClassRules: {
        'ag-cell-custom-valid': (params) =>
          editedCells[`${params.data.id}-${params.colDef.field}`] === 'valid',
        'ag-cell-custom-invalid': (params) =>
          editedCells[`${params.data.id}-${params.colDef.field}`] === 'invalid',
      },
    },
    {
      field: `house_rate::${name}`,
      headerName: 'House rate',
      type: 'numericColumn',
      width: 80,
      cellStartedEdit: (params) =>
        editable || (params.node.rowPinned === 'top' && adding),
      editable: (params) =>
        editable || (params.node.rowPinned === 'top' && adding),
      wrapHeaderText: true,
      hide: !rate_fields.includes('house_rate'),
      cellClassRules: {
        'ag-cell-custom-valid': (params) =>
          editedCells[`${params.data.id}-${params.colDef.field}`] === 'valid',
        'ag-cell-custom-invalid': (params) =>
          editedCells[`${params.data.id}-${params.colDef.field}`] === 'invalid',
      },
    },
    {
      field: `rate::${name}`,
      headerName: 'Total rate',
      type: 'numericColumn',
      width: 80,
      cellStartedEdit: (params) =>
        editable || (params.node.rowPinned === 'top' && adding),
      editable: (params) =>
        editable || (params.node.rowPinned === 'top' && adding),
      wrapHeaderText: true,
      hide: !rate_fields.includes('total_rate'),
      cellClassRules: {
        'ag-cell-custom-valid': (params) =>
          editedCells[`${params.data.id}-${params.colDef.field}`] === 'valid',
        'ag-cell-custom-invalid': (params) =>
          editedCells[`${params.data.id}-${params.colDef.field}`] === 'invalid',
      },
    },
  ];
};

const CompGridsView = () => {
  const { data: accountSettings, isFetched: isFetchedAccountSettings } =
    API.getBasicQuery(`accounts/settings`);

  const viewSettings = accountSettings?.pages_settings?.comp_grids_schedules;
  let pageLabel = 'Comp grids';
  let readOnly = false;
  if (viewSettings?.page_label) {
    pageLabel = viewSettings?.page_label;
  }
  if (viewSettings?.read_only) {
    readOnly = viewSettings?.read_only;
  }
  if (isFetchedAccountSettings && viewSettings?.show_page === false) {
    return (
      // TODO: Remove navigate after figuring out how to handle this in router
      <Navigate to="/settings" />
    );
  }

  const initialInputRow = {
    id: undefined,
    comp_grid: null,
    company: null,
    product_type: null,
    product: null,
    compensation_type: null,
    issue_age_start: null,
    issue_age_end: null,
    premium_min: null,
    premium_max: null,
    policy_year_start: null,
    policy_year_end: null,
    str_id: undefined,
  };
  const [inputRow, _setInputRow] = useState(initialInputRow);
  const [newRowNode, setNewRowNode] = useState<any>(null);
  const [adding, setAdding] = useState(false);
  const [dropdownValues, setDropdownValues] = useState<{
    comp_grid: number[];
    comp_grid_mapping: Record<number, string>;
    product: number[];
    product_mapping: Record<number, string>;
  }>({
    comp_grid: [],
    comp_grid_mapping: { 0: 'Click to add' },
    product: [],
    product_mapping: { 0: 'Click to add' },
  });
  const [newRowValues, setNewRowValues] = useState(null);
  const [selectedCompGrid, setSelectedCompGrid] = useState<string | null>(null);
  const [searchParams, setSearchParams] = useSearchParams({});
  const [selectedCompGrids, setSelectedCompGrids] = useState<number[] | null>(
    null
  );
  const [filteredLevels, setFilteredLevels] = useState<string[]>([]);
  const [editable, setEditable] = useState(false);
  const [editedCells, setEditedCells] = useState({});
  const [editedValues, setEditedValues] = useState({});
  const [editNode, setEditNode] = useState<{ [key: string]: any[] }>({});
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [openBulkActionsDialog, setOpenBulkActionsDialog] = useState(false);
  const [selectedDateRanges, setSelectedDateRanges] = useState<any>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState<string[]>([]);
  const [selectedRatesIds, setSelectedRatesIds] = useState<string[]>([]);
  const [selectedCriteria, setSelectedCriteria] = useState<any[]>([]);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const [selectedDateFilter, setSelectedDateFilter] = useState<string>(
    searchParams.get('effective_date')
      ? 'singleDate'
      : searchParams.get('range_date')
        ? 'dateRange'
        : 'today'
  );
  const [showDateRanges, setShowDateRanges] = useState<boolean>(
    searchParams.get('incl_date_ranges') ? true : false
  );
  const [effectiveDateFilterValue, setEffectiveDateFilterValue] = useState<
    Date | undefined
  >(
    searchParams.get('effective_date')
      ? new Date(searchParams.get('effective_date')!)
      : undefined
  );

  const [dateRangeFilterValue, setDateRangeFilterValue] = useState<
    number | undefined
  >(
    searchParams.get('range_date')
      ? parseInt(searchParams.get('range_date')!)
      : undefined
  );
  const [confirmNewRates, setConfirmNewRates] = useState(false);
  const [params, setParams] = useState<any>(null);

  const gridRef = useRef<any>(null);
  const query = searchParams.get('q');
  const inclDateRanges = searchParams.get('incl_date_ranges');

  const { userRole } = useRoleStore();
  const isProducer = userRole === Roles.PRODUCER;

  const { showSnackbar } = useSnackbar();

  const toISOStringSafe = (dateValue: any): string | undefined => {
    const date = new Date(dateValue);
    return !isNaN(date.getTime()) ? date.toISOString() : undefined;
  };

  const queryString = `effective_date=${toISOStringSafe(effectiveDateFilterValue)}&range_date=${dateRangeFilterValue}`;

  const {
    data: compGrids,
    isFetched: isFetchedCompGrids,
    isLoading: isLoadingCompGrids,
    refetch: refetchCompGrids,
  } = API.getBasicQuery('comp-grids/view', queryString);

  const { data: dateRangesData } = API.getBasicQuery(
    'date-ranges',
    `type=${DateRangesTypes.ANY}${selectedCompGrids?.length && selectedCompGrids?.length !== compGrids?.length ? `&comp_grids=${selectedCompGrids?.join(',')}` : ''}`
  );
  const ratesCriteriaPatcher = API.getMutation('comp-grids/view', 'PATCH');
  const ratesBulkPatcher = API.getMutation(
    'comp-grids/rates/bulk-edit',
    'PATCH'
  );
  const ratesBulkDeleter = API.getMutation(
    'comp-grids/rates/bulk-edit',
    'DELETE'
  );
  const copyRatesPoster = API.getMutation(
    'comp-grids/rates/copy-rates',
    'POST'
  );
  const criteriaWithRatesPoster = API.getMutation(
    'comp-grids/criteria/criteria-with-rates',
    'POST'
  );

  const columnDefsBase = useMemo(() => {
    const nonNegativeValueSetter = (params) => {
      // Ensure the input value is non-negative
      if (params.newValue < 0) {
        showSnackbar('Cannot set a negative value.', 'error');
        return false;
      }

      if (params.data[params.colDef.field] !== params.newValue) {
        params.data[params.colDef.field] = params.newValue;
        return true;
      }

      return false;
    };

    return [
      {
        headerCheckboxSelection: editable,
        checkboxSelection: editable,
        hide: !editable,
        width: 30,
        pinned: 'left' as const,
        cellStyle: {
          display: 'flex',
          alignItems: 'none',
          justifyContent: 'center',
          marginLeft: 12,
          border: 'none',
        },
      },
      { field: 'id', hide: true },
      {
        field: 'comp_grid',
        headerName: 'Comp grid',
        pinned: 'left' as const,
        width: 140,
        hide: !adding,
        editable: (params) => params.node.rowPinned === 'top' && adding,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: dropdownValues.comp_grid,
        },
        refData: dropdownValues.comp_grid_mapping,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
          marginLeft: 'inherit',
          border: 'none',
        },
        onCellValueChanged: (params) => {
          setSelectedCompGrid(params.newValue);
          setNewRowNode(params);
          // Refresh the cells to reflect comp_grid changes
          params.data.company = compGrids.find(
            (grid) => grid.id === params.newValue
          )?.company.company_name;
          params.data.company_id = compGrids.find(
            (grid) => grid.id === params.newValue
          )?.company.id;
          params.data.product = '';
          params.data.product_type = '';
          params.api.refreshCells({
            rowNodes: [params.node],
            force: true,
          });
        },
      },
      {
        field: 'company',
        headerName: 'Carrier',
        pinned: 'left' as const,
        width: 140,
        tooltipValueGetter: (p) => p?.data?.company,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
          marginLeft: 'inherit',
          border: 'none',
        },
      },
      { field: 'company_id', hide: true },
      {
        field: 'product',
        headerName: 'Product',
        pinned: 'left' as const,
        width: 180,
        tooltipValueGetter: (p) => p?.data?.product,
        editable: (params) => params.node.rowPinned === 'top' && adding,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: dropdownValues.product,
        },
        refData: dropdownValues.product_mapping,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
          marginLeft: 'inherit',
          border: 'none',
        },
        onCellValueChanged: (params) => {
          params.data.product_type = compGrids
            .find((grid) => grid.id === selectedCompGrid)
            ?.comp_grid_products.find(
              (product) => product.id === params.newValue
            )?.type;
          params.api.refreshCells({
            rowNodes: [params.node],
            force: true,
          });
        },
      },
      {
        field: 'product_type',
        headerName: 'Product type',
        pinned: 'left' as const,
        width: 90,
        wrapHeaderText: true,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
          marginLeft: 'inherit',
          border: 'none',
        },
      },
      {
        field: 'policy_year_start',
        headerName: 'Policy year start',
        pinned: 'left' as const,
        width: 70,
        wrapHeaderText: true,
        editable: (params) =>
          editable || (params.node.rowPinned === 'top' && adding),
        type: 'numberColumn',
        valueSetter: nonNegativeValueSetter,
      },
      {
        field: 'policy_year_end',
        headerName: 'Policy year end',
        pinned: 'left' as const,
        width: 70,
        wrapHeaderText: true,
        editable: (params) =>
          editable || (params.node.rowPinned === 'top' && adding),
        type: 'numberColumn',
        valueSetter: nonNegativeValueSetter,
      },
      {
        field: 'issue_age_start',
        headerName: 'Age start',
        pinned: 'left' as const,
        width: 55,
        wrapHeaderText: true,
        editable: (params) =>
          editable || (params.node.rowPinned === 'top' && adding),
        type: 'numberColumn',
        valueSetter: nonNegativeValueSetter,
      },
      {
        field: 'issue_age_end',
        headerName: 'Age\nend',
        pinned: 'left' as const,
        width: 55,
        wrapHeaderText: true,
        editable: (params) =>
          editable || (params.node.rowPinned === 'top' && adding),
        type: 'numberColumn',
        valueSetter: nonNegativeValueSetter,
      },
      {
        field: 'premium_min',
        headerName: 'Premium min',
        pinned: 'left' as const,
        width: 55,
        wrapHeaderText: true,
        editable: (params) =>
          editable || (params.node.rowPinned === 'top' && adding),
        type: 'numberColumn',
      },
      {
        field: 'premium_max',
        headerName: 'Premium max',
        pinned: 'left' as const,
        width: 55,
        wrapHeaderText: true,
        editable: (params) =>
          editable || (params.node.rowPinned === 'top' && adding),
        type: 'numberColumn',
      },
      {
        field: 'compensation_type',
        headerName: 'Compensation type',
        pinned: 'left' as const,
        width: 120,
        wrapHeaderText: true,
        tooltipValueGetter: (p) => p?.data?.compensation_type,
        editable: (params) =>
          editable || (params.node.rowPinned === 'top' && adding),
      },
      {
        field: 'rates_date_ranges',
        headerName: 'Date range',
        pinned: 'left' as const,
        width: 220,
        autoHeight: true,
        hide: !showDateRanges,
        cellRenderer: (params) => {
          const { value } = params;
          if (!value) return null;
          return (
            <Box display="flex" flexDirection="column">
              {value.map((dateRange, index) => (
                <Tooltip title={dateRange}>
                  <Chip
                    key={`${index}-datechip`}
                    sx={{ m: 0.25 }}
                    label={dateRange}
                  />
                </Tooltip>
              ))}
            </Box>
          );
        },
      },
    ];
  }, [
    editable,
    adding,
    dropdownValues.comp_grid,
    dropdownValues.comp_grid_mapping,
    dropdownValues.product,
    dropdownValues.product_mapping,
    showDateRanges,
    showSnackbar,
    compGrids,
    selectedCompGrid,
  ]);

  const searchSettings = [
    {
      id: 'incl_date_ranges',
      type: 'toggle',
      label: 'Show date ranges',
    },
  ];

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const newInclDateRanges = searchParams.get('incl_date_ranges');

    setShowDateRanges(newInclDateRanges ? true : false);
  }, [location.search, inclDateRanges]);

  useEffect(() => {
    const params = new URLSearchParams(searchParams);

    const updateParams = (key: string, value: any) => {
      if (value) {
        params.set(key, toISOStringSafe(value) || '');
      } else {
        params.delete(key);
      }
    };

    if (selectedDateFilter === 'singleDate') {
      setDateRangeFilterValue(undefined);
      params.delete('range_date');
    } else if (selectedDateFilter === 'dateRange') {
      setEffectiveDateFilterValue(undefined);
      params.set('range_date', dateRangeFilterValue?.toString() || '');
      params.delete('effective_date');
    } else {
      setEffectiveDateFilterValue(undefined);
      setDateRangeFilterValue(undefined);
      params.delete('effective_date');
      params.delete('range_date');
    }

    updateParams('effective_date', effectiveDateFilterValue);

    setSearchParams(params);
  }, [selectedDateFilter, effectiveDateFilterValue, dateRangeFilterValue]);

  const filteredCompGrids = useMemo(
    () =>
      (Array.isArray(compGrids) ? compGrids : []).filter((compGrid) =>
        selectedCompGrids?.includes(compGrid.id)
      ),
    [compGrids, selectedCompGrids]
  );

  const compGridLevels = useMemo(() => {
    const uniqueLevels = [
      ...new Set(
        filteredCompGrids.map((compGrid) => compGrid?.comp_grid_levels).flat()
      ),
    ];

    // Add comp grid available rate fields to each level for showing/hiding rate columns
    return uniqueLevels.map((level) => {
      const correspondingCompGrid = filteredCompGrids.find((compGrid) =>
        compGrid?.comp_grid_levels.includes(level)
      );

      return {
        ...level,
        rate_fields: correspondingCompGrid?.rate_fields || [
          'carrier_rate',
          'house_rate',
          'total_rate',
        ],
      };
    });
  }, [filteredCompGrids]);

  // The following assumes that all levels have the same children
  const levelColsMap = useMemo(
    () =>
      new Map(
        compGridLevels?.map((compGridLevel: any) => [
          compGridLevel.name,
          {
            headerName: compGridLevel.name,
            headerClass: 'custom-cell-border',
            children: getGridLevelCols(
              compGridLevel.name,
              compGridLevel.rate_fields,
              userRole,
              editable,
              adding,
              editedCells
            ).map((col: any, index: number, array: any[]) => ({
              ...col,
              headerClass: 'custom-cell-border',
              cellClass: [index === 0 ? 'first-child-border' : '']
                .join(' ')
                .trim(),
            })),
          },
        ])
      ),
    [compGridLevels, editable, editedCells, showDateRanges]
  );

  const levelCols = useMemo(
    () =>
      Array.from(levelColsMap.values()).filter((v) =>
        filteredLevels.includes(v.headerName)
      ),
    [levelColsMap, filteredLevels]
  );

  const levelNames = useMemo(
    () => Array.from(levelColsMap.keys()),
    [levelColsMap]
  );

  useEffect(() => {
    if (Array.isArray(compGrids) && selectedCompGrids == null) {
      setSelectedCompGrids(compGrids.map((compGrid) => compGrid.id));
    }
  }, [compGrids, selectedCompGrids]);

  useEffect(() => {
    if (Array.isArray(compGridLevels) && filteredLevels?.length === 0) {
      setFilteredLevels(levelNames);
    }

    const compGridFilteredValues = searchParams
      .getAll('comp_grids')
      .map(Number);
    if (Array.isArray(compGrids))
      if (
        compGridFilteredValues.length > 0 &&
        !isEqual(compGridFilteredValues, selectedCompGrids)
      ) {
        setSelectedCompGrids(
          compGrids
            .filter((compGrid) => compGridFilteredValues.includes(compGrid.id))
            .map((compGrid) => compGrid.id)
        );
      }

    const levelFilteredValues = searchParams.getAll('levels');
    if (Array.isArray(compGridLevels))
      if (
        levelFilteredValues.length > 0 &&
        !isEqual(levelFilteredValues, filteredLevels)
      ) {
        setFilteredLevels(levelFilteredValues);
      }

    if (compGrids) {
      const uniqueCompGridsIds = [
        ...new Set<number>(compGrids.map((grid) => grid.id)),
      ];

      const uniqueCompGridsMapping = compGrids.reduce(
        (acc, grid) => {
          acc[grid.id] = grid.name;
          return acc;
        },
        { [0]: 'Click to add' } as Record<number, string>
      );

      const uniqueProductsMapping = compGrids.reduce(
        (acc, grid) => {
          grid.comp_grid_products.forEach((product) => {
            acc[product.id] = product.name;
          });
          return acc;
        },
        { 0: 'Click to add' } as Record<number, string>
      );

      setDropdownValues((prevValues) => ({
        ...prevValues,
        comp_grid: uniqueCompGridsIds,
        comp_grid_mapping: uniqueCompGridsMapping,
        product_mapping: uniqueProductsMapping,
      }));
    }
  }, [
    compGrids,
    isLoadingCompGrids,
    compGridLevels,
    selectedCompGrids,
    searchParams,
    filteredLevels,
    levelNames,
  ]);

  useEffect(() => {
    // When adding a new row, set the carrier and products dropdowns to the selected comp grid data
    if (selectedCompGrid) {
      const filteredGrids = compGrids.filter(
        (grid) => grid.id === selectedCompGrid
      );

      const uniqueProducts = [
        ...new Set<number>(
          filteredGrids.flatMap((grid) =>
            grid.comp_grid_products.map((product) => product.id)
          )
        ),
      ];

      setDropdownValues((prevValues) => ({
        ...prevValues,
        product: uniqueProducts,
      }));
    }
  }, [selectedCompGrid, compGrids]);

  const columnDefs = [...columnDefsBase, ...levelCols];

  const rowList = filteredCompGrids
    .map((compGrid) => compGrid.comp_grid_criteria)
    .flat()
    ?.filter((datum) => {
      if (!query) return true;
      const searchFields = [
        datum.comp_grid_product.name,
        datum.comp_grid_product.type,
        datum.company.company_name,
        datum.compensation_type,
      ];
      return searchFields.join(' ').toLowerCase().includes(query.toLowerCase());
    });

  const filteredData = rowList?.map((compGridCriterion) => {
    const datum = {
      id: compGridCriterion.id,
      comp_grid: filteredCompGrids
        .filter((compGrid) =>
          compGrid.comp_grid_criteria.some(
            (criteria) => criteria.id === compGridCriterion.id
          )
        )
        .map((compGrid) => compGrid.id)[0],
      company: compGridCriterion.company?.company_name,
      company_id: compGridCriterion.company?.id,
      product_type: compGridCriterion.comp_grid_product?.type,
      product: compGridCriterion.comp_grid_product?.id,
      compensation_type: compGridCriterion.compensation_type,
      issue_age_start: compGridCriterion.issue_age_start
        ? Number(compGridCriterion.issue_age_start)
        : null,
      issue_age_end: compGridCriterion.issue_age_end
        ? Number(compGridCriterion.issue_age_end)
        : null,
      premium_min: compGridCriterion.premium_min
        ? Number(compGridCriterion.premium_min)
        : null,
      premium_max: compGridCriterion.premium_max
        ? Number(compGridCriterion.premium_max)
        : null,
      policy_year_start: compGridCriterion.policy_year_start
        ? Number(compGridCriterion.policy_year_start)
        : null,
      policy_year_end: compGridCriterion.policy_year_end
        ? Number(compGridCriterion.policy_year_end)
        : null,
      str_id: compGridCriterion.str_id,
    };
    compGridCriterion.comp_grid_rates?.forEach((rate) => {
      const compGridLevel = rate.comp_grid_level.name;
      if (!isProducer) {
        datum[`carrier_rate::${compGridLevel}`] = Number(rate.carrier_rate);
        datum[`house_rate::${compGridLevel}`] = Number(rate.house_rate);
      }
      datum[`rate::${compGridLevel}`] = Number(rate.rate);
      datum[`rate_id::${compGridLevel}`] = rate.id;
    });

    datum['rates_date_ranges'] = [];
    compGridCriterion.comp_grid_rates[0]?.date_ranges?.forEach((dateRange) => {
      const dateRangeName = dateRange.name;
      if (dateRangeName) {
        datum['rates_date_ranges'].push(
          `${dateRangeName}: (${CommonFormatter.dateRange(dateRange.start_date, dateRange.end_date)})`
        );
      } else {
        datum['rates_date_ranges'].push(
          `${CommonFormatter.dateRange(dateRange.start_date, dateRange.end_date)}`
        );
      }
    });
    return datum;
  });

  // Merge filteredData and editedValues to show edited values
  useEffect(() => {
    const editKeys = Object.keys(editedValues);
    if (filteredData && filteredData.length && editKeys.length) {
      editKeys.forEach((key) => {
        const [rowIndex, _key] = key.split(';;');
        const node = filteredData.find(
          (row) => row.id === parseInt(rowIndex, 10)
        );

        if (node) {
          node[_key] = editedValues[key];
        }
      });
    }
    gridRef.current?.api?.refreshCells();

    if (filteredData) {
      const allColumnIds: string[] = [];
      gridRef.current?.api?.getColumns().forEach((column) => {
        const columnId = column.getId();
        if (columnId && columnId.includes('rate::')) {
          allColumnIds.push(columnId);
        }
      });
      gridRef.current?.api?.autoSizeColumns(allColumnIds, false);
    }
  }, [editedValues, filteredData]);

  const onCellValueChanged = useCallback(
    (params: CellValueChangedEvent) => {
      const { column, data, newValue } = params;

      if (params.rowPinned === 'top') setNewRowValues(data);

      if (newValue !== params.oldValue) {
        const _key = column.getId();
        const isValid = !isNaN(newValue);
        setEditedCells((prev) => ({
          ...prev,
          [`${data.id};;${_key}`]: isValid ? 'valid' : 'invalid',
        }));
        setEditedValues((prev) => ({
          ...prev,
          [`${data.id};;${_key}`]: newValue,
        }));

        const rowNode = rowList.find((row) => row.id === data.id);

        const [_fieldKey, _levelName] = _key.split('::');

        if (!rowNode) {
          return;
        }
        const rowNodeRates = rowNode.comp_grid_rates.filter((r) => {
          return r.comp_grid_level.name === _levelName;
        });

        let targetNodes = editNode[rowNode.str_id];

        if (!targetNodes) {
          const newNodes = cloneDeep(editNode);
          newNodes[rowNode.str_id] = rowNodeRates.flat();
          setEditNode(newNodes);
          targetNodes = newNodes[rowNode.str_id];
        }

        const copyNodes = cloneDeep(targetNodes);
        const nodeIndex = copyNodes.findIndex((r) => {
          return r.comp_grid_level?.name === _levelName;
        });

        if (nodeIndex > -1) {
          if (rowNodeRates.length > 0) {
            copyNodes[nodeIndex] = rowNodeRates;
          }
        } else {
          copyNodes.push({
            rowNode,
            key: _key,
            newValue: newValue,
          });
        }

        const newNodes = cloneDeep(editNode);
        newNodes[rowNode.str_id] = copyNodes.flat();

        setEditNode(newNodes);
      }
    },
    [editNode, rowList]
  );

  const handleNewRatesConfirm = async () => {
    if (params) {
      saveData(params);
    }
  };

  const onSave = async () => {
    if (!editable) {
      setEditable(true);
      return;
    }

    gridRef.current.api.stopEditing();
    // Prepare data to save
    const changeObj = {};
    const _editedValues = cloneDeep(editedValues);
    let _editNode = cloneDeep(editNode);
    // get the focused cell
    const focusedCell = gridRef.current.api.getFocusedCell();
    if (focusedCell) {
      const { rowIndex, column } = focusedCell;
      const {
        colDef: { field },
      } = column;
      const key = `${rowIndex};;${field}`;
      // get the cell node
      const node = gridRef.current.api.getDisplayedRowAtIndex(rowIndex);
      if (node && _editedValues[key] === undefined) {
        _editedValues[key] = node.data[field];
        // update editedNodes
        const [_fieldKey, _levelName] = field.split('::');
        const rowNode = rowList.find((row) => row.id === node.data.id);
        const rowNodeRates = rowNode.comp_grid_rates.filter((r) => {
          return r.comp_grid_level.name === _levelName;
        });
        const targetNodes = editNode[rowNode.str_id];

        if (targetNodes) {
          const copyNodes = cloneDeep(targetNodes);
          const nodeIndex = copyNodes.findIndex((r) => {
            return r.comp_grid_level?.name === _levelName;
          });
          if (nodeIndex > -1) {
            copyNodes[nodeIndex] = rowNodeRates;
          } else {
            copyNodes.push(...rowNodeRates);
          }

          const newNodes = cloneDeep(editNode);
          newNodes[rowNode.str_id] = copyNodes.flat();

          _editNode = newNodes;
        } else {
          const newNodes = cloneDeep(editNode);
          newNodes[rowNode.str_id] = rowNodeRates.flat();
          _editNode = newNodes;
        }
      }
    }

    Object.keys(_editedValues).forEach((key) => {
      const rowIndex = key.split(';;')[0];
      let _key = key.split(';;')[1];

      if (!_key.includes('::')) {
        _key += '::criteria';
      }

      const [field, level] = _key.split('::');
      const node = filteredData.find(
        (row) => row.id === parseInt(rowIndex, 10)
      );
      if (node) {
        if (!changeObj[node['str_id']]) {
          changeObj[node['str_id']] = {
            [level]: {
              [field]: _editedValues[key],
            },
          };
        } else {
          if (!changeObj[node['str_id']][level]) {
            changeObj[node['str_id']][level] = {
              [field]: _editedValues[key],
            };
          } else {
            changeObj[node['str_id']][level][field] = _editedValues[key];
          }
        }
      }
    });

    // Find rates by str_id
    const changeEntries = Object.entries(changeObj) as [string, any];
    const changedRates: CompGridRate[] = [];
    const changedCriteria: CriteriaUpdateData[] = [];
    const newRates: NewRate[] = [];

    for (const [strId, item] of changeEntries) {
      const rowItems = _editNode[strId];
      if (!rowItems) {
        continue;
      }
      const tempCriteriaItem = item.criteria;
      if (tempCriteriaItem) {
        const criteria = {
          str_id: strId,
          ...tempCriteriaItem,
        };
        changedCriteria.push(criteria);
      }

      for (let rate of rowItems) {
        const rateLevelKey = rate.comp_grid_level?.name;
        const tempPreItem = item[rateLevelKey];

        if (tempPreItem) {
          rate = {
            ...rate,
            ...tempPreItem,
          };
          changedRates.push(rate);
        } else {
          // New rate
          const [, level] = rate.key.split('::'); // TODO: We need to refactor how the columns keys are created to avoid this
          const dateRanges = rowList
            .filter((row) => row.comp_grid_id === rate.rowNode.comp_grid_id)
            .flatMap((row) =>
              row.comp_grid_rates
                .filter((rate) => rate.comp_grid_level.name === level)
                .map((rate) => rate.date_ranges)
            );

          const newRate = {
            item: item[level],
            level_name: level,
            rate_value: rate.newValue,
            comp_grid_id: rate.rowNode.comp_grid_id,
            comp_grid_criterion_id: rate.rowNode.id,
          };
          newRates.push(newRate);
        }
      }
    }
    const uniqueNewRates = newRates.filter(
      (rate, index, self) =>
        index ===
        self.findIndex(
          (r) =>
            r.level_name === rate.level_name &&
            r.comp_grid_id === rate.comp_grid_id
        )
    );
    const newRatesParams = uniqueNewRates.map((rate) => {
      return {
        rates: rate.item,
        level_name: rate.level_name,
        comp_grid_id: rate.comp_grid_id,
        comp_grid_criterion_id: rate.comp_grid_criterion_id,
      };
    });

    const ratesParams = changedRates.map((rate) => {
      return {
        id: rate.id,
        carrier_rate: rate.carrier_rate,
        house_rate: rate.house_rate,
        rate: rate.rate,
        comp_grid_level_id: rate.comp_grid_level_id,
        comp_grid_criterion_id: rate.comp_grid_criterion_id,
        date_ranges: rate.date_ranges,
      };
    });

    const dataParams = {
      rates: ratesParams,
      criteria: changedCriteria,
      new_rates: newRatesParams,
    };

    if (newRatesParams.length > 0) {
      setParams(dataParams);
      setConfirmNewRates(true);
      return;
    }

    if (!dataParams.rates.length && !dataParams.criteria.length) {
      setEditable(false);
      return;
    }

    saveData(dataParams);
  };

  const saveData = async (params) => {
    setLoading(true);
    try {
      await ratesCriteriaPatcher.mutateAsync(params);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
    setLoading(false);
    setConfirmNewRates(false);
    // Toggle editable
    clear();
    // Refetch data
    refetchCompGrids();
  };

  const handleDateRangeChange = (index, newDateRange) => {
    const updatedRates = [...params.new_rates];
    updatedRates[index].date_range = newDateRange;
    setParams({ ...params, new_rates: updatedRates });
  };

  const clear = () => {
    setEditedCells({});
    setEditedValues({});
    setEditable(false);
    setEditNode({});
  };

  const handleRowSelected = (event) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    setSelectedRows(selectedData);
  };

  useEffect(() => {
    if (selectedRows.length > 0) {
      const levelsSet = new Set<string>();
      const ratesIdsSet = new Set<string>();
      const _selectedCriteria: any = [];
      selectedRows.forEach((row: any, index) => {
        _selectedCriteria[index] = {
          comp_grid_product: { type: row.product_type, name: row.product },
          policy_year_start: row.policy_year_start,
          policy_year_end: row.policy_year_end,
          issue_age_start: row.issue_age_start,
          issue_age_end: row.issue_age_end,
          compensation_type: row.compensation_type,
        };
        Object.keys(row).forEach((key) => {
          const [field, level] = key.split('::');
          if (level) {
            levelsSet.add(level);
          }
          if (field === 'rate_id') {
            ratesIdsSet.add(row[key]);
          }
        });
      });
      setSelectedLevels(Array.from(levelsSet));
      setSelectedRatesIds(Array.from(ratesIdsSet));
      setSelectedCriteria(_selectedCriteria);
    }
  }, [selectedRows]);

  const bulkEditRates = async () => {
    setLoading(true);
    try {
      const params = {
        ids: selectedRatesIds,
        date_ranges: selectedDateRanges.date_ranges,
      };
      const res = await ratesBulkPatcher.mutateAsync(params);
      if (res.error) {
        showSnackbar(
          `An error occurred when updating date range: ${res.error}`,
          'error'
        );
      } else {
        showSnackbar('Rates updated.', 'success');
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
    setSelectedDateRanges([]);
    setLoading(false);
    clear();
    refetchCompGrids();
    setOpenBulkActionsDialog(false);
  };

  const bulkDeleteRates = async () => {
    setLoading(true);
    try {
      const params = {
        ids: selectedRatesIds,
      };
      const res = await ratesBulkDeleter.mutateAsync(params);
      if (res.error) {
        showSnackbar(
          `An error occurred when deleting rates: ${res.error}`,
          'error'
        );
      } else {
        showSnackbar('Rates deleted.', 'success');
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
    setSelectedDateRanges([]);
    setLoading(false);
    clear();
    refetchCompGrids();
    setShowConfirmDeleteDialog(false);
  };

  const bulkCopyRates = async () => {
    setLoading(true);
    try {
      const params = {
        ids: selectedRatesIds,
        date_ranges: selectedDateRanges.date_ranges,
      };
      const res = await copyRatesPoster.mutateAsync(params);
      if (res.errors) {
        showSnackbar(
          `An error occurred when updating date range: ${res.errors[0].error}`,
          'error'
        );
      } else {
        showSnackbar('Rates copied.', 'success');
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
    setSelectedDateRanges([]);
    setLoading(false);
    clear();
    refetchCompGrids();
    setOpenBulkActionsDialog(false);
  };

  const onCreate = async () => {
    if (!adding) {
      setAdding(true);
      return;
    }

    setAddLoading(true);

    if (newRowValues) {
      const response = await criteriaWithRatesPoster.mutateAsync(newRowValues);
      if (response.data) {
        showSnackbar('New row added successfully', 'success');
        if (newRowNode) {
          // Clean up the new row
          Object.keys(initialInputRow).forEach((key) => {
            newRowNode.data[key] = initialInputRow[key];
          });
          Object.keys(newRowNode.data).forEach((key) => {
            if (!(key in initialInputRow)) {
              newRowNode.data[key] = null;
            }
          });

          newRowNode.api.refreshCells({
            rowNodes: [newRowNode.node],
            force: true,
          });
        }
        refetchCompGrids();
      } else {
        showSnackbar('An error occurred while adding new row', 'error');
      }
    }
    setAdding(false);
    setAddLoading(false);
  };

  // Function to provide a unique ID for each row, this allows to mantain selected rows on re-render
  const getRowId = useMemo(() => (params) => String(params.data.id), []);

  // Custom styles for add new top row
  const getRowHeight = (params) => {
    if (params.node.rowPinned === 'top') {
      return 55;
    }
    return 40;
  };

  const compGridOptions = Array.isArray(compGrids)
    ? compGrids.map((compGrid) => ({
        id: compGrid.id,
        name: compGrid.name,
      }))
    : [];

  if (!isFetchedAccountSettings) return <LoadingCircle />;

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <style>
        {`
          .ag-cell-custom-valid {
            background-color: #eaf5fd !important;
            font-weight: bold;
          }
          .ag-cell-custom-invalid {
            border-color: #f60 !important;
            font-weight: bold;
          }
        `}
      </style>
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5" sx={{ whiteSpace: 'nowrap' }}>
            {pageLabel}
          </Typography>
          <Box sx={{ display: 'flex', mx: 1 }}>
            <EnhancedSelect
              enableSearch
              label="Comp grids"
              sx={{ width: 'auto', mr: 1 }}
              options={compGridOptions}
              value={compGridOptions.filter((item) =>
                selectedCompGrids?.includes(item.id)
              )}
              multiple
              onChange={(v) => {
                const ids: number[] = v?.map((item) => item.id) || [];
                setSelectedCompGrids(ids);
                setSearchParams((prev) => {
                  prev.delete('comp_grids');
                  if (
                    Array.isArray(compGrids) &&
                    compGrids.length !== ids.length
                  ) {
                    ids.forEach((value) => {
                      prev.append('comp_grids', value.toString());
                    });
                  }
                  return prev;
                });
              }}
            />

            <EnhancedSelect
              enableSearch
              multiple
              label="Levels"
              options={levelNames}
              value={filteredLevels.filter((item) =>
                Object.values(levelNames).includes(item)
              )}
              onChange={(values) => {
                setFilteredLevels(values);
                setSearchParams((prev) => {
                  prev.delete('levels');
                  values.forEach((value) => {
                    if (
                      Array.isArray(levelNames) &&
                      levelNames.length !== values.length
                    ) {
                      prev.append('levels', value);
                    }
                  });
                  return prev;
                });
              }}
            />

            <FormControl sx={{ ml: 1, minWidth: 120 }}>
              <InputLabel>Date</InputLabel>
              <Select
                value={selectedDateFilter}
                label="Date"
                onChange={(e) => setSelectedDateFilter(e.target.value)}
                sx={{
                  '.MuiSelect-select': {
                    py: 0.75,
                    px: 1.5,
                    minWidth: 120,
                  },
                }}
              >
                <MenuItem value="today">Today</MenuItem>
                <MenuItem value="singleDate">Date</MenuItem>
                <MenuItem value="dateRange">Date range</MenuItem>
              </Select>
            </FormControl>
            {selectedDateFilter === 'singleDate' && (
              <BasicDatePicker
                label="Effective date"
                value={effectiveDateFilterValue}
                setValue={(e) => setEffectiveDateFilterValue(e)}
                sx={{ ml: 1, minWidth: 152 }}
              />
            )}
            {selectedDateFilter === 'dateRange' && (
              <>
                <FormControl sx={{ ml: 1, mr: 1, minWidth: 148 }}>
                  <InputLabel sx={{ lineHeight: 1 }}>Date range</InputLabel>
                  <Select
                    value={dateRangeFilterValue ?? ''}
                    label="Date range"
                    onChange={(e) => {
                      setDateRangeFilterValue(
                        parseInt(e.target.value as string)
                      );
                    }}
                    sx={{
                      '.MuiSelect-select': { py: 0.75, px: 1.5 },
                    }}
                  >
                    {dateRangesData?.map((dateRange: DateRange) => (
                      <MenuItem key={dateRange.id} value={dateRange.id}>
                        {dateRange.name
                          ? `${dateRange.name}: ${CommonFormatter.dateRange(dateRange.start_date, dateRange.end_date)}`
                          : `${CommonFormatter.dateRange(dateRange.start_date, dateRange.end_date)}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
          </Box>
          <Box>
            <Box sx={{ display: 'flex' }}>
              <SearchBox id="comp_grids" />
              <SearchSettings settings={searchSettings} />
              {!readOnly && (
                <>
                  {editable && (
                    <>
                      <Button
                        sx={{ ml: 3 }}
                        variant="outlined"
                        disabled={selectedRows.length === 0}
                        onClick={() => setOpenBulkActionsDialog(true)}
                      >
                        Bulk actions
                      </Button>
                      <Button sx={{ ml: 1 }} onClick={clear}>
                        Cancel
                      </Button>
                    </>
                  )}
                  <LoadingButton
                    variant="contained"
                    sx={{ ml: 1 }}
                    onClick={onSave}
                    loading={loading}
                  >
                    {editable ? 'Save' : 'Edit'}
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    sx={{ ml: 1 }}
                    onClick={onCreate}
                    loading={addLoading}
                  >
                    {adding ? 'Save' : 'Add'}
                  </LoadingButton>
                </>
              )}
            </Box>
            {editable && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  mt: 1,
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    backgroundColor: '#ffffaa',
                    p: 0.5,
                    pb: 0.25,
                    lineHeight: 'unset',
                    borderRadius: 2,
                  }}
                >
                  ℹ️ Click rates to edit
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={{ flex: 1 }} className="ag-theme-material">
        {!isLoadingCompGrids ? (
          <AgGridReact
            pinnedTopRowData={adding ? [inputRow] : undefined}
            headerHeight={40}
            columnDefs={columnDefs}
            rowData={filteredData}
            suppressHorizontalScroll={false}
            alwaysShowHorizontalScroll={true}
            debounceVerticalScrollbar={true}
            ref={gridRef}
            onCellValueChanged={onCellValueChanged}
            singleClickEdit={true}
            tooltipShowDelay={500}
            rowSelection={editable ? 'multiple' : undefined}
            onRowSelected={handleRowSelected}
            suppressRowClickSelection={true}
            getRowId={getRowId}
            gridOptions={{
              suppressMovableColumns: true,
            }}
            getRowHeight={getRowHeight}
          />
        ) : (
          <LoadingCircle />
        )}
      </Box>
      <CompGridBulkActions
        openBulkActionsDialog={openBulkActionsDialog}
        setOpenBulkActionsDialog={setOpenBulkActionsDialog}
        selectedCriteria={selectedCriteria}
        selectedLevels={selectedLevels}
        selectedDateRanges={selectedDateRanges}
        setSelectedDateRanges={setSelectedDateRanges}
        loading={loading}
        bulkEditRates={bulkEditRates}
        bulkCopyRates={bulkCopyRates}
        bulkDeleteRates={bulkDeleteRates}
        showConfirmDeleteDialog={showConfirmDeleteDialog}
        setShowConfirmDeleteDialog={setShowConfirmDeleteDialog}
      />
      <ConfirmNewRatesDialog
        open={confirmNewRates}
        onClose={() => setConfirmNewRates(false)}
        params={params}
        compGrids={compGrids}
        selectedDateRanges={selectedDateRanges}
        handleNewRatesConfirm={handleNewRatesConfirm}
        handleDateRangeChange={handleDateRangeChange}
      />
    </Box>
  );
};

export default CompGridsView;
