import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import { AgGridReact } from 'ag-grid-react';

const ResultTable = ({ headers, rows }) => {
  const columnDefs = useMemo(
    () =>
      (headers || []).map((header) => ({
        field: header,
        // sortable: true,
        // filter: true,
        // editable: true,
      })),
    [headers]
  );

  const rowDataDef = useMemo(
    () =>
      (rows || []).map((row) => {
        const obj = {};
        headers.forEach((header, i) => {
          obj[header] = row[i];
        });
        return obj;
      }),
    [headers, rows]
  );

  return (
    <Box
      sx={{ overflowX: 'auto', width: '100%', height: '100%' }}
      className="ag-theme-material"
    >
      <AgGridReact
        headerHeight={40}
        rowData={rowDataDef}
        columnDefs={columnDefs}
      />
    </Box>
  );
};

export default ResultTable;
