import { parseDate as _parseDate } from 'chrono-node';

/**
 * Parse date string (with natural language) to date object
 * @param dateStr date string
 * @param format date format
 * @returns Date object | string | null
 */
const parseDate = (dateStr: string, format?: string) => {
  if (!dateStr) return null;
  const date = _parseDate(dateStr);

  if (format) {
    const dateStr = date?.toLocaleDateString(format);
    return dateStr;
  }
  return date;
};

export const desc_parseDate = {
  name: 'libs.tools.parseDate(dateStr, format)',
  description:
    'Parses date strings including natural language into Date objects or formatted strings.',
  arguments: {
    dateStr: '[String] Date string to parse',
    format: '[String] Optional. Format for output date string',
  },
  returns:
    '[Date|String|null] Date object, formatted date string, or null if invalid',
  examples:
    'parseDate("Today is May 25, 2024")\n// → Date object\n\nparseDate("Today is May 25, 2024", "en")\n// → "05/25/2024"\n\nparseDate("2021-01-01")\n// → Date object\n',
};

export default parseDate;
