import { useMemo } from 'react';

import { Option, SelectProps } from './types';
import { BaseSelect } from './BaseSelect';
import { convertObjectToOption, convertOptionToValue } from './helpers';

export const ObjectSelect = <T, K extends boolean = false>({
  options,
  valueKey = 'id',
  labelKey = 'name',
  onChange,
  multiple,
  value,
  ...rest
}: SelectProps<T, K>) => {
  const _options = useMemo(() => {
    return options.map((item) =>
      convertObjectToOption(item, valueKey, labelKey)
    ) as Option[];
  }, [labelKey, options, valueKey]);

  const _onChange = (item: Option | Option[]) => {
    let newValue: T | T[];
    if (Array.isArray(item)) {
      newValue = item.map((item) => {
        return convertOptionToValue(item, valueKey, labelKey);
      }) as T[];
    } else {
      newValue = convertOptionToValue(item, valueKey, labelKey) as T;
    }

    onChange(newValue as K extends false ? T : T[]);
  };

  const _value: Option | Option[] | undefined = useMemo(() => {
    if (Array.isArray(value))
      return value.map((item) =>
        convertObjectToOption(item, valueKey, labelKey)
      ) as Option[];

    return convertObjectToOption(value, valueKey, labelKey);
  }, [labelKey, value, valueKey]);

  return (
    <BaseSelect
      multiple={multiple}
      value={_value}
      options={_options}
      onChange={_onChange}
      {...rest}
    />
  );
};
