import { CSSProperties, memo } from 'react';
import { areEqual, FixedSizeList } from 'react-window';
import { Box, Checkbox, colors, IconButton, Typography } from '@mui/material';
import GpsFixedOutlined from '@mui/icons-material/GpsFixedOutlined';

import { ItemData } from './types';
import { AllValue } from './constants';

const ROW_HEIGHT = 40;
const Row = memo(
  ({
    index,
    style,
    data,
  }: {
    index: number;
    style: CSSProperties;
    data: ItemData;
  }) => {
    const item = data.options[index];
    const isAll = item.value === AllValue;
    const isSelected = !!(item.value === AllValue
      ? data.isAllSelected
      : data.selectedValues[item.value]);

    const renderLabel = data.renderLabel && !isAll ? data.renderLabel : null;

    return (
      <Box
        onClick={() => data.onChange(item)}
        style={style}
        sx={{
          background: isSelected ? 'rgba(33, 150, 243, 0.12)' : 'white',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          '&:hover': {
            background: 'rgba(33, 150, 243, 0.12)',
            '& .target-select-btn': {
              visibility: 'visible',
            },
          },
        }}
      >
        {data.multiple && <Checkbox checked={isSelected} />}
        {renderLabel ? (
          renderLabel({
            isSelected,
            index: data.disableAllOption ? index : index - 1,
            sx: { flexGrow: 1 },
          })
        ) : (
          <Typography sx={{ flexGrow: 1, paddingLeft: !data.multiple ? 2 : 0 }}>
            {item.label}
          </Typography>
        )}
        {data.multiple && item.value !== AllValue && (
          <IconButton
            className="target-select-btn"
            sx={{
              marginRight: 1,
              opacity: 0.3,
              visibility: 'hidden',
              color: colors.blue['600'],
              '&:hover': { opacity: 1 },
            }}
            onClick={(evt) => {
              evt.stopPropagation();
              data.onTargetSelect(item);
            }}
          >
            <GpsFixedOutlined sx={{ width: 16, height: 16 }} />
          </IconButton>
        )}
      </Box>
    );
  },
  areEqual
);

export default function List({ itemData }: { itemData: ItemData }) {
  return (
    <FixedSizeList
      itemData={itemData}
      height={ROW_HEIGHT * Math.min(itemData.options.length, 10)}
      itemCount={itemData.options.length}
      itemSize={40}
      width={'100%'}
      itemKey={(index, _data) => _data.options[index].value}
    >
      {Row}
    </FixedSizeList>
  );
}
