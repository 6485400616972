import { DataEntities } from 'common/globalTypes';

export type Field = Record<string, any>;

export interface Fields {
  [DataEntities.COMMISSIONS]: Field[];
  [DataEntities.POLICIES]: Field[];
}

export interface ActionObject {
  field: string;
  value: string;
  action: string;
}

export enum DataUpdateCopyTypes {
  'ACTIONS' = 'actions',
  'CRITERIA' = 'criteria',
  'CONFIG' = 'config',
}

export interface DataUpdateActionsObject {
  access: string;
  data_entity: DataEntities;
  data_update_actions: ActionObject;
  id: number;
  name: string;
  notes: string;
  str_id: string;
}
