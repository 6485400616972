import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Formatter from 'common/Formatter';
import { useEffect, useMemo, useState } from 'react';

import { FilterSelect } from '@/common';
import useSnackbar from '@/contexts/useSnackbar';
import API from '@/services/API';
import { useAccountStore } from '@/store';
import {
  DocumentTypeCreatedType,
  DocumentTypeE,
  DocumentTypesPriority,
  PriorityList,
} from './enum';

const ActionModal = ({
  open,
  handleCancel,
  rowData,
  companies,
  owners,
  processors,
  mappings,
}) => {
  const [uploading, setUploading] = useState(false);
  const [formData, setFormData] = useState<Partial<DocumentTypeE>>({
    carrier_name: '',
    paying_entity: '',
    owner: '',
    mapping_id: '',
    status: 'draft',
    notes: '',
    priority: DocumentTypesPriority.medium,
    processor_str_ids: [],
  });
  const [selectedProcessors, setSelectedProcessors] = useState<
    {
      label: string;
      value: string;
      data: any;
    }[]
  >([]);

  const { selectedAccount } = useAccountStore();
  const { showSnackbar } = useSnackbar();

  const statusList = useMemo(
    () => [
      {
        label: 'Draft',
        value: 'draft',
      },
      {
        label: 'In review',
        value: 'in_review',
      },
      {
        label: 'Need update',
        value: 'need_update',
      },
      {
        label: 'Approved',
        value: 'approved',
      },
    ],
    []
  );

  const documentProfilePoster = API.getMutation('document_profiles', 'POST');
  const documentProfilePutter = API.getMutation('document_profiles', 'PUT');

  useEffect(() => {
    if (rowData) {
      const {
        name,
        carrier_name,
        paying_entity,
        owner,
        mapping_id,
        status,
        notes,
        priority,
        processor_str_ids,
      } = rowData;
      setFormData({
        name,
        carrier_name,
        paying_entity,
        owner,
        mapping_id,
        status,
        notes,
        priority,
        processor_str_ids,
      });
      if (processor_str_ids && processor_str_ids.length) {
        const list = processors.filter((item) =>
          processor_str_ids.includes(item.str_id)
        );
        const res = list.map((item) => {
          return {
            label: item.name,
            value: item.str_id,
            data: item,
          };
        });
        setSelectedProcessors(res);
      }
    }
  }, [rowData]);

  useEffect(() => {
    if (selectedProcessors.length) {
      const list = selectedProcessors.map((val) => val.value);
      onFormChange('processor_str_ids', list);
    }
  }, [selectedProcessors]);

  const onSubmit = async () => {
    setUploading(true);
    // if has row data, then it's an update
    const api = rowData ? documentProfilePutter : documentProfilePoster;
    const str_id = rowData ? rowData.str_id : undefined;
    const id = rowData ? rowData.id : undefined;
    const poster = {
      ...formData,
      paying_entity: formData.carrier_name,
      create_type: DocumentTypeCreatedType.manully,
    };
    const body = {
      ...poster,
      str_id,
      id,
      document_str_ids: rowData.document_str_ids,
      create_type: rowData.create_type,
    };
    try {
      const result = await api.mutateAsync(rowData ? body : poster);
      setUploading(false);
      if (result.error) {
        showSnackbar(result.error, 'error');
        return;
      }
      if (result) {
        handleCancel();
      }
    } catch (error) {
      setUploading(false);
    }
  };

  const onFormChange = (key: keyof DocumentTypeE, value: any) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth
      sx={{ background: 'transparent', p: 1 }}
      onClose={handleCancel}
      disableEscapeKeyDown
    >
      <DialogTitle>
        <Box className="flex items-center">Document profile</Box>
      </DialogTitle>
      <IconButton
        className="group absolute p-4 right-0 top-0 cursor-pointer hover:text-blue-600"
        onClick={handleCancel}
      >
        <Close className="group-hover:rotate-180 transition-all origin-center" />
      </IconButton>
      <Divider />

      <DialogContent
        sx={{
          p: 0,
          backgroundColor: '#fff',
          borderRadius: '4px',
        }}
      >
        <Box sx={{ width: '100%', height: '600px', p: 4 }}>
          <FormControl fullWidth sx={{ minWidth: 100, mb: 2 }}>
            <TextField
              label="Name"
              variant="outlined"
              multiline
              minRows={1}
              value={formData.name}
              onChange={(e) => {
                onFormChange('name', e.target.value);
              }}
            />
          </FormControl>

          <FormControl fullWidth sx={{ minWidth: 100, mb: 2 }}>
            <InputLabel>Carrier</InputLabel>
            <Select
              id="select-sheet"
              value={formData.carrier_name}
              label="Carrier"
              disabled
              onChange={(e) => {
                onFormChange('carrier_name', e.target.value);
              }}
            >
              {(companies || []).map((c) => (
                <MenuItem value={c.str_id} key={c.str_id}>
                  {c.company_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ minWidth: 100, mb: 2 }}>
            <InputLabel>Owner</InputLabel>
            <Select
              id="select-sheet"
              value={formData.owner}
              label="Owner"
              onChange={(e) => {
                onFormChange('owner', e.target.value);
              }}
            >
              {(owners || []).map((c) => (
                <MenuItem value={c.uid} key={c.uid}>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: 1,
                    }}
                  >
                    <Box sx={{ flex: 1 }}>
                      {Formatter.contact(c, {
                        account_id: selectedAccount?.accountId,
                      })}
                    </Box>
                    <Box sx={{ color: '#666' }}>{c.email}</Box>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ minWidth: 100, mb: 2 }}>
            <InputLabel>Status</InputLabel>
            <Select
              id="select-sheet"
              value={formData.status}
              label="Status"
              onChange={(e) => {
                onFormChange('status', e.target.value);
              }}
            >
              {statusList.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FilterSelect
            id="processor"
            lable="Select processors"
            sx={{ minWidth: 100, mb: 2 }}
            labelKey="name"
            valueKey="str_id"
            placeholder="Processors"
            options={processors}
            value={selectedProcessors}
            onChange={setSelectedProcessors}
            multiple
            getOptionLabel={(item) => {
              return item.label || item.name || item;
            }}
            renderOptionItem={(item) => {
              return item.name;
            }}
          />

          <FormControl fullWidth sx={{ minWidth: 100, mb: 2 }}>
            <InputLabel>Priority</InputLabel>
            <Select
              id="select-priority"
              value={formData.priority}
              label="Priority"
              onChange={(e) => {
                onFormChange('priority', e.target.value);
              }}
            >
              {PriorityList.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ minWidth: 100, mb: 2 }}>
            <InputLabel>Mapping</InputLabel>
            <Select
              id="select-sheet"
              value={formData.mapping_id}
              label="Mapping"
              onChange={(e) => {
                onFormChange('mapping_id', e.target.value);
              }}
            >
              {(mappings || []).map((c) => (
                <MenuItem value={c.str_id} key={c.str_id}>
                  <Box>{c.name}</Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ minWidth: 100, mb: 2 }}>
            <TextField
              label="Notes"
              multiline
              minRows={2}
              variant="outlined"
              value={formData.notes}
              onChange={(e) => {
                onFormChange('notes', e.target.value);
              }}
            />
          </FormControl>
        </Box>
      </DialogContent>

      <DialogActions sx={{ pt: 0, pb: 2, px: 2 }}>
        <Button onClick={handleCancel}>Cancel</Button>
        <LoadingButton
          onClick={onSubmit}
          loading={uploading}
          variant="contained"
          sx={{ width: '100px' }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ActionModal;
