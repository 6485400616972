import { Box } from '@mui/material';
import dayjs from 'dayjs';
import React, { FC, useState } from 'react';

import BasicDatePicker from '@/components/molecules/BasicDatePicker';

export interface DateRangeValueProps {
  startDate: dayjs.Dayjs | string | null;
  startDateLabel?: string;
  disableStartDatePicker?: boolean;
  toolTipStartDate?: JSX.Element;
  endAdornmentStartDate?: JSX.Element | null;
  endDate: dayjs.Dayjs | string | null;
  endDateLabel?: string;
  disableEndDatePicker?: boolean;
  toolTipEndDate?: JSX.Element;
  endAdornmentEndDate?: JSX.Element | null;
}

export interface BasicDateRangePickerProps {
  range: DateRangeValueProps;
  onChange: (range: DateRangeValueProps) => void;
  key?: string;
  justify?: 'center' | 'left' | 'right';
  width?: number;
  ml?: number;
  my?: number;
  mr?: number;
  mt?: number;
  display?: string;
}

const BasicDateRangePicker: FC<BasicDateRangePickerProps> = ({
  range,
  onChange,
  justify = 'center',
  width = 152,
  ml = 0,
  my = 0,
  mr = 0,
  mt = 0,
  display = 'flex',
}) => {
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);

  const handleStartDateChange = (date: dayjs.Dayjs | null) => {
    onChange({
      ...range,
      startDate: date,
    });
  };

  const handleEndDateChange = (date: dayjs.Dayjs | null) => {
    onChange({
      ...range,
      endDate: date,
    });
  };

  const wrapWithElement = (
    wrapper: JSX.Element | undefined,
    content: JSX.Element
  ): JSX.Element => {
    if (!wrapper) {
      return content;
    }
    return React.cloneElement(wrapper, {}, content);
  };

  return (
    <Box
      sx={{
        display: display,
        alignItems: 'center',
        justifyContent: justify,
        ml: ml,
        my: my,
        mr: mr,
        mt: mt,
      }}
    >
      {wrapWithElement(
        range.toolTipStartDate,
        <div>
          <BasicDatePicker
            label={range.startDateLabel || 'Start date'}
            value={range.startDate}
            setValue={handleStartDateChange}
            sx={{ width: width }}
            key="startDate"
            InputProps={{
              sx: { borderRadius: '32px 0 0 32px', pr: 1 },
              ...(range.endAdornmentStartDate
                ? { endAdornment: range.endAdornmentStartDate }
                : {}),
            }}
            disabled={range.disableStartDatePicker}
            open={openStartDate}
            onOpen={() => setOpenStartDate(true)}
            onClose={() => setOpenStartDate(false)}
          />
        </div>
      )}

      {wrapWithElement(
        range.toolTipEndDate,
        <div>
          <BasicDatePicker
            label={range.endDateLabel || 'End date'}
            value={range.endDate}
            setValue={handleEndDateChange}
            sx={{ width: width }}
            key="endDate"
            InputProps={{
              sx: { borderRadius: '0 32px 32px 0', ml: -0.15, pr: 1 },
              ...(range.endAdornmentEndDate
                ? { endAdornment: range.endAdornmentEndDate }
                : {}),
            }}
            disabled={range.disableEndDatePicker}
            open={openEndDate}
            onOpen={() => setOpenEndDate(true)}
            onClose={() => setOpenEndDate(false)}
          />
        </div>
      )}
    </Box>
  );
};

export default BasicDateRangePicker;
