import { Box, FormControlLabel, Switch } from '@mui/material';
import { useEffect } from 'react';
import { USER_SUB_TYPES } from 'common/user.constants';

import useAppStore from '@/store/appStore';
import { useIsImpersonatedUser } from '@/hooks/useIsImpersonatedUser';
import { useUserInfo } from '@/hooks/useUserInfo';

export const EnableUpdateToggle = () => {
  const { data } = useUserInfo();
  const isImpUser = useIsImpersonatedUser();
  const setState = useAppStore((s) => s.setState);
  const updatesEnabled = useAppStore((s) => s.updatesEnabled);

  const onChange = () => setState({ updatesEnabled: !updatesEnabled });

  useEffect(() => {
    if (isImpUser) {
      setState({
        updatesEnabled: data?.fintaryAdmin?.subType === USER_SUB_TYPES.csm,
      });
    }
  }, [data?.fintaryAdmin?.subType, isImpUser, setState]);

  if (!isImpUser) return null;
  if (data?.fintaryAdmin?.subType === USER_SUB_TYPES.csm) return null;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 0.25 }}>
      <FormControlLabel
        control={
          <Switch onChange={onChange} checked={updatesEnabled} size="small" />
        }
        label="Enable updates"
      />
    </Box>
  );
};
