import { Clear, Search } from '@mui/icons-material';
import { IconButton, TextField } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { useAccountStore } from '@/store';

const SearchBox = ({ id = '' }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState(searchParams.get('q'));
  const [queryDebounced] = useDebounce(query ?? '', 500);
  const queryRef = useRef<HTMLInputElement>(null);
  const { selectedAccount } = useAccountStore();

  useEffect(() => {
    setQuery(searchParams.get('q'));
  }, [searchParams]);

  const updateSearchParams = useCallback(
    (kvMap: object) => {
      setSearchParams((prev) => {
        Object.entries(kvMap).forEach(([k, v]) => {
          if ([undefined, null, ''].includes(v)) {
            prev.delete(k);
          } else {
            prev.set(k, v);
          }
        });
        return prev;
      });
    },
    [setSearchParams]
  );

  useEffect(() => {
    updateSearchParams({ q: queryDebounced });
  }, [queryDebounced, updateSearchParams]);

  return (
    <TextField
      name={`search-${selectedAccount?.accountId}-${id}`}
      variant="outlined"
      value={query ?? ''}
      inputRef={queryRef}
      onChange={(e) => setQuery(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          queryRef?.current?.blur();
        }
      }}
      autoFocus
      id={`search-${id}`}
      autoComplete={`search-${id}`}
      InputProps={{
        startAdornment: <Search sx={{ opacity: 0.5 }} />,
        endAdornment: (
          <IconButton
            onClick={() => {
              setQuery('');
            }}
          >
            <Clear sx={{ opacity: 0.5 }} />
          </IconButton>
        ),
        style: { height: '34px' },
      }}
    />
  );
};

export default SearchBox;
