import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import { nanoid } from 'nanoid';
import { AccountIds } from 'common/constants';
import dayjs from 'dayjs';

import FilterSelect from '@/common/FilterSelect';
import API from '@/services/API';
import { useAccountStore } from '@/store';
import { SyncEndAdornment } from '@/common/SyncEndAdornment';
import { FieldTypes } from '@/types';
import BasicDateRangePicker from '@/common/BasicDateRangePicker';

type ContactsLevelsAddProps = {
  data: any;
  setter: any;
  field: any;
  dynamicSelects: any;
  isSyncedField: (
    data: any,
    syncedFields: string[],
    fieldId: string,
    config: any
  ) => boolean;
  syncedFields: string[];
};

const ContactsLevelsAdd: React.FC<ContactsLevelsAddProps> = ({
  data,
  field,
  syncedFields,
  isSyncedField,
  setter,
}) => {
  const contactLevels = data?.contact_level ?? [];
  const { data: _companies, isLoading: isLoadingCompanies } =
    API.getBasicQuery('companies');
  const { selectedAccount } = useAccountStore();

  const isEdisonRisk =
    AccountIds.EDISON_RISK === (selectedAccount?.accountId || '');

  const isReadonly = (data: any, fieldId: string) => {
    if (syncedFields?.includes(fieldId)) {
      return isSyncedField(data, syncedFields, fieldId, data.config);
    }
    return false;
  };
  const comapnies = useMemo(
    () =>
      (_companies ?? [])?.data
        ?.filter((company) => company.sync_id)
        .map((company) => ({
          value: company.id,
          label: company.company_name,
        }))
        .sort((a, b) => a.label?.localeCompare(b.label)),
    [_companies]
  );
  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="subtitle2">{field.label}</Typography>
      <Box
        key={`${field.id}-box`}
        sx={{
          width: '100%',
          mb: 1,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            mt: 0.5,
            pl: 1,
            pt: 0.5,
            pb: 0,
            display: 'flex',
            flexDirection: 'column',
            borderStyle: 'solid',
            borderColor: 'silver',
            borderWidth: 1,
            borderRadius: 4,
            width: '100%',
            backgroundColor: '#2196f308',
          }}
        >
          <Box sx={{ my: 0.5, display: 'flex', flexWrap: 'wrap' }}>
            {contactLevels?.map((contactLevel, index) => (
              <Box
                key={`${contactLevel.id}-${index}`}
                sx={{
                  m: 0.5,
                  px: 1,
                  py: 0.5,
                  borderStyle: 'solid',
                  borderColor: 'silver',
                  borderWidth: 1,
                  borderRadius: 4,
                  display: 'inline-block',
                  width: 300,
                  backgroundColor: '#2196f30a',
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  position="relative"
                  sx={{ ml: 0.5 }}
                >
                  <Typography variant="body2">Agent level</Typography>
                  <IconButton
                    onClick={() => {
                      setter({
                        ...data,
                        contact_level: contactLevels.filter(
                          (cl) => cl.id !== contactLevel.id
                        ),
                      });
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Box>
                  <Box>
                    <TextField
                      label="Level"
                      variant="outlined"
                      value={contactLevel.level_label ?? ''}
                      disabled={isReadonly(contactLevel, 'level_label')}
                      onChange={(e) => {
                        setter({
                          ...data,
                          contact_level: data.contact_level.map((item) =>
                            item.id === contactLevel.id
                              ? { ...item, level_label: e.target.value }
                              : item
                          ),
                        });
                      }}
                      sx={{ my: 0.5, width: '100%' }}
                      InputProps={{
                        endAdornment: (
                          <SyncEndAdornment
                            syncedFields={syncedFields}
                            syncId={contactLevel.sync_id}
                            fieldId="level_label"
                            data={contactLevel}
                            onChange={(newOverrideFields) => {
                              setter({
                                ...data,
                                contact_level: data.contact_level.map((item) =>
                                  item.id === contactLevel.id
                                    ? {
                                        ...item,
                                        config: {
                                          ...(item.config || {}),
                                          overrideFields: newOverrideFields,
                                        },
                                      }
                                    : item
                                ),
                              });
                            }}
                          />
                        ),
                      }}
                    />
                  </Box>

                  <BasicDateRangePicker
                    range={{
                      startDate: contactLevel.start_date
                        ? dayjs.utc(contactLevel.start_date)
                        : null,
                      startDateLabel: 'Start date',
                      endDate: contactLevel.end_date
                        ? dayjs.utc(contactLevel.end_date)
                        : null,
                      endDateLabel: 'End date',
                      endAdornmentStartDate: (
                        <SyncEndAdornment
                          syncedFields={syncedFields}
                          syncId={contactLevel.sync_id}
                          fieldId="start_date"
                          fieldType={FieldTypes.DATE}
                          data={contactLevel}
                          onChange={(newOverrideFields) => {
                            setter({
                              ...data,
                              contact_level: data.contact_level.map((item) =>
                                item.id === contactLevel.id
                                  ? {
                                      ...item,
                                      config: {
                                        ...(item.config || {}),
                                        overrideFields: newOverrideFields,
                                      },
                                    }
                                  : item
                              ),
                            });
                          }}
                        />
                      ),
                      endAdornmentEndDate: (
                        <SyncEndAdornment
                          syncedFields={syncedFields}
                          syncId={contactLevel.sync_id}
                          fieldId="end_date"
                          fieldType={FieldTypes.DATE}
                          data={contactLevel}
                          onChange={(newOverrideFields) => {
                            setter({
                              ...data,
                              contact_level: data.contact_level.map((item) =>
                                item.id === contactLevel.id
                                  ? {
                                      ...item,
                                      config: {
                                        ...(item.config || {}),
                                        overrideFields: newOverrideFields,
                                      },
                                    }
                                  : item
                              ),
                            });
                          }}
                        />
                      ),
                    }}
                    onChange={({ startDate, endDate }) => {
                      setter({
                        ...data,
                        contact_level: data.contact_level.map((item) =>
                          item.id === contactLevel.id
                            ? {
                                ...item,
                                start_date: startDate
                                  ? dayjs.isDayjs(startDate)
                                    ? startDate.toDate()
                                    : new Date(startDate)
                                  : null,
                                end_date: endDate
                                  ? dayjs.isDayjs(endDate)
                                    ? endDate.toDate()
                                    : new Date(endDate)
                                  : null,
                              }
                            : item
                        ),
                      });
                    }}
                    width={140}
                    my={0.5}
                  />
                </Box>
                {isEdisonRisk && (
                  <Box>
                    <FilterSelect
                      value={contactLevel.company_id}
                      options={comapnies}
                      label="Company"
                      disabled={!!data.sync_id}
                      onChange={(e) => {
                        setter({
                          ...data,
                          contact_level: data.contact_level.map((item) =>
                            item.id === contactLevel.id
                              ? { ...item, company_id: e?.value }
                              : item
                          ),
                        });
                      }}
                      getOptionLabel={(option: any, options?: any[]) =>
                        typeof option === 'number' && Array.isArray(options)
                          ? (options.find((o) => o.value === option)?.label ??
                            '')
                          : typeof option === 'object'
                            ? option.label
                            : option
                      }
                    />
                  </Box>
                )}
                <Box>
                  <FormControlLabel
                    sx={{ ml: 0 }}
                    control={
                      <>
                        <Checkbox
                          checked={contactLevel.loa}
                          disabled={isReadonly(contactLevel, 'loa')}
                          onChange={(e) => {
                            setter({
                              ...data,
                              contact_level: data.contact_level.map((item) =>
                                item.id === contactLevel.id
                                  ? { ...item, loa: e.target.checked }
                                  : item
                              ),
                            });
                          }}
                        />
                        <Box style={{ marginLeft: 10 }}>
                          <SyncEndAdornment
                            syncedFields={syncedFields}
                            syncId={contactLevel.sync_id}
                            fieldId="loa"
                            // fieldType={FieldTypes.BOOLEAN}
                            data={contactLevel}
                            onChange={(newOverrideFields) => {
                              setter({
                                ...data,
                                contact_level: data.contact_level.map((item) =>
                                  item.id === contactLevel.id
                                    ? {
                                        ...item,
                                        config: {
                                          ...(item.config || {}),
                                          overrideFields: newOverrideFields,
                                        },
                                      }
                                    : item
                                ),
                              });
                            }}
                          />
                        </Box>
                      </>
                    }
                    label="LOA"
                  />
                </Box>
              </Box>
            ))}
            <FormControl key={field.id} sx={{ m: 0.5, width: 30 }}>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setter({
                    ...data,
                    contact_level: [
                      ...(data.contact_level ?? []),
                      {
                        loa: false,
                        level_label: '',
                        id: nanoid(),
                        start_date: null,
                        end_date: null,
                      },
                    ],
                  });
                }}
              >
                Add
              </Button>
            </FormControl>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactsLevelsAdd;
