import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { RATES_LABELS } from 'common/constants';

import DateRangeManager from '@/components/molecules/DateRangeManager';
import { DateRangesTypes } from '@/types';

const ConfirmNewRatesDialog = ({
  open,
  onClose,
  params,
  compGrids,
  selectedDateRanges,
  handleNewRatesConfirm,
  handleDateRangeChange,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>Confirm new rates</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to create new rates?
        </DialogContentText>
        <Box sx={{ mt: 1 }}>
          {params?.new_rates?.length > 0 && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Level name</TableCell>
                    <TableCell>Comp grid</TableCell>
                    <TableCell>Rates</TableCell>
                    <TableCell>Date range</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {params.new_rates.map((rate, index) => {
                    if (typeof rate.rates !== 'object' || rate.rates === null) {
                      return null;
                    }

                    return (
                      <TableRow key={index}>
                        <TableCell>{rate.level_name}</TableCell>
                        <TableCell>
                          {
                            compGrids.filter(
                              (compGrid) => compGrid.id === rate.comp_grid_id
                            )[0]?.name
                          }
                        </TableCell>
                        <TableCell>
                          {Object.keys(rate.rates).map(
                            (rateItem, rateIndex) => {
                              if (
                                !Object.prototype.hasOwnProperty.call(
                                  RATES_LABELS,
                                  rateItem
                                )
                              ) {
                                return null;
                              }
                              return (
                                <Box key={rateIndex}>
                                  {RATES_LABELS[rateItem]}:{' '}
                                  {rate.rates[rateItem]}
                                </Box>
                              );
                            }
                          )}
                        </TableCell>
                        <TableCell>
                          <DateRangeManager
                            key={`comp_grid_criteria_date_ranges_${index}`}
                            row={selectedDateRanges}
                            setter={(newDateRange) =>
                              handleDateRangeChange(index, newDateRange)
                            }
                            field={{
                              id: 'date_ranges',
                              label: '',
                            }}
                            type={DateRangesTypes.ANY}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} size="small" color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleNewRatesConfirm}
          size="small"
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmNewRatesDialog;
