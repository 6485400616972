import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import * as Sentry from '@sentry/react';
import useBeforeUnloadPage from 'contexts/useBeforeunloadPage';
import { sha256 } from 'crypto-hash';
import { nanoid } from 'nanoid';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useBeforeUnload } from 'react-use';
import { useOriginalFile } from 'store/excelStore';

import FilePreview from '@/common/preview';
import { SpreadsheetProps, XLS_CSV_TYPES } from '@/common/preview/model';
import DuplicateFilesDetector from '@/components/UploadModal/duplicateFilesDetector';
import useSnackbar from '@/contexts/useSnackbar';
import { useUploadStorageFileV2 } from '@/contexts/useUploadStorageFileV2';
import API from '@/services/API';
import { normalizeCurrency } from '@/services/DataTransformation/normalizer';
import Spreadsheet from '@/services/Spreadsheet';
import { readFile } from '@/services/helpers';
import { useAccountStore } from '@/store';
import useUploadStore from '@/store/uploadStore';
import { DocumentProfileModel, IDocumentModel } from './processFlow/model';

interface SelectItem {
  label: string;
  value: string;
  data: File & { path?: string };
  docType: string;
}

interface PreviewUploadsProps {
  formModel: any;
  onFinishUpload: (err: boolean) => void;
  onFileUploadSuccess: (result: {
    document: IDocumentModel;
    profile: DocumentProfileModel;
  }) => Promise<void>;
}

const PreviewUploads = forwardRef(
  (
    { formModel, onFileUploadSuccess, onFinishUpload }: PreviewUploadsProps,
    ref
  ) => {
    const [currentPreviewFile, setCurrentPreviewFile] = useState<File>();
    const [selectOptions, setSelectOptions] = useState<SelectItem[]>([]);
    const [spreadsheet, setSpreadsheet] = useState<SpreadsheetProps>();
    const [fileHashes, setFileHashes] = useState<SpreadsheetProps>();
    const [hasDuplicateFile, setHasDuplicateFile] = useState<boolean>(false);

    const originalFile = useOriginalFile();
    const { selectedAccount } = useAccountStore();
    const { showSnackbar } = useSnackbar();

    const documentsPoster = API.getMutation('documents', 'POST');
    const documentProfilePoster = API.getMutation('document_profiles', 'POST');
    const { uploadFile } = useUploadStorageFileV2();
    const uploadProgresses = useUploadStore((s) => s.uploadProgresses);
    const setUploadProgress = useUploadStore((s) => s.setUploadProgress);
    const resetProgresses = useUploadStore((s) => s.resetProgresses);

    const uploading = Object.values(uploadProgresses).some(
      (item) => item === 'uploading'
    );

    useEffect(() => {
      const fetchFileHashes = async () => {
        const hashes = {};
        await Promise.all(
          selectOptions.map(async (option) => {
            const file = option.data;
            const fileName = file.name;
            const fileContent = await readFile(file);
            const fileHash = await sha256(fileContent);
            hashes[fileName] = fileHash;
          })
        );
        setFileHashes(hashes);
      };
      fetchFileHashes();
    }, [selectOptions]);

    useEffect(() => {
      const values = Object.values(uploadProgresses);
      const finished = values.length && !uploading;
      console.log('values', values);
      if (finished) {
        console.log('finishing');
        onFinishUpload(values.some((item) => item === 'failed'));
        resetProgresses();
      }
    }, [onFinishUpload, uploadProgresses, uploading]);

    useBeforeUnload(
      uploading,
      'You have unsaved files, are you sure you want to leave?'
    );
    useBeforeUnloadPage(
      uploading,
      'You have unsaved files, are you sure you want to leave?'
    );

    const loadFilePath = (file: File) => {
      if (!selectedAccount?.accountId || !file) {
        return '';
      }
      const filename = `${nanoid()}-${file.name}`;
      const accountId = selectedAccount?.accountId;
      let filePath = 'uploads/';
      if (accountId) {
        filePath += `${accountId}/`;
      }
      filePath += filename;
      return filePath;
    };

    const loadUploadBody = async () => {
      // Loop selectOption
      const promises = selectOptions.map(async (option) => {
        const file = option.data;
        const filePath = loadFilePath(file);
        const fileContent = await readFile(file);
        const fileHash = await sha256(fileContent);

        const bankTotalKey = file?.path || '';
        const bankTotalAmount = formModel.bank_total_amounts?.[bankTotalKey];
        const checkDate = formModel.check_dates?.[bankTotalKey];
        const depositDate = formModel.deposit_dates?.[bankTotalKey];
        const company_str_id =
          formModel.companies?.[file.name]?.value || formModel.companies?.value;
        const document_type =
          formModel.document_type?.[file.name] || formModel.document_type;
        const body = {
          filename: file.name,
          file_path: filePath,
          type: document_type,
          method: '',
          processor: '',
          file_hash: fileHash,
          file,
          status: 'new',
          company_str_id: company_str_id,
          tag: formModel.tag,
          docType: option.docType,
          bank_total_amount: bankTotalAmount
            ? normalizeCurrency(bankTotalAmount)
            : null,
          check_date: checkDate,
          deposit_date: depositDate,
        };
        return body;
      });

      const bodys = await Promise.all(promises);

      return bodys;
    };

    const getFailedFileNames = () => {
      const fileNames: string[] = [];
      for (const key in uploadProgresses) {
        if (uploadProgresses[key] === 'failed') fileNames.push(key);
      }
      return fileNames;
    };

    const postUploadsInfo = async (params?: { tryAgain: boolean }) => {
      let bodys = await loadUploadBody();

      if (params?.tryAgain) {
        const failedFileNames = getFailedFileNames();
        bodys = bodys.filter((item) => failedFileNames.includes(item.filename));
      }

      bodys.forEach(async ({ file, docType, ...rest }) => {
        try {
          const docPoster = {
            ...rest,
          };

          const uploadInfo = await documentsPoster.mutateAsync(docPoster);
          // Create/update document_profile record
          const docTypeParams = {
            carrier_name: uploadInfo.company_str_id,
            paying_entity: uploadInfo.company_str_id,
            owner: '',
            field_mapping: '',
            status: 'draft',
            file_link: [rest.file_path],
            notes: '',
            document_str_ids: [uploadInfo.str_id],
            mappings_str_ids: uploadInfo.mapping ? [uploadInfo.mapping] : [],
          };

          // TODO - refactor:this step is optional
          // and should be done on Server side
          documentProfilePoster
            .mutateAsync(docTypeParams)
            .then((docProfile) => {
              onFileUploadSuccess({
                document: uploadInfo,
                profile: docProfile,
              });
            })
            .catch((ex) => {
              Sentry.captureException(ex);
            });

          setUploadProgress({ filename: file.name, state: 'uploading' });
          await uploadFile({
            file,
            action: 'write',
            endpoint: 'documents',
            endpoint_str_id: uploadInfo.str_id,
            file_preview_type: uploadInfo.override_file_path
              ? 'override'
              : 'original',
          });

          setUploadProgress({ filename: file.name, state: 'done' });
        } catch (error: any) {
          setUploadProgress({ filename: file.name, state: 'failed' });
          const errMsg = error.message || error;
          Sentry.captureException(error);
          showSnackbar(`Failed to create document record: ${errMsg}`, 'error');
        }
      });
    };

    const doChange = useCallback(async (file) => {
      if (!file) {
        return;
      }
      setCurrentPreviewFile(file);
    }, []);

    const onChange = async (e) => {
      const val = e.target.value;
      if (!val) {
        return;
      }
      const file = selectOptions.find((item) => item.value === val)!.data;
      doChange(file);
    };

    useEffect(() => {
      // Excel, csv need to load the raw data
      const setExcelData = async () => {
        if (
          currentPreviewFile &&
          XLS_CSV_TYPES.includes(currentPreviewFile.type)
        ) {
          const res = (await Spreadsheet.loadSpreadsheet(
            currentPreviewFile
          )) as SpreadsheetProps;
          setSpreadsheet(res);
        }
      };
      setExcelData();
    }, [currentPreviewFile]);

    useEffect(() => {
      if (originalFile) {
        setSelectOptions((prev) => {
          const newOptions = originalFile.map((file) => ({
            label: file.name,
            value: file.name,
            data: file,
            docType: 'original',
          }));

          const uniqueOptions = newOptions.filter(
            (option) => !prev.some((item) => item.label === option.label)
          );

          return [...prev, ...uniqueOptions];
        });
      }
    }, [originalFile]);

    useEffect(() => {
      const selectOne = async () => {
        if (selectOptions.length > 0) {
          await doChange(selectOptions[0].data);
        }
      };
      selectOne();
    }, [doChange, originalFile, selectOptions, selectOptions.length]);

    useImperativeHandle(ref, () => ({
      submit: postUploadsInfo,
    }));

    const renderUploadProgressIcon = (filename: string) => {
      switch (uploadProgresses[filename]) {
        case 'done':
          return <CheckIcon sx={{ marginLeft: '10px', color: green['500'] }} />;
        case 'failed':
          return (
            <ErrorOutlineIcon sx={{ marginLeft: '10px', color: red['500'] }} />
          );
        case 'uploading':
          return <CircularProgress size="20px" sx={{ marginLeft: '10px' }} />;
        default:
          return null;
      }
    };

    const renderDuplicateFilesIcon = (filename: string) => {
      if (
        fileHashes &&
        Object.prototype.hasOwnProperty.call(fileHashes, filename)
      ) {
        return (
          <DuplicateFilesDetector
            hash={fileHashes[filename]}
            hasDuplicateFile={hasDuplicateFile}
            setHasDuplicateFile={setHasDuplicateFile}
          />
        );
      }
      return null;
    };

    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          maxHeight: 'calc(100vh - 240px)',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <section>
          {currentPreviewFile && (
            <FormControl fullWidth sx={{ mt: 1 }}>
              <InputLabel>File</InputLabel>
              <Select
                fullWidth
                value={currentPreviewFile.name}
                onChange={onChange}
                sx={{ marginBottom: 2 }}
                label="File"
                renderValue={(v) => {
                  return (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{ flexGrow: 1 }}>{v}</Typography>
                      {renderDuplicateFilesIcon(v)}
                      {renderUploadProgressIcon(v)}
                    </Box>
                  );
                }}
              >
                {selectOptions.map((item) => (
                  <MenuItem
                    sx={{ alignItems: 'center' }}
                    key={item.value}
                    value={item.value}
                  >
                    <Typography sx={{ flexGrow: 1 }}>{item.label}</Typography>
                    {renderDuplicateFilesIcon(item.value)}
                    {renderUploadProgressIcon(item.value)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </section>
        {hasDuplicateFile && (
          <Typography
            variant="body2"
            color="error"
            sx={{
              marginTop: '-10px',
              marginLeft: 'auto',
              marginRight: '4px',
              textAlign: 'right',
            }}
          >
            This file is a duplicate of an existing document. Click above to
            view existing duplicate files.
          </Typography>
        )}
        <FilePreview
          previewFile={currentPreviewFile}
          previewWith={window.innerWidth * 0.8}
          // @ts-ignore
          spreadsheet={spreadsheet}
          // @ts-ignore
          setSpreadsheet={setSpreadsheet}
        />
      </Box>
    );
  }
);
PreviewUploads.displayName = 'PreviewUploads';

export default PreviewUploads;
