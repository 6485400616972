import React, { useState, useRef } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  Box,
  Grow,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  ContentCopy as ContentCopyIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

interface ToolDescItem {
  name: string;
  description: string;
  arguments?: Record<string, string>;
  returns: string;
  examples?: string;
}

interface ToolDesc {
  [category: string]: {
    [key: string]: ToolDescItem;
  };
}

const ToolDesc = ({ toolDesc = {} }: { toolDesc: ToolDesc }) => {
  const [hoveredItem, setHoveredItem] = useState<{
    category: string;
    key: string;
  }>({ category: '', key: '' });
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const isHoveringDescription = useRef(false);

  const CodeBlock = styled('pre')(({ theme }) => ({
    margin: 0,
    padding: theme.spacing(1.5),
    backgroundColor: 'black',
    borderRadius: theme.shape.borderRadius,
    color: 'white',
    fontFamily: 'Consolas, "Liberation Mono", Menlo, Courier, monospace',
    fontSize: '13px',
    overflowX: 'auto',
    '& code': {
      padding: 0,
      background: 'none',
    },
  }));

  const handleMouseEnter = (
    event: React.MouseEvent<HTMLElement>,
    category: string,
    key: string
  ) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setAnchorEl(event.currentTarget);
    setHoveredItem({ category, key });
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      if (!isHoveringDescription.current) {
        setAnchorEl(null);
        setHoveredItem({ category: '', key: '' });
      }
    }, 100);
  };

  const handleDescriptionMouseEnter = () => {
    isHoveringDescription.current = true;
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const handleDescriptionMouseLeave = () => {
    isHoveringDescription.current = false;
    handleMouseLeave();
  };

  const handleToolsCopy = (name: string) => {
    navigator.clipboard.writeText(name).catch((err) => {
      console.error('Failed to copy text: ', err);
    });
  };

  const getPopperPosition = () => {
    if (!anchorEl) return {};
    const rect = anchorEl.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const descriptionHeight = 600;

    let top = rect.top;
    if (rect.top + descriptionHeight > windowHeight) {
      top = windowHeight - descriptionHeight - 20;
    }

    return {
      position: 'fixed',
      top: Math.max(20, top),
      right: window.innerWidth - rect.left,
      transform: 'translateX(10px)',
      zIndex: 1300,
    } as const;
  };

  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'flex-end', position: 'relative' }}
    >
      <List sx={{ width: 270 }}>
        {Object.keys(toolDesc).map((category) => (
          <Accordion
            key={category}
            sx={{
              boxShadow: 'none',
              '&::before': {
                display: 'none',
              },
              '& .MuiAccordionSummary-root': {
                minHeight: 'auto',
                padding: '4px 12px',
                backgroundColor: 'rgba(0, 0, 0, 0.03)',
                fontFamily: '"Source Sans Pro", sans-serif',
                fontWeight: 600,
                fontSize: '0.9rem',
                '&.Mui-expanded': {
                  minHeight: 'auto',
                },
              },
              '& .MuiAccordionDetails-root': {
                padding: '4px 12px',
              },
            }}
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                '& .MuiAccordionSummary-content': {
                  margin: 0,
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: '0.9rem',
                  fontWeight: 600,
                  fontFamily: '"Source Sans Pro", sans-serif',
                }}
              >
                {category}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {Object.keys(toolDesc[category]).map((key) => (
                <ListItem
                  key={key}
                  onMouseEnter={(e) => handleMouseEnter(e, category, key)}
                  onMouseLeave={handleMouseLeave}
                  sx={{
                    cursor: 'pointer',
                    padding: '4px 8px',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    },
                    '& .MuiListItemText-primary': {
                      fontSize: '0.875rem',
                      fontFamily: '"Source Code Pro", monospace',
                    },
                  }}
                >
                  <ListItemText primary={key} />
                </ListItem>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </List>

      <Grow in={Boolean(hoveredItem.key)}>
        <Paper
          sx={{
            position: 'absolute',
            width: 350,
            p: 2,
            right: '100%',
            mr: 2,
            bgcolor: 'background.paper',
            boxShadow: 4,
            maxHeight: '600px',
            overflowY: 'auto',
            ...(anchorEl ? getPopperPosition() : {}),
          }}
          onMouseEnter={handleDescriptionMouseEnter}
          onMouseLeave={handleDescriptionMouseLeave}
        >
          <Box sx={{ mb: 0.5 }}>
            <Box sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
              <Typography
                color="primary"
                sx={{
                  fontWeight: 600,
                  mb: 1,
                  fontFamily:
                    'Consolas, "Liberation Mono", Menlo, Courier, monospace',
                  fontSize: '0.9rem',
                  flexGrow: 1,
                  mr: 2,
                  wordBreak: 'break-all',
                }}
              >
                {toolDesc[hoveredItem.category]?.[hoveredItem.key]?.name}
              </Typography>
              <Box
                sx={{
                  cursor: 'pointer',
                  color: 'text.secondary',
                  '&:hover': {
                    color: 'text.primary',
                  },
                }}
                onClick={() =>
                  handleToolsCopy(
                    toolDesc[hoveredItem.category]?.[hoveredItem.key]?.name
                  )
                }
              >
                <ContentCopyIcon fontSize="small" />
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 1 }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {toolDesc[hoveredItem.category]?.[hoveredItem.key]?.description}
            </Typography>
          </Box>

          <Box sx={{ mt: 1 }}>
            <Typography
              variant="subtitle2"
              color="primary"
              sx={{
                fontWeight: 600,
                mb: 1,
              }}
            >
              Arguments:
            </Typography>
            <Box>
              {Object.entries(
                toolDesc[hoveredItem.category]?.[hoveredItem.key]?.arguments ||
                  {}
              ).map(([argName, argDesc]) => (
                <Typography
                  key={argName}
                  variant="body2"
                  sx={{
                    color: 'text.secondary',
                    fontFamily:
                      'Consolas, "Liberation Mono", Menlo, Courier, monospace',
                    '& .type': {
                      color: '#8250df',
                    },
                  }}
                >
                  <Box component="span" fontWeight={600}>
                    {argName.split(/(\[.*?\])/).map((part, index) => (
                      <Box
                        key={index}
                        component="span"
                        className={part.startsWith('[') ? 'type' : ''}
                      >
                        {part}
                      </Box>
                    ))}
                    :
                  </Box>{' '}
                  {String(argDesc)
                    .split(/(\[.*?\])/)
                    .map((part, index) => (
                      <Box
                        key={index}
                        component="span"
                        className={part.startsWith('[') ? 'type' : ''}
                      >
                        {part}
                      </Box>
                    ))}
                </Typography>
              ))}
            </Box>
          </Box>

          <Box sx={{ mt: 1 }}>
            <Typography
              variant="subtitle2"
              color="primary"
              sx={{
                fontWeight: 600,
                mb: 1,
              }}
            >
              Returns:
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: 'text.secondary',
                fontFamily:
                  'Consolas, "Liberation Mono", Menlo, Courier, monospace',
                '& .type': {
                  color: '#8250df',
                },
              }}
            >
              {toolDesc[hoveredItem.category]?.[hoveredItem.key]?.returns
                .split(/(\[.*?\])/)
                .map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    className={part.startsWith('[') ? 'type' : ''}
                  >
                    {part}
                  </Box>
                ))}
            </Typography>
          </Box>

          <Box sx={{ mt: 1 }}>
            <Typography
              variant="subtitle2"
              color="primary"
              sx={{
                fontWeight: 600,
                mb: 0.5,
              }}
            >
              Example:
            </Typography>
            <CodeBlock>
              <code>
                {toolDesc[hoveredItem.category]?.[hoveredItem.key]?.examples ||
                  ''}
              </code>
            </CodeBlock>
          </Box>
        </Paper>
      </Grow>
    </Box>
  );
};

export default ToolDesc;
