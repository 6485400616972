import { useEffect, useState } from 'react';
import { captureException } from '@sentry/react';

import API from '@/services/API';
import useSnackbar from '@/contexts/useSnackbar';
import { getMimeType } from '@/services/helpers';

type StorageFileParams = StorageSignedUrlParams & {
  file: File;
};

const useUploadStorageFile = () => {
  const [fileParams, setUploadFileParams] = useState<StorageFileParams[]>([]);
  const fileSignedUrlLoader = API.getMutation('storage/getSignedUrl', 'POST');
  const [isUploaded, setIsUploaded] = useState(false);
  const { showSnackbar } = useSnackbar();

  const getSignedUrl = async (params: StorageSignedUrlParams) => {
    try {
      const res = await fileSignedUrlLoader.mutateAsync({
        ...params,
        action: 'write',
      });
      return res.data;
    } catch (error: any) {
      console.error(error.message || error);
      showSnackbar(error.message || error, 'error');
      return null;
    }
  };

  const uploadFilesToStorage = async (signedUrl, file) => {
    try {
      const mimeType = getMimeType(file.name);
      const headers = new Headers({
        'content-type': mimeType,
        'content-length': file.size.toString(),
      });
      const res = await fetch(signedUrl, {
        method: 'PUT',
        headers: headers,
        body: file,
      });
      if (!res.ok) {
        showSnackbar(
          `Failed to upload file, error: ${res.status} ${res.statusText} `,
          'error'
        );
        setIsUploaded(false);
        return;
      }
      setIsUploaded(true);
      return res;
    } catch (error: any) {
      const err = error.message || error;
      console.error(err);
      captureException(error);
      showSnackbar(`Failed to upload file: ${err}`, 'error');
      setIsUploaded(false);
      return;
    }
  };

  const uploadFilesByGenerateSignedUrl = async (
    fileParams: StorageFileParams[]
  ) => {
    const signedUrlsPromise = fileParams.map(
      async ({ file_preview_type, endpoint, endpoint_str_id, file }) => {
        const params: StorageSignedUrlParams = {
          file_preview_type,
          endpoint,
          endpoint_str_id,
        };
        const signedUrl = (await getSignedUrl(params)) as string;
        return { signedUrl, file };
      }
    );

    try {
      const signedUrls = await Promise.all(signedUrlsPromise);
      const uploadPromises = signedUrls.map(async ({ signedUrl, file }) => {
        return uploadFilesToStorage(signedUrl, file);
      });
      await Promise.all(uploadPromises);
      setIsUploaded(true);
    } catch (error: any) {
      console.error(error.message || error);
      captureException(error);

      showSnackbar(error.message || error, 'error');
      setIsUploaded(false);
      return;
    }
  };

  useEffect(() => {
    if (fileParams && fileParams.length) {
      uploadFilesByGenerateSignedUrl(fileParams);
    }
  }, [fileParams]);

  return {
    setUploadFileParams,
    isUploaded,
    uploadFilesByGenerateSignedUrl,
    uploadFilesToStorage,
  };
};

export default useUploadStorageFile;
