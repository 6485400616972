import { NotificationsNone, DeveloperBoard } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import {
  Alert,
  Badge,
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import API from '@/services/API';
import { States } from '@/types';
import { useAccountStore } from '@/store';

export const Notification = () => {
  const navigate = useNavigate();
  const { userState } = useAccountStore();
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  const [versionNotification, setVersionNotification] = useState<any>();
  const { data: notifications } = API.getBasicQuery<AppNotification[]>(
    'notifications',
    '',
    userState?.userOverallState === States.ACTIVE
  );

  useEffect(() => {
    const versionListener = (evt: any) => {
      setVersionNotification({
        id: evt.detail.buildTime,
        type: 'version',
        text: (
          <Alert
            severity="info"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              localStorage.setItem('triggerReload', Date.now().toString());
              window.location.reload();
            }}
          >
            A new version is available. Click to reload and update.
          </Alert>
        ),
      });
    };
    window.addEventListener('version-update', versionListener);
    return () => window.removeEventListener('version-update', versionListener);
  }, []);
  const openNotifications = (event) => {
    const notificationsAnchorEl = event.currentTarget;
    setNotificationsAnchorEl(notificationsAnchorEl);
  };

  const closeNotifications = () => {
    setNotificationsAnchorEl(null);
  };

  const getNotificationIcon = (notification) => {
    switch (notification.type) {
      case 'processor':
        return <DeveloperBoard />;
      default:
        return null;
    }
  };

  const total = (notifications?.length ?? 0) + (versionNotification ? 1 : 0);
  const hasNotification = !!total;
  return (
    <>
      <Tooltip
        title={
          !hasNotification ? 'No new notifications' : `${total} notifications`
        }
      >
        <IconButton onClick={openNotifications}>
          <Badge badgeContent={total} color="warning">
            <NotificationsNone
              sx={{
                color: hasNotification ? '#fff' : '#eee',
              }}
            />
          </Badge>
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={notificationsAnchorEl}
        open={Boolean(notificationsAnchorEl)}
        onClose={closeNotifications}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box>
          <MenuList sx={{ py: 0 }}>
            {hasNotification ? (
              [versionNotification, ...(notifications || [])].map(
                (notification) => (
                  <MenuItem
                    key={notification.id}
                    onClick={() => {
                      navigate(notification.link);
                    }}
                  >
                    {notification.type !== 'version' && (
                      <ListItemIcon>
                        {getNotificationIcon(notification)}
                      </ListItemIcon>
                    )}
                    <ListItemText>{notification.text}</ListItemText>
                  </MenuItem>
                )
              )
            ) : (
              <MenuItem disabled>No notifications</MenuItem>
            )}
          </MenuList>
        </Box>
      </Menu>
    </>
  );
};
