import { Option } from './types';

export const convertObjectToOption = <T>(
  item: T,
  valueKey: string,
  labelKey: string
): Option | undefined => {
  if (!item) return undefined;

  const rest = { ...item };
  delete rest[valueKey];
  delete rest[labelKey];

  return {
    value: item[valueKey],
    label: item[labelKey],
    ...rest,
  };
};

export const convertOptionToValue = (
  item: Option,
  valueKey: string,
  labelKey: string
) => {
  const { value, label, ...rest } = item;

  return {
    [valueKey]: value,
    [labelKey]: label,
    ...rest,
  };
};

export const convertStringToOptionList = (arr: Option['value'][]): Option[] =>
  arr.map((item) => ({ value: item, label: String(item) }));
