import React from 'react';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { isValidJsonString } from 'common/helpers';
import { RulesOperators } from 'common/globalTypes';

import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import { FieldTypes } from '@/types';
import { EnhancedSelect } from './EnhancedSelect';

const fieldTypeComponents = {
  [FieldTypes.DATE]: ({ fieldMatcher, fieldsMatchers, i, setValue }) => (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <BasicDatePicker
        sx={{ mr: 1 }}
        label="Value"
        value={fieldMatcher.value}
        setValue={(value) => {
          const newFieldMatchers = [...fieldsMatchers];
          newFieldMatchers[i].value = dayjs(value).utc().format('YYYY-MM-DD');
          setValue(newFieldMatchers);
        }}
      />
    </LocalizationProvider>
  ),
  [FieldTypes.SELECT]: ({
    field,
    fieldMatcher,
    fieldsMatchers,
    i,
    setValue,
  }) => (
    <EnhancedSelect
      label="Value"
      options={field.options}
      value={field.options.find((item) => {
        if (typeof item === 'string') return item === fieldMatcher.value;
        return item.id === fieldMatcher.value;
      })}
      onChange={(v) => {
        const newFieldMatchers = [...fieldsMatchers];
        console.log(v);
        newFieldMatchers[i].value = typeof v === 'string' ? v : v.id;
        setValue(newFieldMatchers);
      }}
      sx={{ mr: 1 }}
    />
  ),
  // Add more field types and their corresponding components here
};

const DefaultFieldComponent = ({
  fieldMatcher,
  fieldsMatchers,
  i,
  setValue,
}) => (
  <TextField
    label="Value"
    value={fieldMatcher.value}
    onChange={(e) => {
      const newFieldMatchers = [...fieldsMatchers];
      newFieldMatchers[i].value = e.target.value;
      setValue(newFieldMatchers);
    }}
    sx={{ mr: 1, minWidth: 90 }}
    error={
      ['ncontainedin', 'containedin'].includes(fieldMatcher.op) &&
      !isValidJsonString(fieldMatcher.value)
    }
  />
);

const FieldMatcherComponent = ({
  fields,
  fieldMatcher,
  fieldsMatchers,
  i,
  setValue,
}) => {
  const field = fields.find((f) => f.id === fieldMatcher?.field);
  let FieldComponent =
    fieldTypeComponents[field?.type] || DefaultFieldComponent;

  if (
    fieldMatcher?.op === RulesOperators.WITHIN_ONE_YEAR ||
    fieldMatcher?.op === RulesOperators.AT_LEAST_ONE_YEAR
  ) {
    FieldComponent = fieldTypeComponents[FieldTypes.SELECT];
  }
  return (
    <FieldComponent
      field={field}
      fieldMatcher={fieldMatcher}
      fieldsMatchers={fieldsMatchers}
      i={i}
      setValue={setValue}
    />
  );
};

export default FieldMatcherComponent;
