import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { MoreVert } from '@mui/icons-material';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './styles.css';
import { isEqual, isNull, set } from 'lodash-es';
import { useContext, useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { WidgetGroup } from 'common/constants';
import { useNavigate } from 'react-router-dom';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { LoadingContext } from 'contexts/LoadingContext';
import AddIcon from '@mui/icons-material/Add';

import useDashboard from './dashboardHook';
import { useRoleStore } from '@/store';
import LoadingCircle from '@/components/atoms/LoadingCircle';
import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import MultiSelect from '@/components/molecules/MultiSelect';
import API from '@/services/API';
import UILabels from '@/services/UILabels';
import { useAccountStore } from '@/store';
import WidgetCreator from './WidgetCreator';
import BoxWidget from './BoxWidget';
import ChartWidget from './ChartWidget';
import TableWidget from './TableWidget';
import WidgetWrapper from './WidgetWrapper';
import { UIStateContext } from '@/contexts/UIStateProvider';
import { Roles } from '@/types';
import WidgetSelector from './WidgetSelector';
import SubPageCreator from './SubPageCreator';
import useSnackbar from '@/contexts/useSnackbar';

dayjs.extend(quarterOfYear);
dayjs.extend(utc);
dayjs.extend(timezone);

const dateRanges = {
  thisWeek: () => ({
    startDate: dayjs().startOf('week').toDate(),
    endDate: dayjs().endOf('week').toDate(),
  }),
  lastWeek: () => ({
    startDate: dayjs().subtract(1, 'week').startOf('week').toDate(),
    endDate: dayjs().subtract(1, 'week').endOf('week').toDate(),
  }),
  thisMonth: () => ({
    startDate: dayjs().startOf('month').toDate(),
    endDate: dayjs().endOf('month').toDate(),
  }),
  lastMonth: () => ({
    startDate: dayjs().subtract(1, 'month').startOf('month').toDate(),
    endDate: dayjs().subtract(1, 'month').endOf('month').toDate(),
  }),
  thisQuarter: () => ({
    startDate: dayjs().startOf('quarter').toDate(),
    endDate: dayjs().endOf('quarter').toDate(),
  }),
  lastQuarter: () => ({
    startDate: dayjs().subtract(1, 'quarter').startOf('quarter').toDate(),
    endDate: dayjs().subtract(1, 'quarter').endOf('quarter').toDate(),
  }),
  thisYear: () => ({
    startDate: dayjs().startOf('year').toDate(),
    endDate: dayjs().endOf('year').toDate(),
  }),
  lastYear: () => ({
    startDate: dayjs().subtract(1, 'year').startOf('year').toDate(),
    endDate: dayjs().subtract(1, 'year').endOf('year').toDate(),
  }),
  last7days: () => ({
    startDate: dayjs().subtract(7, 'days').toDate(),
    endDate: dayjs().toDate(),
  }),
  last30days: () => ({
    startDate: dayjs().subtract(30, 'days').toDate(),
    endDate: dayjs().toDate(),
  }),
  last60days: () => ({
    startDate: dayjs().subtract(60, 'days').toDate(),
    endDate: dayjs().toDate(),
  }),
  last90days: () => ({
    startDate: dayjs().subtract(90, 'days').toDate(),
    endDate: dayjs().toDate(),
  }),
};

const DrawerMode = {
  CREATE_WIDGET: 'CREATE_WIDGET',
  ADD_SHARED_WIDGET: 'ADD_SHARED_WIDGET',
  ADD_NEW_PAGE: 'ADD_NEW_PAGE',
};

const filters = {
  agentGroup: {
    label: 'Agent group',
    type: 'multi-select',
    field: 'agent_group',
    optionsKey: 'agentGroup',
    filterFunc: (val, filterVal) =>
      filterVal === 'All' ? true : val === filterVal,
  },
  agent: {
    label: 'Agent',
    type: 'multi-select',
    field: 'agent',
    optionsKey: 'agent',
    filterFunc: (val, filterVal) =>
      filterVal === 'All' ? true : val === filterVal,
  },
  policyStatus: {
    label: 'Policy status',
    type: 'multi-select',
    optionsKey: 'policyStatus',
    field: 'policy_status',
    filterFunc: (val, filterVal) =>
      filterVal === 'All' ? true : val === filterVal,
  },
  productType: {
    label: 'Product type',
    type: 'multi-select',
    optionsKey: 'productType',
    field: 'product_type',
    filterFunc: (val, filterVal) =>
      filterVal === 'All' ? true : val === filterVal,
  },
  compensationType: {
    label: 'Compensation type',
    type: 'multi-select',
    optionsKey: 'compensationType',
    field: 'compensation_type',
    filterFunc: (val, filterVal) =>
      filterVal === 'All' ? true : val === filterVal,
  },
  tag: {
    label: 'Tag',
    type: 'multi-select',
    optionsKey: 'tag',
    field: 'tag',
    filterFunc: (val, filterVal) =>
      filterVal === 'All' ? true : val === filterVal,
  },
  dateRange: {
    label: 'Date range',
    type: 'select',
    options: [
      { label: 'This week', value: 'thisWeek' },
      { label: 'Last week', value: 'lastWeek' },
      { label: 'This month', value: 'thisMonth' },
      { label: 'Last month', value: 'lastMonth' },
      { label: 'This quarter', value: 'thisQuarter' },
      { label: 'Last quarter', value: 'lastQuarter' },
      { label: 'This year', value: 'thisYear' },
      { label: 'Last year', value: 'lastYear' },
      { label: 'Last 7 days', value: 'last7days' },
      { label: 'Last 30 days', value: 'last30days' },
      { label: 'Last 60 days', value: 'last60days' },
      { label: 'Last 90 days', value: 'last90days' },
      { label: 'Custom', value: 'custom' },
    ],
    initialValue: 'last60days',
    sx: { width: 140 },
  },
  startDate: {
    label: 'Start date',
    type: 'date',
    field: 'effectiveDate',
    initialValue: dayjs().subtract(60, 'days').toDate(),
    filterFunc: (val, filterVal) => val >= filterVal,
  },
  endDate: {
    label: 'End date',
    type: 'date',
    field: 'effectiveDate',
    initialValue: dayjs().toDate(),
    filterFunc: (val, filterVal) => val <= filterVal,
  },
  timePeriod: {
    label: 'Time period',
    type: 'select',
    options: [
      { label: 'Day', value: 'day' },
      { label: 'Week', value: 'week' },
      { label: 'Month', value: 'month' },
    ],
    initialValue: 'Month',
    sx: { width: 100 },
  },
};
const ResponsiveGridLayout = WidthProvider(Responsive);

const Dashboard = ({ dashboardLabel, dashboardName }) => {
  const { data: accountSettings, isFetched: isFetchedAccountSettings } =
    API.getBasicQuery(`accounts/settings`);
  const navigate = useNavigate();
  const [sideDrawerMode, setSideDrawerMode] = useState(
    DrawerMode.CREATE_WIDGET
  );
  const [layouts, setLayouts] = useState(null);
  const [showSaveLayoutButton, setShowSaveLayoutButton] = useState(false);
  const { setLoadingConfig } = useContext(LoadingContext);

  const cols = { lg: 8, md: 8, sm: 4, xs: 2, xxs: 2 };
  const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };
  const margin = {
    lg: [10, 10],
    md: [10, 10],
    sm: [10, 10],
    xs: [10, 10],
    xxs: [10, 10],
  };
  const sidebarWidth = 500;
  const {
    role: [role],
  } = useContext(UIStateContext);
  const isFinatryAdmin = role === 'admin';
  const { userRole } = useRoleStore();
  const isAccountAdmin = userRole === Roles.ACCOUNT_ADMIN;
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [widgetOnEdit, setWidgetOnEdit] = useState(null);
  const [activeView, setActiveView] = useState(userRole);
  const [searchParams, setSearchParams] = useSearchParams();
  const viewSettings = accountSettings?.pages_settings?.insights;
  const createWidgetPoster = API.getMutation('insights/preview', 'POST');
  const saveWidgetLayoutPoster = API.getMutation('insights/layout', 'POST');
  const saveWidgetPoster = API.getMutation('insights', 'POST');
  const deleter = API.getMutation('insights', 'DELETE');
  const dashboardDeleter = API.getMutation(
    'insights/delete_dashboard',
    'DELETE'
  );
  const updateWidgetPoster = API.getMutation('insights', 'PUT');
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  let pageLabel = dashboardLabel ?? 'Insights';
  if (viewSettings?.page_label) {
    pageLabel = viewSettings?.page_label;
  }

  if (isFetchedAccountSettings && viewSettings?.show_page === false) {
    return <Navigate to="/settings" />;
  }
  const [widgetModel, setWidgetModel] = useState(null);
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false);
  const { showSnackbar } = useSnackbar();
  const { setPredefinedDashboardName, setPredefinedWidgetSettings } =
    useDashboard();
  const [widgetIdTobeDeleted, setWidgetIdTobeDeleted] = useState(null);
  const [values, setValues] = useState({
    agentGroup: [],
    agent: [],
    compensationType: [],
    tag: [],
    policyStatus: [],
    productType: [],
  });
  const [filteredValues, setFilteredValues] = useState({
    agentGroup: [],
    agent: [],
    compensationType: [],
    tag: [],
    dateRange: 'last60days',
    endDate: dateRanges.last60days().endDate,
    policyStatus: [],
    productType: [],
    startDate: dateRanges.last60days().startDate,
    timePeriod: 'day',
  });

  // load the filtered values from the url query string
  useEffect(() => {
    const filteredValues = {};
    Object.keys(filters).forEach((key) => {
      if (searchParams.has(key)) {
        if (filters[key].type === 'multi-select') {
          filteredValues[key] = searchParams.getAll(key);
        } else {
          filteredValues[key] = searchParams.get(key);
        }
      }
    });
    setFilteredValues((prev) => ({ ...prev, ...filteredValues }));
  }, []);

  const updateFilteredValues = (key, value) => {
    setFilteredValues({ ...filteredValues, [key]: value });
    setSearchParams((prev) => {
      prev.set(key, value);
      return prev;
    });
  };
  const updateUrlAndQuery = (key, value, replace = false) => {
    updateUrl(key, value, replace);
    updateQuery(key, value, replace);
  };
  const updateUrl = (key, value, replace = false) => {
    const values = searchParams.getAll(key);
    if (replace) {
      if (searchParams.has(key) && searchParams.get(key) != value) {
        console.log('replace', key, value);
        setSearchParams((prev) => {
          prev.set(key, value);
          return prev;
        });
        return;
      }
    }
    if (values && values.some((val) => val == value)) {
      return;
    }
    setSearchParams((prev) => {
      prev.append(key, value);
      return prev;
    });
  };

  const updateQuery = (key, value, replace = false) => {
    if (replace) {
      query = query.replace(new RegExp(`${key}=[^&]*`), `${key}=${value}`);
      return;
    }
    if (query.includes(`${key}=${value}`)) {
      return;
    }
    query = query.concat(`&${key}=${value}`);
  };

  const { data: settingsData } = API.getBasicQuery('accounts');

  const { selectedAccount } = useAccountStore();
  const mode = selectedAccount?.accountMode;
  const labels = new UILabels(mode);
  let query = searchParams.toString();
  query.concat(
    `&start_date=${new Date(filteredValues.startDate)
      .toISOString()
      .substring(0, 10)}&end_date=${new Date(filteredValues.endDate)
      .toISOString()
      .substring(0, 10)}`
  );
  if (values?.agentGroup?.length !== filteredValues?.agentGroup?.length) {
    filteredValues.agentGroup.forEach((val) => {
      updateUrlAndQuery('agent_group', val);
    });
  }
  if (
    values?.agent?.length !== filteredValues?.agent?.length &&
    filteredValues.agent
  ) {
    filteredValues.agent.forEach((val) => {
      updateUrlAndQuery('agent', val);
    });
  }
  if (
    values?.compensationType?.length !==
      filteredValues?.compensationType?.length ||
    values?.compensationType?.length !== 0
  ) {
    filteredValues.compensationType.forEach((val) => {
      updateUrlAndQuery('compensation_type', val);
    });
  }

  if (
    values?.tag?.length !== filteredValues?.tag?.length ||
    values?.tag?.length !== 0
  ) {
    filteredValues.tag.forEach((val) => {
      updateUrlAndQuery('tag', val);
    });
  }

  if (
    values?.tag?.length !== filteredValues?.tag?.length ||
    values?.tag?.length !== 0
  ) {
    filteredValues.tag.forEach((val) => {
      query = query.concat(`&tag=${val}`);
    });
  }

  if (values?.policyStatus?.length !== filteredValues?.policyStatus?.length) {
    filteredValues.policyStatus.forEach((val) => {
      updateUrlAndQuery('policy_status', val);
    });
  }

  if (values?.productType?.length !== filteredValues?.productType?.length) {
    filteredValues.productType.forEach((val) => {
      updateUrlAndQuery('product_type', val);
    });
  }

  if (query[query.length - 1] !== '?') {
    query.concat(`&time_period=${filteredValues.timePeriod}`);
  } else {
    query.concat(`time_period=${filteredValues.timePeriod}`);
  }
  if (activeView) {
    updateUrlAndQuery('active_view', activeView, true);
  }

  const createWidget = async (data) => {
    const agent_group = [];
    filteredValues.agentGroup.forEach((val) => {
      agent_group.push(val);
    });
    const compensation_type = [];
    filteredValues.compensationType.forEach((val) => {
      compensation_type.push(val);
    });
    const product_type = [];
    filteredValues.productType.forEach((val) => {
      product_type.push(val);
    });
    const policy_status = [];
    filteredValues.policyStatus.forEach((val) => {
      policy_status.push(val);
    });
    const response = await createWidgetPoster.mutateAsync({
      // Build the below data object here using the query data
      agent: filteredValues.agent,
      agent_group: agent_group?.length ? agent_group : undefined,
      compensation_type: compensation_type.length
        ? compensation_type
        : undefined,
      end_date: new Date(filteredValues.endDate).toISOString().substring(0, 10),
      policy_status: policy_status.length ? policy_status : undefined,
      product_type: product_type.length ? product_type : undefined,
      start_date: new Date(filteredValues.startDate)
        .toISOString()
        .substring(0, 10),
      time_period: filteredValues.timePeriod,
      widgetDefinition: data,
    });
    return response;
  };

  const saveWidget = async () => {
    if (isNull(widgetModel)) {
      return;
    }
    setLoadingConfig({
      loading: true,
      message: 'Saving widget...',
    });
    let response;
    try {
      if (isEditingMode) {
        response = await updateWidgetPoster.mutateAsync({
          id: widgetOnEdit.id,
          name: widgetModel.name,
          spec: widgetModel.spec,
          accessRoles: widgetModel.accessRoles,
        });
      } else {
        response = await saveWidgetPoster.mutateAsync({
          name: widgetModel.name,
          spec: widgetModel.spec,
          accessRoles: widgetModel.accessRoles,
        });
      }
    } catch (e) {
      showSnackbar('Failed to save widget', 'error');
    }
    setLoadingConfig({
      loading: false,
    });

    switch (response.widgetGroup) {
      case WidgetGroup.BOX:
        setBoxes((prev) => [...prev, response]);
        break;
      case WidgetGroup.CHART:
        setCharts((prev) => [...prev, response]);
        break;
      case WidgetGroup.TABLE:
        setTables((prev) => [...prev, response]);
        break;
    }
  };

  const saveLayout = async () => {
    setLoadingConfig({
      loading: true,
      message: 'Saving layout...',
    });
    try {
      await saveWidgetLayoutPoster.mutateAsync({
        layout: layouts,
        dashboardId: insightsData?.dashboardId,
        role_id: activeView ?? userRole,
      });
    } catch (e) {
      showSnackbar('Failed to save layout', 'error');
    }

    setLoadingConfig({
      loading: false,
    });
  };
  const [insightsData, setInsightsData] = useState({});
  const [widgetArray, setWidgetArray] = useState([]);

  const {
    isLoading: isLoadingInsights,
    data: result,
    isFetched: isFetchedInsights,
  } = API.getBasicQuery('insights', query);

  useEffect(() => {
    if (isFetchedInsights) {
      setInsightsData(result);
      const layout = result.dashboardSettings?.layout
        ? result.dashboardSettings?.layout[activeView]
        : null;
      buildWidgetArray(
        [
          ...(result && result.boxes ? result.boxes : []),
          ...(result && result.charts ? result.charts : []),
          ...(result && result.tables ? result.tables : []),
        ],
        layout
      );
    }
  }, [result]);
  const buildWidgetArray = (data, layout) => {
    const currentBreakpoint = Object.keys(breakpoints).find((breakpoint) => {
      return window.innerWidth > breakpoints[breakpoint];
    });

    const totalCols = cols[currentBreakpoint];
    let tempArray = [];
    let boxIndex = 0;
    let chartTableIndex = 0;
    data.map((item, index) => {
      const arrayItem = {
        ...item,
        i: '' + item.id,
      };
      if (item.widgetGroup === WidgetGroup.BOX) {
        arrayItem.w = 2;
        arrayItem.h = 1;
        arrayItem.x = (boxIndex * 2) % totalCols;
        arrayItem.y = Math.floor((boxIndex * 2) / totalCols);
        boxIndex++;
      } else {
        arrayItem.w = 4;
        arrayItem.h = 2;
        arrayItem.x = (chartTableIndex * 4) % totalCols;
        arrayItem.y = Math.floor(
          (boxIndex * 4 + chartTableIndex * 4) / totalCols
        );
        chartTableIndex++;
      }

      tempArray.push(arrayItem);
    });
    if (layout) {
      layout?.map((position) => {
        tempArray.map((data) => {
          if (data.i === position.i) {
            data.x = position.x;
            data.y = position.y;
            data.w = position.w;
            data.h = position.h;
          }
        });
      });
    }
    const lastX = tempArray.sort((a, b) => b.x - a.x)[0];
    const lastY = tempArray.sort((a, b) => b.y - a.y)[0];
    tempArray.push({
      i: 'new',
      x: (lastX ? lastX.x + lastX.w : 0) % totalCols,
      y: (lastY ? lastY.y : 0) + 1,
      w: 2,
      h: 1,
    });
    setWidgetArray(tempArray);
  };
  const handleModify = (currentLayout, allLayouts) => {
    setLayouts(currentLayout);
    setShowSaveLayoutButton(true);
  };

  const renderViewChip = (role) => {
    let label = '';
    switch (role) {
      case Roles.ACCOUNT_ADMIN:
        label = 'Account admin view';
        break;
      case Roles.PRODUCER:
        label = 'Producer view';
        break;
      case Roles.DATA_SPECIALIST:
        label = 'Data specialist view';
        break;
      default:
        return null;
    }
    return (
      <Chip
        sx={{
          m: 0.2,
        }}
        clickable
        color={role === activeView ? 'primary' : 'default'}
        onClick={() => setActiveView(role)}
        label={label}
        variant={role === activeView ? '' : 'outlined'}
      />
    );
  };

  useEffect(() => {
    if (
      (isFetchedInsights &&
        !isEqual(values.agentGroup, insightsData?.agentGroup)) ||
      !isEqual(values.agent, insightsData?.filterValues?.agent) ||
      !isEqual(
        values.compensationType,
        insightsData?.filterValues?.compensationType
      ) ||
      !isEqual(values.tag, insightsData?.filterValues?.tag) ||
      !isEqual(values.policyStatus, insightsData?.filterValues?.policyStatus) ||
      !isEqual(values.productType, insightsData?.filterValues?.productType)
    ) {
      setValues((prev) => ({
        ...prev,
        agentGroup: insightsData?.filterValues?.agentGroup,
        agent: insightsData?.filterValues?.agent,
        compensationType: insightsData?.filterValues?.compensationType,
        tag: insightsData?.filterValues?.tag,
        policyStatus: insightsData?.filterValues?.policyStatus,
        productType: insightsData?.filterValues?.productType,
      }));
      if (
        filteredValues.agent === undefined ||
        filteredValues.agent.length === 0
      ) {
        setFilteredValues((prev) => ({
          ...prev,
          agent: insightsData?.filterValues?.agent?.map((e) => e.id) ?? '',
        }));
      }

      if (
        filteredValues.agentGroup === undefined ||
        filteredValues.agentGroup.length === 0
      ) {
        setFilteredValues((prev) => ({
          ...prev,
          agentGroup:
            insightsData?.filterValues?.agentGroup?.map((e) => e.id) ?? '',
        }));
      }
    }
  }, [isFetchedInsights, insightsData]);

  useEffect(() => {
    if (filteredValues.dateRange !== 'custom') {
      setFilteredValues({
        ...filteredValues,
        ...dateRanges[filteredValues.dateRange](),
      });
    }
  }, [filteredValues.dateRange]);

  const isLoading = isLoadingInsights;

  const [boxes, setBoxes] = useState([]);
  const [charts, setCharts] = useState([]);
  const [tables, setTables] = useState([]);

  useEffect(() => {
    if (!widgetModel || widgetOnEdit) {
      return;
    }
    let layoutPlaceHolder = widgetArray.filter((item) => item.i === 'new')?.[0];
    if (!layoutPlaceHolder) {
      return;
    }
    let layout = widgetArray.filter((item) => item.i !== 'new');
    layoutPlaceHolder = {
      ...layoutPlaceHolder,
      ...widgetModel,
      i: 'preview',
    };
    layout.push(layoutPlaceHolder);
    setWidgetArray(layout);
  }, [widgetModel]);

  useEffect(() => {
    if (isFetchedInsights) {
      if (dashboardName) {
        setBoxes(insightsData?.boxes);
        setCharts(insightsData?.charts);
        setTables(insightsData?.tables);
      } else {
        setBoxes(insightsData?.boxes ?? []);
        setCharts(insightsData?.charts ?? []);
        setTables(insightsData?.tables ?? []);
      }
    }
  }, [isFetchedInsights, insightsData]);

  const filteredFilters = Object.fromEntries(
    Object.entries(filters).filter(
      ([k, v]) =>
        !['startDate', 'endDate'].includes(k) ||
        filteredValues.dateRange === 'custom'
    )
  );

  const [sideDrawerOpen, setsideDrawerOpen] = useState(false);

  const openAddWidgetDialog = (mode) => {
    setSideDrawerMode(mode);
    setIsEditingMode(false);
    setWidgetOnEdit(null);
    setsideDrawerOpen(true);
  };

  const copyExistingWidget = (widget) => {
    setWidgetOnEdit(widget);
    setSideDrawerMode(DrawerMode.CREATE_WIDGET);
    setIsEditingMode(false);
    setsideDrawerOpen(true);
  };

  const editWidget = (id) => {
    const widget = [...boxes, ...charts, ...tables].find((e) => e.id === id);
    setWidgetOnEdit({ ...widget.spec, id: widget.id, spec: widget.spec });
    setIsEditingMode(true);
    setSideDrawerMode(DrawerMode.CREATE_WIDGET);
    setsideDrawerOpen(true);
  };

  const renderDrawerContent = (mode) => {
    switch (mode) {
      case DrawerMode.CREATE_WIDGET:
        return (
          <WidgetCreator
            widgetOnEdit={widgetOnEdit}
            createWidget={createWidget}
            setWidgetModel={setWidgetModel}
          />
        );
      case DrawerMode.ADD_SHARED_WIDGET:
        return (
          <WidgetSelector
            sharedWidgets={insightsData?.sharedWidgets}
            dashboardSettings={insightsData?.dashboardSettings}
            closeAddWidgetDialog={closeAddWidgetDialog}
            dashboardName={dashboardName}
          />
        );
      case DrawerMode.ADD_NEW_PAGE:
        return (
          <SubPageCreator
            closeAddWidgetDialog={closeAddWidgetDialog}
            sharedWidgets={insightsData?.sharedWidgets}
          />
        );
      default:
        return null;
    }
  };

  const cloneDashboard = () => {
    setPredefinedDashboardName(`${dashboardName} (copy)`);
    setPredefinedWidgetSettings(insightsData?.dashboardSettings);
    setsideDrawerOpen(true);
    setSideDrawerMode(DrawerMode.ADD_NEW_PAGE);
    handleMenuClose();
  };

  const deleteDashboard = async () => {
    const res = await dashboardDeleter.mutateAsync({
      dashboardName: dashboardName,
      dashboardId: insightsData?.dashboardId,
    });
    if (res.error) {
      showSnackbar('Failed to delete dashboard', 'error');
      return;
    }
    navigate('/insights');
  };

  const deleteWidget = async () => {
    const res = await deleter.mutateAsync({ id: widgetIdTobeDeleted });
    if (res.error) {
      showSnackbar('Failed to delete widget', 'error');
      return;
    }
    setBoxes((prev) => prev.filter((e) => e.id !== widgetIdTobeDeleted));
    setCharts((prev) => prev.filter((e) => e.id !== widgetIdTobeDeleted));
    setTables((prev) => prev.filter((e) => e.id !== widgetIdTobeDeleted));
    setDeleteConfirmDialogOpen(false);
  };

  const closeAddWidgetDialog = () => {
    setsideDrawerOpen(false);
  };

  const renderWidget = (widget) => {
    switch (widget.widgetGroup) {
      case WidgetGroup.BOX:
        return (
          <BoxWidget
            id={widget.id}
            displayName={
              labels.getLabel('dashboard', widget.labelId) ?? widget.displayName
            }
            value={widget.value}
          />
        );
      case WidgetGroup.CHART:
        return (
          <ChartWidget
            id={widget.id}
            displayName={
              labels.getLabel('dashboard', widget.labelId) ?? widget.displayName
            }
            data={widget.value}
          />
        );
      case WidgetGroup.TABLE:
        return (
          <TableWidget
            data={widget.data}
            formatters={widget.formatters}
            id={widget.id}
            displayName={
              labels.getLabel('dashboard', widget.labelId) ?? widget.displayName
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box p={2} sx={{ width: '100%', overflowY: 'scroll' }}>
      <Drawer
        anchor="right"
        variant="persistent"
        open={sideDrawerOpen}
        onClose={closeAddWidgetDialog}
        sx={{
          '& .MuiDrawer-paper': {
            marginTop: '64px',
            maxWidth: sidebarWidth,
          },
        }}
      >
        {/* action bar to close the drawer and save the widget */}

        <Tabs
          value={sideDrawerMode}
          onChange={(e, value) => {
            setSideDrawerMode(value);
          }}
        >
          <Tab label="Create widget" value={DrawerMode.CREATE_WIDGET} />
          <Tab label="Config widgets" value={DrawerMode.ADD_SHARED_WIDGET} />
          <Tab label="Add new page" value={DrawerMode.ADD_NEW_PAGE} />
        </Tabs>
        {renderDrawerContent(sideDrawerMode)}
        {sideDrawerMode === DrawerMode.CREATE_WIDGET && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px',
              borderBottom: '1px solid #e0e0e0',
              position: 'fixed',
              bottom: '0',
              width: '100%',
              background: 'inherit',
            }}
          >
            <Box>
              <Button variant="outlined" onClick={() => closeAddWidgetDialog()}>
                Close
              </Button>
              <Button
                variant="contained"
                sx={{ marginLeft: '10px' }}
                onClick={() => {
                  saveWidget();
                  closeAddWidgetDialog();
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        )}
      </Drawer>
      <Box
        sx={{
          width: sideDrawerOpen ? `calc(100% - ${sidebarWidth}px)` : '100%',
        }}
      >
        <Box display="flex">
          <Typography variant="h5">
            {settingsData?.company
              ? `${settingsData?.company} ${(insightsData?.dashboardLabel ?? pageLabel)?.toLowerCase()}`
              : (insightsData?.dashboardLabel ?? pageLabel)}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'baseline',
              padding: '10px',
              overflowX: 'scroll',
            }}
          >
            {Object.entries(filteredFilters)?.map(([k, v]) => {
              if (v.type === 'date') {
                return (
                  <div style={{ width: '100%' }}>
                    <BasicDatePicker
                      label={v.label}
                      key={v.label}
                      value={filteredValues[k]}
                      setValue={
                        (e) => updateFilteredValues(k, e)
                        // setFilteredValues({ ...filteredValues, [k]: e })
                      }
                      sx={{ ml: 1, width: 200 }}
                    />
                  </div>
                );
              } else if (v.type === 'multi-select') {
                if (
                  k === 'agent' &&
                  settingsData?.dashboard_filter_by_agent !== 'True'
                )
                  return null;
                if (k === 'agent' || k === 'agentGroup')
                  return (
                    <MultiSelect
                      label={v.label}
                      values={values?.[v.optionsKey] ?? []}
                      valuer={(val) => val.id}
                      selectedValues={
                        filteredValues[k]?.length
                          ? filteredValues[k]
                          : values?.[v.optionsKey]?.id
                      }
                      setSelectedValues={(values) => {
                        setFilteredValues({
                          ...filteredValues,
                          [k]: values,
                        });
                      }}
                      sx={{ width: 135, ml: 1 }}
                      key={v.label}
                      formatter={(val) => val.name}
                    />
                  );
                return (
                  <MultiSelect
                    label={v.label}
                    values={values?.[v.optionsKey] ?? []}
                    selectedValues={
                      filteredValues[k]?.length
                        ? filteredValues[k]
                        : values?.[v.optionsKey]
                    }
                    setSelectedValues={(values) =>
                      setFilteredValues({
                        ...filteredValues,
                        [k]: values,
                      })
                    }
                    sx={{ width: 135, ml: 1 }}
                    key={v.label}
                  />
                );
              } else if (v.type === 'select') {
                return (
                  <div key={v.label}>
                    <FormControl
                      sx={{ ml: 1, width: 135, ...v.sx }}
                      key={v.label}
                    >
                      <InputLabel>{v.label}</InputLabel>
                      <Select
                        value={filteredValues[k]}
                        label={v.label}
                        onChange={(e) =>
                          // setFilteredValues({
                          //   ...filteredValues,
                          //   [k]: e.target.value,
                          // })
                          updateFilteredValues(k, e.target.value)
                        }
                        sx={{
                          '.MuiSelect-select': {
                            py: 0.75,
                            px: 1.5,
                          },
                        }}
                      >
                        {(v.options ?? []).map((option) => (
                          <MenuItem value={option.value} key={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                );
              }
              return null;
            })}
            <Dialog
              style={{ zIndex: 9999 }}
              open={deleteConfirmDialogOpen}
              onClose={() => setDeleteConfirmDialogOpen(false)}
            >
              <DialogTitle>{'Do you want to delete this widget?'}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {'This action cannot be undone.'}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDeleteConfirmDialogOpen(false)}>
                  Disagree
                </Button>
                <Button onClick={deleteWidget} autoFocus>
                  Agree
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
        <Box>
          {(isFinatryAdmin || isAccountAdmin) && (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                gap: 1,
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                {renderViewChip(Roles.ACCOUNT_ADMIN)}
                {renderViewChip(Roles.PRODUCER)}
                {renderViewChip(Roles.DATA_SPECIALIST)}
              </Box>
              <Box>
                {showSaveLayoutButton && (
                  <Button variant="contained" onClick={saveLayout}>
                    {' '}
                    Save Layout{' '}
                  </Button>
                )}
                <Button
                  variant="contained"
                  sx={{ marginLeft: 1, maxHeight: 4 }}
                  onClick={() => openAddWidgetDialog(DrawerMode.CREATE_WIDGET)}
                >
                  Add
                </Button>
                <IconButton
                  sx={{ alignSelf: 'flex-start' }}
                  onClick={handleMenuClick}
                >
                  <MoreVert />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem key="Delete" onClick={deleteDashboard}>
                    Delete dashboard
                  </MenuItem>
                  <MenuItem key="copy" onClick={cloneDashboard}>
                    Create copy
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          )}
        </Box>
        <Box>
          {isLoading ? (
            <LoadingCircle isLoading={isLoading} />
          ) : (
            <>
              <ResponsiveGridLayout
                onLayoutChange={handleModify}
                verticalCompact={true}
                cols={cols}
                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                preventCollision={false}
                draggableHandle=".dragHandle"
                autoSize={true}
                margin={margin}
              >
                {widgetArray?.map((widget, index) => {
                  return (
                    <div
                      className="reactGridItem"
                      key={widget.i}
                      data-grid={{
                        x: widget?.x,
                        y: widget?.y,
                        w: widget?.w,
                        h: widget?.h,
                        i: widget?.i,
                        id: widget?.id,
                        minW: 2,
                        maxW: Infinity,
                        maxH: Infinity,
                        isDraggable: true,
                        isResizable: true,
                      }}
                    >
                      {widget.i === 'new' ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '1px dashed #ccc',
                            borderRadius: '5px',
                            height: '100%',
                            '&:hover': {
                              cursor: 'pointer',
                              border: '1px dashed #000',
                            },
                          }}
                          onClick={() =>
                            openAddWidgetDialog(DrawerMode.CREATE_WIDGET)
                          }
                        >
                          <Tooltip title="Add new widget">
                            <AddIcon
                              sx={{
                                '&:hover': {
                                  color: 'primary.main',
                                  cursor: 'pointer',
                                },
                              }}
                            />
                          </Tooltip>
                        </Box>
                      ) : (
                        <WidgetWrapper
                          id={widget.id}
                          sharedWidget={widget.spec?.shared}
                          displayName={
                            labels.getLabel('dashboard', widget.labelId) ??
                            widget.displayName
                          }
                          onDelete={() => {
                            setDeleteConfirmDialogOpen(true);
                            setWidgetIdTobeDeleted(widget.id);
                          }}
                          onEdit={() => editWidget(widget.id)}
                          data={widget.value}
                          type={widget.type}
                          widgetData={widget}
                          onCopy={copyExistingWidget}
                        >
                          {renderWidget(widget)}
                        </WidgetWrapper>
                      )}
                    </div>
                  );
                })}
              </ResponsiveGridLayout>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
