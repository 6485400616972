const getIndices = (array: string[][], colNames: string[]) => {
  const indices: number[] = [];
  const headers = array[0];

  colNames.forEach((colName) => {
    const idx = headers.indexOf(colName);
    if (idx >= 0) indices.push(idx);
    else console.warn(`Could not find a column named ${colName} in header row`);
  });

  indices.sort((a, b) => b - a); // Work backwards so indices don't change

  return indices;
};

export const desc_getIndices = {
  name: 'libs.tools.getIndices(array, colNames)',
  description:
    'Gets the column indices for specified column names from a 2D array, using the first row as headers.',
  arguments: {
    array: '[Array<Array<string>>] 2D array where first row contains headers',
    colNames: '[Array<string>] Array of column names to find indices for',
  },
  returns: '[Array<number>] Array of column indices in descending order',
  examples:
    'getIndices([\n  ["Name", "Age", "City"],\n  ["John", "30", "NY"]\n], ["City", "Name"])\n// → [2, 0]\n',
};

export default getIndices;
