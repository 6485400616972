import { Box, Chip, Typography } from '@mui/material';
import React from 'react';
import { DataEntities, DataUpdateRule } from 'common/globalTypes';

import { fieldMatcherActions } from '@/components/molecules/ActionPicker';
import { Fields } from '@/components/ToolsPage/DataUpdateTools/types';
import { fieldMatcherOptions } from '@/components/molecules/FieldMatcher';

interface RuleViewerProps {
  fieldData: DataUpdateRule | DataUpdateRule[];
  rowData: any;
  fields: Fields;
}

const CriteriaActionsViewer: React.FC<RuleViewerProps> = ({
  fieldData,
  rowData,
  fields,
}) => {
  if (!fieldData) {
    return <Chip label="Not available" />;
  }
  const dataEntityFields = fields[rowData.data_entity as DataEntities] || {};
  const getFieldLabel = (fieldId: string) => {
    const field = Object.values(dataEntityFields).find(
      (field) => (field as any).id === fieldId
    );
    return field ? (field as any).label : fieldId;
  };

  const getOperatorLabel = (operatorString: string) => {
    const operator = Object.values(fieldMatcherOptions).find(
      (action) => action.id === operatorString
    );
    return operator
      ? (operator.shortLabel ?? operator.label ?? operator)
      : operator;
  };

  return (
    <Box sx={{ whiteSpace: 'nowrap' }}>
      {!Array.isArray(fieldData) && fieldData.action ? (
        <Box>
          {fieldData.field && (
            <Box sx={{ mb: 1 }}>
              <Chip label={`${getFieldLabel(fieldData.field)}`} />
            </Box>
          )}
          <Chip
            label={`${
              fieldMatcherActions.find(
                (action) => action.id === fieldData.action
              )?.label
            } `}
          />
          <span style={{ marginLeft: 4, marginRight: 4 }}>to</span>
          <Chip label={`${fieldData.value}`} />
        </Box>
      ) : Array.isArray(fieldData) && fieldData.length > 0 ? (
        fieldData.map((rule, index) => (
          <Box key={index} sx={{ mb: 1 }}>
            {rule.field && (
              <Chip label={`${getFieldLabel(rule.field)}`} sx={{ mr: 0.5 }} />
            )}
            <span style={{ marginRight: 4 }}>
              {rule.op && getOperatorLabel(rule.op)}
            </span>
            <Chip label={`${rule.value}`} sx={{ mr: 0.5 }} />
          </Box>
        ))
      ) : (
        <Chip label="Not available" />
      )}
    </Box>
  );
};

export default CriteriaActionsViewer;
