import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sha256 } from 'crypto-hash';
import { nanoid } from 'nanoid';

import API from '@/services/API';
import { readFile } from '@/services/helpers';
import { auth } from '@/firebase';
import FactoryFileUpload from '@/common/Uplaod/FactoryFileUpload';
import useUploadStorageFile from '@/contexts/useUploadStorageFile';
import useSnackbar from '@/contexts/useSnackbar';
import { useAccountStore } from '@/store';

interface DocumentOverrideFileProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  uploadedRow: any;
}

const DocumentOverrideFile = ({
  open,
  setOpen,
  uploadedRow,
}: DocumentOverrideFileProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { selectedAccount } = useAccountStore();

  const { showSnackbar } = useSnackbar();

  const documentsPutter = API.getMutation('documents', 'PUT');
  const { uploadFilesByGenerateSignedUrl } = useUploadStorageFile();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const refreshPage = () => {
    const currentPath = '/documents';
    navigate('/');
    setTimeout(() => {
      navigate(currentPath);
    });
  };

  const uploadFile = async () => {
    if (!file || !selectedAccount) return null;
    const filename = `${nanoid()}-${file.name}`;
    const filePath = `uploads/${selectedAccount?.accountId}/${filename}`;

    setLoading(true);

    const fileContent = await readFile(file);
    const fileHash = await sha256(fileContent);

    const _ft = filePath.split('.').pop()?.toLowerCase() || '';
    const fileType = ['xls', 'xlsx', 'csv'].includes(_ft) ? 'spreadsheet' : _ft;
    const param = {
      id: uploadedRow.id,
      override_file_path: filePath,
      override_file_hash: fileHash,
      status: 'new',
      company_str_id: uploadedRow.company_str_id,
      override_filename: file.name,
      str_id: uploadedRow.str_id,
      file_type: fileType,
    };
    const res = await documentsPutter.mutateAsync(param);

    await uploadFilesByGenerateSignedUrl([
      {
        endpoint: 'documents',
        endpoint_str_id: uploadedRow.str_id,
        file_preview_type: 'override',
        file: file,
      },
    ]);

    setLoading(false);
    if (res.error) {
      Sentry.captureException(res.error);
      showSnackbar(res.error, 'error');
      return null;
    }
    setOpen(false);
    refreshPage();
  };

  const handleFileChange = (files) => {
    const _file = files[0];
    setFile(_file);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open Form Dialog
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload override file</DialogTitle>
        <Divider />
        <DialogContent
          sx={{
            width: '600px',
          }}
        >
          <FactoryFileUpload
            label="Override file"
            required
            onChange={handleFileChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            loading={loading}
            disabled={!file}
            sx={{ width: '100px' }}
            onClick={uploadFile}
            variant="contained"
          >
            Upload
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DocumentOverrideFile;
