import { useSearchParams } from 'react-router-dom';

import { DocumentPreviewKeys } from '@/types';

const usePreviewParams = () => {
  const [searchParams, setSearchParams] = useSearchParams({});

  const setShowPreview = (val: boolean) =>
    setSearchParams((prev) => {
      if (val) {
        prev.set('m', DocumentPreviewKeys.PREVIEW);
      } else {
        prev.delete('m');
        prev.delete('id');
        prev.delete('t');
      }
      return prev;
    });

  const setPreviewPath = (val: string, previewType?, isId = true) =>
    setSearchParams((prev) => {
      if (val) {
        if (isId) prev.set('id', val);
        prev.set('id', val);
        if (previewType === DocumentPreviewKeys.ORIGINAL)
          prev.set('t', DocumentPreviewKeys.ORIGINAL);
        else if (previewType === DocumentPreviewKeys.OVERRIDE)
          prev.set('t', DocumentPreviewKeys.OVERRIDE);
      } else {
        prev.delete('id');
        prev.delete('t');
      }
      return prev;
    });

  const showPreview = searchParams.get('m') === DocumentPreviewKeys.PREVIEW;
  const previewId = searchParams.get('id') ?? '';
  const previewType = searchParams.get('t');

  return {
    showPreview,
    setShowPreview,
    previewId,
    setPreviewPath,
    previewType,
  };
};

export default usePreviewParams;
