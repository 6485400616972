import { useState } from 'react';
import { captureException } from '@sentry/react';

import API from '@/services/API';
import useSnackbar from '@/contexts/useSnackbar';
import { getMimeType } from '@/services/helpers';

export const useUploadStorageFileV2 = () => {
  const fileSignedUrlLoader = API.getMutation('storage/getSignedUrl', 'POST');
  const [uploadStatus, setUploadStatus] = useState<{
    [filename: string]: UploadState;
  }>({});
  const { showSnackbar } = useSnackbar();

  type StorageFileParams = StorageSignedUrlParams & {
    file: File;
  };

  const uploadFile = async ({
    endpoint,
    endpoint_str_id,
    action,
    file,
    file_preview_type,
  }: StorageFileParams) => {
    try {
      setUploadStatus((prev) => ({ ...prev, [file.name]: 'uploading' }));

      const params: StorageSignedUrlParams = {
        endpoint,
        endpoint_str_id,
        file_preview_type,
        action,
      };
      const { data: signedUrl } = await fileSignedUrlLoader.mutateAsync(params);

      const mimeType = getMimeType(file.name);
      const headers = new Headers({
        'content-type': mimeType,
        'content-length': file.size.toString(),
      });
      const res = await fetch(signedUrl, {
        method: 'PUT',
        headers: headers,
        body: file,
      });

      if (!res.ok) {
        showSnackbar(
          `Failed to upload file, error: ${res.status} ${res.statusText} `,
          'error'
        );
        setUploadStatus((prev) => ({ ...prev, [file.name]: 'failed' }));
        return;
      }

      setUploadStatus((prev) => ({ ...prev, [file.name]: 'done' }));
    } catch (error: any) {
      console.error(error.message || error);
      captureException(error);
      showSnackbar(`Failed to upload file: ${error}`, 'error');
      setUploadStatus((prev) => ({ ...prev, [file.name]: 'failed' }));
      throw error;
    }
  };

  return { uploadFile, uploadStatus };
};
