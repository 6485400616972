import { useCallback, useEffect, useState } from 'react';
import { captureException } from '@sentry/react';

import API from '@/services/API';
import useSnackbar from '@/contexts/useSnackbar';

const useStorageSignedUrl = () => {
  const [signedUrlParams, setSignedUrlParams] =
    useState<StorageSignedUrlParams>();

  const [signedUrl, setSignedUrl] = useState<string | null>(null);
  const getSignedUrlPoster = API.getMutation('storage/getSignedUrl', 'POST');
  const { showSnackbar } = useSnackbar();

  const getSignedUrl = useCallback(
    async (option: StorageSignedUrlParams) => {
      const { endpoint_str_id, action } = option;
      try {
        if (action === 'read' && !endpoint_str_id) {
          setSignedUrl(null);
          return '';
        }
        const res = await getSignedUrlPoster.mutateAsync({ ...option });
        setSignedUrl(res.data);
        return res.data;
      } catch (error: any) {
        console.error(error.message || error);
        captureException(error);
        showSnackbar(error.message || error, 'error');
        setSignedUrl(null);
        return '';
      }
    },
    [showSnackbar]
  );

  useEffect(() => {
    if (!signedUrlParams) return;
    getSignedUrl(signedUrlParams);
  }, [getSignedUrl, signedUrlParams]);

  return { signedUrl, setSignedUrlParams, getSignedUrl };
};

export default useStorageSignedUrl;
