import { useEffect, useState } from 'react';
import { useOriginalFile, useOverrideFile } from 'store/excelStore';
import PropTypes from 'prop-types';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Launch } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import Spreadsheet from '@/services/Spreadsheet';
import FilePreview from '@/common/preview';
import { XLS_CSV_TYPES, SpreadsheetModel } from '@/common/preview/model';
import { DocumentPreviewKeys } from '@/types';

const ProcessPreview = ({ previewWidth, rowData, processForm }) => {
  const [currentPreviewFile, setCurrentPreviewFile] = useState<File>();
  const [spreadsheet, setSpreadsheet] = useState<SpreadsheetModel>();

  const originalFile = useOriginalFile();
  const overrideFile = useOverrideFile();

  useEffect(() => {
    const target = originalFile || overrideFile;
    const doChange = async (file) => {
      if (!file) {
        return;
      }
      setCurrentPreviewFile(file);
    };
    doChange(target);
  }, [originalFile, overrideFile]);

  useEffect(() => {
    // Excel, csv need to load the raw data
    const setExcelData = async () => {
      if (
        currentPreviewFile &&
        XLS_CSV_TYPES.includes(currentPreviewFile.type)
      ) {
        const res = (await Spreadsheet.loadSpreadsheet(
          currentPreviewFile
        )) as unknown as SpreadsheetModel;
        setSpreadsheet(res);
      }
    };
    setExcelData();
  }, [currentPreviewFile]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          position: 'absolute',
          zIndex: 100,
        }}
      >
        {rowData && (
          <Tooltip
            title={
              rowData.override_file_path?.split('/').pop() || rowData.filename
            }
          >
            <IconButton
              to={`/documents?id=${rowData.str_id}&t=${rowData.override_file_path ? DocumentPreviewKeys.OVERRIDE : DocumentPreviewKeys.ORIGINAL}&m=preview`}
              component={Link}
              sx={{ opacity: 0.8, '&:hover': { opacity: 1 } }}
              target="_blank"
            >
              <Launch />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Box sx={{ flex: 1, overflow: 'auto' }}>
        <FilePreview
          previewFile={currentPreviewFile}
          previewWith={previewWidth}
          sheet={processForm.selectedSheet}
          spreadsheet={spreadsheet}
          setSpreadsheet={setSpreadsheet}
        />
      </Box>
    </Box>
  );
};

ProcessPreview.propTypes = {
  previewWidth: PropTypes.number,
};
ProcessPreview.displayName = 'ProcessPreview';

export default ProcessPreview;
