const isValidDateFormat = (
  dateStr: string,
  strict: boolean = false,
  allowYearless: boolean = false
): boolean => {
  // Regex patterns for different date formats
  const fullDatePattern = strict
    ? /^(?:(\d{4})[-/](\d{2})[-/](\d{2})|(\d{2})[-/](\d{2})[-/](\d{4}))$/
    : /(?:(\d{4})[-/](\d{2})[-/](\d{2})|(\d{2})[-/](\d{2})[-/](\d{4}))/;

  const yearlessDatePattern = strict
    ? /^(\d{2})[-/](\d{2})$/
    : /(\d{2})[-/](\d{2})/;

  if (allowYearless) {
    return yearlessDatePattern.test(dateStr) || fullDatePattern.test(dateStr);
  }

  return fullDatePattern.test(dateStr);
};

export const desc_isValidDateFormat = {
  name: 'libs.tools.isValidDateFormat(dateStr, strict, allowYearless)',
  description: 'Validates if a string matches common date format patterns.',
  arguments: {
    dateStr: '[String] Date string to validate',
    strict: '[Boolean] Optional. If true, matches entire string exactly',
    allowYearless: '[Boolean] Optional. If true, allows MM/DD format',
  },
  returns: '[Boolean] True if string matches a valid date format',
  examples:
    'isValidDateFormat("12/31/2023")\n// → true\n\nisValidDateFormat("12/31", false, true)\n// → true\n\nisValidDateFormat("2023-12-31", true)\n// → true\n',
};

export default isValidDateFormat;
