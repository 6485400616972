import { SyncOutlined } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';

export const SyncedEntity = (props: {
  children?: React.ReactNode;
  isSynced: boolean | (() => boolean);
  disabled?: boolean;
}) => {
  const { children, isSynced: _isSynced, disabled } = props;
  const isSynced = typeof _isSynced === 'function' ? _isSynced() : _isSynced;
  return (
    <Tooltip title="This record is synced from your AMS/CRM">
      <Box
        style={{
          cursor: disabled ? 'default' : 'pointer',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {isSynced && <SyncOutlined color={disabled ? 'disabled' : 'primary'} />}
        {children}
      </Box>
    </Tooltip>
  );
};
