import { ContentCopy } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import copy from 'copy-to-clipboard';
import { useEffect, useState } from 'react';

import API from '@/services/API';
import CopyButton from '@/components/atoms/CopyButton';
import useSnackbar from '@/contexts/useSnackbar';

const GenerateLink = () => {
  const [linkParams, setLinkParams] = useState<any>({
    amount: 0,
    paymentTypes: [],
  });
  return (
    <FormGroup
      sx={{
        display: 'flex',
        flexDirection: 'row',
        mb: 1,
        justifyContent: 'center',
      }}
    >
      <TextField
        label="Amount"
        value={linkParams.amount}
        sx={{ mr: 1, width: 160 }}
      />
      <FormControl sx={{ mr: 1, minWidth: 180 }}>
        <InputLabel>Payment methods</InputLabel>
        <Select
          label="Payment methods"
          multiple
          value={linkParams.paymentTypes}
          onChange={({ target: { value } }) => {
            setLinkParams({
              ...linkParams,
              paymentTypes:
                typeof value === 'string' ? value.split(',') : value,
            });
          }}
        >
          <MenuItem value={'ACH'}>ACH</MenuItem>
          <MenuItem value={'CREDIT_CARD'}>Credit Card</MenuItem>
        </Select>
      </FormControl>

      <Button variant="contained" disabled>
        Generate link
      </Button>
    </FormGroup>
  );
};

// Form that takes in a url and has a button to call backend api to disable other stripe payment methods
const UpdatePamentLink = () => {
  const [paymentLinkId, setPaymentLinkId] = useState('');
  const [loading, setLoading] = useState(false);
  // const { data: paymentLinks } = API.getBasicQuery('stripe/payment_links');
  const poster = API.getMutation('stripe/payment_links', 'POST');

  return (
    <Box>
      <TextField
        label="Payment Id"
        value={paymentLinkId}
        onChange={({ target: { value } }) => setPaymentLinkId(value)}
        sx={{ width: 400, mr: 1 }}
      />
      <LoadingButton
        loading={loading}
        variant="outlined"
        onClick={async () => {
          setLoading(true);
          await poster.mutateAsync({
            paymentLinkId,
            paymentMethods: ['us_bank_account'],
          });
          setLoading(false);
        }}
        sx={{ mr: 1 }}
      >
        ACH only
      </LoadingButton>
      <LoadingButton
        loading={loading}
        variant="outlined"
        onClick={async () => {
          setLoading(true);
          await poster.mutateAsync({
            paymentLinkId,
            paymentMethods: ['us_bank_account', 'card', 'link'],
          });
          setLoading(false);
        }}
      >
        Enable credit card
      </LoadingButton>
    </Box>
  );
};

const PaymentLinks = () => {
  const [links, setLinks] = useState<string[]>([]);
  const [loading, setLoading] = useState({});
  const { data: paymentLinks } = API.getBasicQuery('stripe/payment_links');
  const poster = API.getMutation('stripe/payment_links', 'POST');
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    setLinks(paymentLinks || []);
  }, [paymentLinks]);

  return (
    <Box>
      <Box sx={{ mb: 1, textAlign: 'center' }}>
        {/* <GenerateLink /> */}
        <UpdatePamentLink />
      </Box>
      <Typography variant="h6">Payment links</Typography>
      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Link</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {links.map((link: any) => (
                <TableRow key={link.id}>
                  <TableCell
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    {link.id}
                    <CopyButton value={link.id} />
                  </TableCell>
                  <TableCell>
                    <Link href={link.url} target="_blank">
                      {link.url}
                    </Link>
                    <CopyButton value={link.url} />
                  </TableCell>
                  <TableCell>
                    <LoadingButton
                      loading={loading[link.id]}
                      variant="outlined"
                      onClick={async () => {
                        try {
                          setLoading({ ...loading, [link.id]: true });
                          await poster.mutateAsync({
                            paymentLinkId: link.id,
                            paymentMethods: ['us_bank_account'],
                          });
                        } catch (e: any) {
                          showSnackbar(e.message, 'error');
                        } finally {
                          setLoading({ ...loading, [link.id]: false });
                        }
                      }}
                      sx={{ mr: 1 }}
                    >
                      ACH only
                    </LoadingButton>
                    <LoadingButton
                      loading={loading[link.id]}
                      variant="outlined"
                      onClick={async () => {
                        try {
                          setLoading({ ...loading, [link.id]: true });
                          await poster.mutateAsync({
                            paymentLinkId: link.id,
                            paymentMethods: ['us_bank_account', 'card', 'link'],
                          });
                        } catch (e: any) {
                          showSnackbar(e.message, 'error');
                        } finally {
                          setLoading({ ...loading, [link.id]: false });
                        }
                      }}
                    >
                      Enable credit card
                    </LoadingButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default PaymentLinks;
