import { captureException } from '@sentry/react';
import { removeLeadingTrailingChar } from 'common/helpers';
import { useCallback, useEffect, useState } from 'react';

import useSnackbar from '@/contexts/useSnackbar';
import API from '@/services/API';

type downloadParams = {
  endpoint_str_id: string;
  file_preview_type: 'override' | 'original' | 'logo';
  endpoint?: 'documents' | 'accounts';
};

const useDownloadStorageFile = () => {
  const [file, setFile] = useState<File | null>(null);
  const [blobFile, setBlobFile] = useState<Blob | null>(null);

  const [downloadFileParams, setDownloadFileParams] = useState<downloadParams>(
    {} as downloadParams
  );

  const { showSnackbar } = useSnackbar();

  const downloader = API.getMutation('storage/download', 'POST', {
    rawData: true,
  });

  const downloadFile = useCallback(
    async (downloadFileParams: downloadParams) => {
      const { endpoint_str_id, file_preview_type, endpoint } =
        downloadFileParams;
      if (!file_preview_type || !endpoint_str_id) return;
      try {
        const res: Response = await downloader.mutateAsync({
          endpoint_str_id,
          file_preview_type,
          endpoint,
        });

        if (!res.ok) {
          showSnackbar(`Error: ${res.statusText}`, 'error');
          return null;
        }

        const filename = removeLeadingTrailingChar(
          res.headers.get('content-disposition')?.split('=')[1] || 'documents',
          '"'
        );
        const blob = await res.blob();
        const file = new File([blob], filename, { type: blob.type });

        setFile(file);
        setBlobFile(blob);
        return file;
      } catch (error: any) {
        console.error(error.error || error);
        captureException(error);
        showSnackbar(error.error || error, 'error');
        return null;
      }
    },
    [downloadFileParams]
  );

  useEffect(() => {
    const { endpoint_str_id, file_preview_type } = downloadFileParams;
    if (endpoint_str_id && file_preview_type) {
      downloadFile(downloadFileParams);
    }
  }, [downloadFileParams]);

  return { file, blobFile, downloadFile, setDownloadFileParams };
};

export default useDownloadStorageFile;
