import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface DataUpdatePreviewModalProps {
  open: boolean;
  handleClose: () => void;
  handleDataUpdatePost: (isPreview: boolean) => void;
  loading: boolean;
  previewData: PreviewData[];
  fieldsToPreview: Field[];
}

interface PreviewData {
  data_entity_id: number;
  [key: string]: any;
}

interface Field {
  id: string;
  name: string;
}

const DataUpdatePreviewModal: React.FC<DataUpdatePreviewModalProps> = ({
  open,
  handleClose,
  handleDataUpdatePost,
  loading,
  previewData,
  fieldsToPreview,
}) => {
  const fieldsIds = fieldsToPreview.map((field) => field.id);
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Data Update Preview</DialogTitle>
      <DialogContent>
        <TableContainer
          component={Paper}
          sx={{
            margin: 2,
            maxHeight: 500,
            width: 800,
            overflow: 'auto',
            border: '1px solid #ccc',
          }}
        >
          <Table stickyHeader sx={{ borderCollapse: 'collapse' }} size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ border: '1px solid #ccc' }}>
                  Preview fields
                </TableCell>
                <TableCell sx={{ border: '1px solid #ccc' }}>
                  Fields to change
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {previewData.map((data, index) => {
                const unchangedFields = {};
                const changedFields = {};

                Object.keys(data).forEach((key) => {
                  if (key !== 'data_entity_id') {
                    if (fieldsIds.includes(key)) {
                      unchangedFields[key] = data[key];
                    } else {
                      changedFields[key] = data[key];
                    }
                  }
                });

                return (
                  <TableRow key={index}>
                    <TableCell sx={{ border: '1px solid #ccc' }}>
                      {Object.entries(unchangedFields).map(([key, value]) => (
                        <div key={key}>
                          <strong>{key}:</strong>{' '}
                          {typeof value === 'object'
                            ? JSON.stringify(value)
                            : (value as string)}
                        </div>
                      ))}
                    </TableCell>
                    <TableCell sx={{ border: '1px solid #ccc' }}>
                      {Object.entries(changedFields).map(([key, value]) => (
                        <div key={key}>
                          <strong>{key}:</strong>{' '}
                          {typeof value === 'object'
                            ? JSON.stringify(value)
                            : (value as string)}
                        </div>
                      ))}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={() => handleDataUpdatePost(false)}
          sx={{ ml: 1 }}
        >
          Run data update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DataUpdatePreviewModal;
