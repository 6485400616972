import { LaunchOutlined } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Formatter from 'common/Formatter';
import React from 'react';
import { Link } from 'react-router-dom';

type TransactionDetail = {
  id: string;
  amount: string;
  status: string;
  date: Date | null;
  notes: string;
};

type Transaction = {
  id: string;
  amount: string;
  status: string;
  date: Date | null;
  notes: string;
  accounting_transaction_details?: TransactionDetail[];
};

type Field = {
  id: string;
  label: string;
};

type CustomerPoliciesProps = {
  data: {
    report_data: any[];
  };
  // setter: (value: any) => void;
  field: Field;
};

const CustomerPolicies: React.FC<CustomerPoliciesProps> = ({
  data,
  field,
  // setter,
}) => {
  const customerPolicies = data?.report_data ?? [];

  console.log(customerPolicies);

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="subtitle1">Policies</Typography>
      <TableContainer>
        <Table>
          {customerPolicies.length > 0 && (
            <TableHead>
              <TableRow>
                <TableCell>Policy ID</TableCell>
                <TableCell>Product name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Effective date</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {customerPolicies.map((policy) => (
              <TableRow key={policy.str_id}>
                <TableCell>{policy.policy_id}</TableCell>
                <TableCell>{policy.product_name}</TableCell>
                <TableCell>{policy.policy_status}</TableCell>
                <TableCell>{Formatter.date(policy.effective_date)}</TableCell>
                <TableCell>
                  <IconButton
                    component={Link}
                    to={`/policies?id=${policy.str_id}`}
                    target="_blank"
                    sx={{
                      opacity: 0.5,
                      '&:hover': { opacity: 1 },
                      color: '#2196f3',
                    }}
                  >
                    <LaunchOutlined />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CustomerPolicies;
