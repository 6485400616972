import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DataEntities } from 'common/globalTypes';

import API from '@/services/API';
import { FieldTypes } from '@/types';
import Statements from '@/services/Statements';
import Reports from '@/services/Reports';
import RunDataUpdate from '@/components/ToolsPage/DataUpdateTools/RunDataUpdate';
import { Fields } from '@/components/ToolsPage/DataUpdateTools/types';
import DataUpdateConfig from '@/components/ToolsPage/DataUpdateTools/DataUpdateConfig';
import DataUpdateCriteria from '@/components/ToolsPage/DataUpdateTools/DataUpdateCriteria';
import DataUpdateActions from '@/components/ToolsPage/DataUpdateTools/DataUpdateActions';

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    {...other}
  >
    {value === index && (
      <Box sx={{ pt: 2 }}>
        <Typography component="div">{children}</Typography>
      </Box>
    )}
  </div>
);

const DataUpdateTools: React.FC = () => {
  const statements = useMemo(() => new Statements('insurance'), []);
  const reports = useMemo(() => new Reports('insurance', {}), []);

  const [fields, setFields] = useState<Fields>({
    [DataEntities.COMMISSIONS]: Object.values(statements.fields),
    [DataEntities.POLICIES]: Object.values(reports.fields),
  });

  const { data: _statementDataOptions } = API.getBasicQuery(
    `statement_data/options`
  );

  const statementProducTypesList = useMemo(
    () => [
      ...(_statementDataOptions?.product_type?.sort((a, b) =>
        (a as string).localeCompare(b as string)
      ) || []),
    ],
    [_statementDataOptions?.product_type]
  );

  const statementCompTypeList = useMemo(
    () => [
      ...(_statementDataOptions?.compensation_type?.sort((a, b) =>
        (a as string).localeCompare(b as string)
      ) || []),
    ],
    [_statementDataOptions?.compensation_type]
  );

  useEffect(() => {
    // Set custom fields for the statements entity
    if (statementProducTypesList && statementCompTypeList) {
      statements.fields.product_type.options = statementProducTypesList;
      statements.fields.product_type.type = FieldTypes.SELECT;

      // Define statement_data fields as options for the effective_date field so we can use the 'within one year' or 'at least one year' from the payment date operators.
      statements.fields.effective_date.options = Object.keys(statements.fields);

      statements.fields.compensation_type.options = statementCompTypeList;
      statements.fields.compensation_type.type = FieldTypes.SELECT;

      // Remove the date type from the payment_date field because it is not necessary.
      // We plan to use operators like "within one year" or "at least one year from the payment date".
      statements.fields.payment_date.type = undefined;
    }
    setFields({
      [DataEntities.COMMISSIONS]: Object.values(statements.fields),
      [DataEntities.POLICIES]: Object.values(reports.fields),
    });
  }, [statementProducTypesList, statementCompTypeList, reports, statements]);

  const tabs = [
    {
      label: 'Run data update',
      path: 'run-data-update',
      component: <RunDataUpdate fields={fields} />,
    },
    {
      label: 'Data update config',
      path: 'data-update-config',
      component: <DataUpdateConfig fields={fields} />,
    },
    {
      label: 'Data update criteria',
      path: 'data-update-criteria',
      component: <DataUpdateCriteria fields={fields} />,
    },
    {
      label: 'Data update actions',
      path: 'data-update-actions',
      component: <DataUpdateActions fields={fields} />,
    },
  ];

  const { tab = 'list' } = useParams();
  const tabPaths = tabs.map((tab) => tab.path);
  const routedTab = tabPaths.indexOf(tab) >= 0 ? tabPaths.indexOf(tab) : 0;
  const [value, setValue] = useState(routedTab);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    navigate(`/settings/data-update-tools/${tabs[newValue].path}`);
    setValue(newValue);
  };

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          {tabs.map((tab) => (
            <Tab label={tab.label} key={tab.label} />
          ))}
        </Tabs>
      </Box>
      <Box sx={{ overflowX: 'scroll', height: '700px' }}>
        {tabs.map((tab) => (
          <TabPanel value={value} index={tabs.indexOf(tab)} key={tab.label}>
            {tab.component}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default DataUpdateTools;
